import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Quill from 'quill';
import { AppService } from 'src/app/services/app.service';
import { SharedService } from 'src/app/services/shared.service';
import { DialogComponent } from '../dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { CustomToolbarItemModel, DocumentEditorContainerComponent } from '@syncfusion/ej2-angular-documenteditor';
import { TitleBar } from './title-bar';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-text-block',
  templateUrl: './text-block.component.html'
})
export class TextBlockComponent implements OnInit {

  @ViewChild('quillContainer') quillContainer: ElementRef;
  @ViewChildren('templateCard', { read: ElementRef }) templateCard: QueryList<ElementRef>;

  private container: DocumentEditorContainerComponent;

  @ViewChild('documenteditor_default', { static: false }) set containerContent(content: DocumentEditorContainerComponent) {
    if(content) {
        this.container = content;
    }
 }

 private ngForm: NgForm;

 @ViewChild('form', { static: false }) set ngFormContent(content: NgForm) {
  if(content) {
      this.ngForm = content;
  }
}

 @Output() backTrigger: EventEmitter<string> = new EventEmitter<any>();

  public icons = Quill.import('ui/icons');
  public font = Quill.import('formats/font');
  public Bold = Quill.import('formats/bold');
  public parchment = Quill.import('parchment');
  public modules = {};
  public displayEditor = false;
  public content = '';
  public placeholderList = [
    { value: '@Mandant', description: 'some dsescjd mandant' },
    { value: '#Unfalldatum', description: 'this desfbc dsescjd opponent' },
  ];
  public previousIndex = 0;
  public textBlockForm: FormGroup;
  public textBlockId: any;
  public dialogRef: any;
  public dialogSubscription: Subscription;
  public createNew = false;
  public variableList: any;
  public groupedVariableList: any = [];

  public titleBar: TitleBar;
  public items = ['Undo', 'Redo', 'Image', 'Table', 'TableOfContents', 'Header', 'Footer', 'PageSetup', 'PageNumber', 'Find', 'Break', 'LocalClipboard', 'RestrictEditing'];
  public bodyObservable: Observable<any>;
  public groupedVariables: any = {
    isExpandBody: {}
  };

  constructor(
    public appService: AppService,
    public fb: FormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    public sharedService: SharedService,
    public dialog: MatDialog,
  ) {}

  @HostListener('window:beforeunload')
  canDeactivate() {
    // if (this.formChanged) {
    //   if (
    //     confirm(
    //       'Möchten Sie die getätigten Änderungen verwerfen und ohne Speichern verlassen?'
    //     )
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // }
    return true;
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(({ params }: any) => {
      this.textBlockId = params.id;
      this.fetchInitialDetails()
      this.subscribeDialogEvent();
    });

    this.route.queryParams.subscribe(params => {
      this.createNew = params['create_new'];
    });
  }

  fetchInitialDetails() {
    let fetchArr = [this.appService.listCaseVariable()];
    fetchArr = this.textBlockId ? [...fetchArr, this.appService.textBlockDetail(this.textBlockId)] : fetchArr;

    forkJoin(fetchArr).subscribe(
      (res: any) => {
        
        if(this.textBlockId) {
          this.createForm(res[0]?.data?.result, res[1].data.result);
        } else {
          this.createForm(res[0]?.data?.result);
        }
      },
      err => {
        console.log(err);
      }
    )
  }

  fetchTemplateDetail() {
    this.appService.templateDetail(this.textBlockId).subscribe(
      (res: any) => {
        this.createForm(res.data.result);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  createForm(variableList, data?) {
    this.textBlockForm = this.fb.group({
      name: [data?.name, Validators.compose([Validators.required])],
      body: [data?.body, Validators.compose([Validators.required])],
    });
    this.displayEditor = true;

    setTimeout(() => {
      this.bodyObservable = this.ngForm.form.get('body')!.valueChanges
            .pipe(
              startWith(''),
              map(value => {
                return this._filterGroup(value, this.groupedVariableList);
              })
            )
    });

    variableList.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    
    this.variableList = variableList;
    this.groupedVariableList = this.groupVariables(variableList);

  }

  scanContent(text = 'Hi @#ricky, how are you @#navneet @#checking ') {
    console.log(
      Array.from(text.matchAll(/@#[a-z]*/g), (m) => m[0]).map(
        (ele) => ele.split('#')[1]
      )
    );
  }

  saveTextBlock() {
    if (this.textBlockForm.valid) {
      if (this.textBlockId && !this.createNew) {
        this.updateTextBlock();
      } else {
        this.createTextBlock();
      }
    }
  }

  updateTextBlock() {
    this.appService
      .editTextBlock(this.textBlockId, this.textBlockForm.value)
      .subscribe(
        (res) => {
          this.sharedService.open(`Textbaustein wurde erfolgreich gespeichert`, 'success');
          this.router.navigate(['/dashboard/textblockList']);
        },
        (err) => {
          console.log(err);
        }
      );
  }

  createTextBlock() {
    this.appService.createTextBlock(this.textBlockForm.value).subscribe(
      (res) => {
        this.sharedService.open(`Textbaustein wurde erfolgreich erstellt`, 'success');
        this.router.navigate(['/dashboard/textblockList']);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  deleteTextBlock() {
    this.appService.deleteTextBlock(this.textBlockId).subscribe(
      (res) => {
        this.dialogRef.close();
        this.sharedService.open(`Textbaustein wurde erfolgreich gelöscht`, 'success');
        this.router.navigate(['/dashboard/textblockList']);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  openDialog(data) {
    this.dialogRef = this.dialog.open(DialogComponent, {
      width: '300px',
      disableClose: false,
      data,
    });
  }

  openDeleteModal() {
    this.openDialog({
      dialogType: 'CONFIRMATION',
      title: `Löschen?`,
      message: `Möchten Sie den Textbaustein wirklich löschen?`,
      allowTooltip: 'Confirm',
      denyTooltip: 'Deny',
    });
  }

  subscribeDialogEvent() {
    this.dialogSubscription = this.sharedService
      .getDialogSubmitEvent()
      .subscribe((res) => {
        switch (res?.type) {
          case 'CONFIRMATION_ALLOWED':
            this.deleteTextBlock();
            break;
          default:
            this.dialogRef.close();
        }
      });
  }

  back() {
    this.backTrigger.emit();
  }

  getheight() {
    return window.innerHeight - 400;
  }

  onCreate(): void {
    this.container.documentEditorSettings.showRuler = true;
    let titleBarElement: HTMLElement = document.getElementById('default_title_bar');
    this.titleBar = new TitleBar(titleBarElement, this.container.documentEditor, true);
    this.titleBar.updateDocumentTitle();
    if(this.textBlockForm.get('body').value) {
      this.container.documentEditor.open(this.textBlockForm.get('body').value);
    }
  }

  selectionChanges() {
    if (this.container.documentEditor.selection.contextType.indexOf('Header') > -1 ||
      this.container.documentEditor.selection.contextType.indexOf('Footer') > -1 ) {
      this.container.restrictEditing = true;
    } else {
      this.container.restrictEditing = false;
    }
  }

  onEditorContentChange(e) {
    this.textBlockForm.patchValue({
      body: this.container.documentEditor.serialize()
    });
  }

  variableFormClicked() {
    this.sharedService.variableDropdownEvent.next(true);
    const overlayContainerEleement: any = document.getElementsByClassName("cdk-overlay-container");
    overlayContainerEleement[0].style['z-index'] = 10000;
  }

  _filterGroup(value: string, variableList: Array<any>) {
    let updatedVL = variableList;
    if (value) {
      updatedVL = variableList
        .map(group => ({ ...group.group && { group: group.group, list: this._filter(group.list, value) }, ...!group.group && { label: group.label, value: group.value, description: group.description } }))
        .filter(group => group?.list?.length > 0 || group?.value?.toLowerCase().includes(value.toLowerCase()));
    }

    const groupedArr = updatedVL.filter(({ group }) => group);
    const variableArr = updatedVL.filter(({ group }) => !group);
    return [...groupedArr, ...variableArr];
  }

  groupVariables(variableList) {
    let updatedList = [];
    variableList?.forEach(({ name, description }) => {
      if (updatedList.findIndex(({ group, list }) => group === name.split('_')[0] && list && list.findIndex(({ group: childGroup }) => childGroup && childGroup.split('_')[1] === name.split('_')[1]) !== -1) !== -1) {
        const parentIndex = updatedList.findIndex(({ group, list }) => group === name.split('_')[0] && list && list.findIndex(({ group: childGroup }) => childGroup && childGroup.split('_')[1] === name.split('_')[1]) !== -1);
        const childIndex = updatedList[parentIndex].list.findIndex(({ group: childGroup }) => childGroup && childGroup.split('_')[1] === name.split('_')[1]);
        updatedList[parentIndex].list[childIndex].list = [...updatedList[parentIndex].list[childIndex].list, { label: name, value: name, description, type: 'variable' }];
      } else if (updatedList.findIndex(({ group, list }) => group === name.split('_')[0] && list && list.findIndex(({ label }) => label && label.split('_')[1] === name.split('_')[1]) !== -1) !== -1) {
        const parentIndex = updatedList.findIndex(({ group, list }) => group === name.split('_')[0] && list && list.findIndex(({ label }) => label && label.split('_')[1] === name.split('_')[1]) !== -1);
        const childIndex = updatedList[parentIndex].list.findIndex(({ label }) => label && label.split('_')[1] === name.split('_')[1]);
        const currVariable = updatedList[parentIndex].list[childIndex];
        updatedList[parentIndex].list.splice(childIndex, 1, { group: `${name.split('_')[0]}_${name.split('_')[1]}`, list: [currVariable, { label: name, value: name, description, type: 'variable' }] });
      } else if (updatedList.findIndex(({ group }) => group === name.split('_')[0]) !== -1) {
        const index = updatedList.findIndex(({ group }) => group === name.split('_')[0]);
        updatedList[index].list = [...updatedList[index].list, { label: name, value: name, description, type: 'variable' }];
      } else if (updatedList.findIndex(({ label }) => label && label.split('_')[0] === name.split('_')[0]) !== -1) {
        const index = updatedList.findIndex(({ label }) => label && label.split('_')[0] === name.split('_')[0]);
        const currVariable = updatedList[index];
        updatedList.splice(index, 1, { group: name.split('_')[0], list: [currVariable, { label: name, value: name, description, type: 'variable' }] });
        if (currVariable.value.split('_')[1] === name.split('_')[1]) {
          updatedList[index].list = [{ group: `${name.split('_')[0]}_${name.split('_')[1]}`, list: updatedList[index].list }];
        }
      } else {
        updatedList = [...updatedList, { label: name, value: name, description, type: 'variable' }];
      }
    });
    return updatedList;
  }

  _filter(opt: (string | any)[], value: string): string[] {
    const filterValue = value.toLowerCase();
    let temp = opt.filter(item => !item.value || item.value.toLowerCase().includes(filterValue));
    temp = temp.map(item => item.value ? item : { group: item.group, list: this._filter(item.list, value) });
    temp = temp.filter(item => item.value || item.list.length > 0);
    return temp;
  };

  triggerEvent(e) {
    if (!e) {
      const overlayContainerEleement: any = document.getElementsByClassName("cdk-overlay-container");
      overlayContainerEleement[0].style['z-index'] = 1000;
      this.sharedService.variableDropdownEvent.next(false);
    }
  }

  toggleSelection(event: any, group: any, field: any) {
    this.groupedVariables[field][group.group] = !this.groupedVariables[field][group.group] ? true : false;
    event.stopPropagation();
  }

  editorVariableSelected(e) {
    this.container.documentEditor.editor.insertText(e.option.value);
    this.ngForm.form.patchValue({ body: '' });
  }

  ngOnDestroy() {
    if (this.dialogSubscription) this.dialogSubscription.unsubscribe();
  }
}
