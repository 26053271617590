import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MustMatch } from '../../components/shared/MustMatch.validator';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { SubSink } from 'subsink';
import { SharedService } from 'src/app/services/shared.service';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/services/app.service';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit, OnDestroy {

  registerForm: FormGroup;
  public errMsg = {
    message: 'No alerts'
  };
  submitted = false;
  private subs = new SubSink();
  private tokenValues;

  constructor(private route: ActivatedRoute, private router: Router, private formBuilder: FormBuilder, public appService: AppService,
    private snackbarService: SnackbarService, public dialogRef: MatDialogRef<RegisterComponent>,
    private sharedService: SharedService) {

  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
        const token = params['token'];
        this.registerForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            username: ['', [Validators.required, Validators.minLength(5)]],
            password: ['', [
                Validators.required,
                Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&\\-])[A-Za-z\\d@$!%*?&\\-]{8,}$')
            ]],
            cpass: ['', Validators.required],
            phone: ['', [,Validators.pattern('^[0-9]*$')]],
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            token: [token, Validators.required]
        });
        this.appService.getTokenInformation(token).subscribe(
            tokenInfo => {
                this.tokenValues = tokenInfo;
                this.registerForm.patchValue({ email: this.tokenValues.email });
            }
        )
    });
    }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.sharedService.progress.next(false);
  }

  get getEmail() { return this.registerForm.get('email'); }
  get getUsername() { return this.registerForm.get('username'); }
  get getPassword() { return this.registerForm.get('password'); }
  get getCPass() { return this.registerForm.get('cpass'); }
  get getPhone() { return this.registerForm.get('phone'); }
  get getFirstName() { return this.registerForm.get('firstName'); }
  get getLastName() { return this.registerForm.get('lastName'); }

  get validations() { return this.registerForm.controls; }

  // ===================== Login function ========================== //
  moveToLogin() {
    this.router.navigate(['./login']);
  }

  // ===================== Register functions ========================== //

  register() {
    this.submitted = true;
    if (!this.registerForm.valid) {
      this.validateAllFormFields(this.registerForm);
      console.log('invalid');
      return;
    } else {
      this.sharedService.progress.next(true);
      const formValue = { ...this.registerForm.value, token: this.registerForm.value.token};
      this.subs.sink = this.appService.register(JSON.stringify(formValue))
        .subscribe(
          (data: any) => {
            this.router.navigate(['/login']);
            this.sharedService.open(data.data.result.message, 'success');
          },
          error => {
            { console.error(error); this.registerError(error); this.sharedService.open(this.errMsg.message, 'failure'); }
          }
        );
      this.sharedService.progress.next(false);
    }
  }

  registerError(error: HttpErrorResponse) {
    this.errMsg = error.error;
  }

  // ===================== Validations ========================== //

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
        if (control.invalid) {
        console.log(this.registerForm.value);
        
          console.error(`Invalid field: ${field}`, control.errors);
        }
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  // ===================== Reset function ========================== //

  resetForm() {
    this.submitted = false;
    this.registerForm.reset();
  }
}
