import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class DateVariableService {
    private dateVariablesSubject = new BehaviorSubject<any[]>([]); 
    public dateVariables$ = this.dateVariablesSubject.asObservable();

    constructor(private appService: AppService) {}

    fetchDateVariables(documentTemplateId: string): void {
        this.appService.getDateVariables(documentTemplateId).pipe(
            map((response: any) => response.data.result || []),
            map((variables: any[]) =>
                variables.map((variable) => ({
                    name: `#Datum_${variable.variableName}`,
                    dateType: variable.dateType,
                    days: variable.days,
                    operator: variable.operator,
                    id: variable.id,
                }))
            ),
            tap((formattedVariables) => this.dateVariablesSubject.next(formattedVariables))
        ).subscribe();
    }

    createDateVariable(documentTemplateId: string, variable: any): Observable<any> {
        return this.appService.saveDateVariables([variable]).pipe(
            tap(() => this.fetchDateVariables(documentTemplateId))
        );
    }

    updateDateVariable(documentTemplateId: string, variable: any): Observable<any> {
        return this.appService.updateDateVariable(variable).pipe(
            tap(() => this.fetchDateVariables(documentTemplateId))
        );
    }

    deleteDateVariable(documentTemplateId: string, variableId: string): Observable<any> {
        return this.appService.deleteDateVariable(variableId).pipe(
            tap(() => this.fetchDateVariables(documentTemplateId))
        );
    }

    getCurrentDateVariables(): any[] {
        return this.dateVariablesSubject.getValue();
    }
}