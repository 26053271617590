import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Quill from 'quill';
import Delta from 'quill-delta';
import { AppService } from 'src/app/services/app.service';
import { SharedService } from 'src/app/services/shared.service';
import { DialogComponent } from '../dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, forkJoin, Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
})
export class TemplateComponent implements OnInit {
  @ViewChild('quillContainer') quillContainer: ElementRef;
  @ViewChild('fileButtonSelect') fileButtonSelect;
  @ViewChildren('templateCard', { read: ElementRef }) templateCard: QueryList<ElementRef>;

  @Output() backTrigger: EventEmitter<string> = new EventEmitter<any>();
  @Output() close: EventEmitter<string> = new EventEmitter<any>();
  @Output() fileAdded: EventEmitter<string> = new EventEmitter<any>();

  @Input() selectedTemplate: any;
  @Input() caseDetail: any;
  @Input() filledVariables: any = {};
  @Input() documentList: any;

  public icons = Quill.import('ui/icons');
  public font = Quill.import('formats/font');
  public Bold = Quill.import('formats/bold');
  public parchment = Quill.import('parchment');
  public modules = {};
  public displayEditor = false;
  public content = '';
  public placeholderList = [
    { value: '@Mandant', description: 'some dsescjd mandant' },
    { value: '#Unfalldatum', description: 'this desfbc dsescjd opponent' },
    { value: '#caseCode', description: 'code for case' },
  ];
  public previousIndex = 0;
  public templateForm: FormGroup;
  public templateId: any;
  public dialogRef: any;
  public dialogSubscription: Subscription;
  public createNew = false;
  public variableList: any = [];
  public emailVariableList: any = [];
  public textBlockList: any;
  public selectVariables = {
    recipient: '',
    cc: '',
    bcc: '',
    subject: ''
  };
  public caseValueDetail = [];
  public isFileSelctDisabled = true;
  public showError = false;
  recipientObservable: Observable<any>;
  ccObservable: Observable<any>;
  bccObservable: Observable<any>;
  subjectObservable: Observable<any>;
  bodyObservable: Observable<any>;
  groupedVariables: any = {
    isExpandRecipient : {},
    isExpandCC: {},
    isExpandBCC: {},
    isExpandSubject: {},
    isExpandBody: {}
  };
  variableForm: FormGroup = this.fb.group({
    recipient: '',
    cc: '',
    bcc: '',
    subject: '',
    body: ''
  });
  quillInstance: any;
  selectedIndex: any;

  constructor(
    public appService: AppService,
    public fb: FormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    public sharedService: SharedService,
    public dialog: MatDialog,
  ) {}

  @HostListener('window:beforeunload')
  canDeactivate() {
    // if (this.formChanged) {
    //   if (
    //     confirm(
    //       'Möchten Sie die getätigten Änderungen verwerfen und ohne Speichern verlassen?'
    //     )
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // }
    return true;
  }


  ngOnInit(): void {    
    this.recipientObservable = this.variableForm.get('recipient')!.valueChanges
      .pipe(
        startWith(''),
        map(value => {
          return this._filterGroup(value, this.emailVariableList);
        })
      );

    this.ccObservable = this.variableForm.get('cc')!.valueChanges
    .pipe(
      startWith(''),
      map(value => {
        return this._filterGroup(value, this.emailVariableList);
      })
    );

    this.bccObservable = this.variableForm.get('bcc')!.valueChanges
    .pipe(
      startWith(''),
      map(value => {
        return this._filterGroup(value, this.emailVariableList);
      })
    );

    this.subjectObservable = this.variableForm.get('subject')!.valueChanges
    .pipe(
      startWith(''),
      map(value => {
        return this._filterGroup(value, this.variableList);
      })
    );

    this.bodyObservable = this.variableForm.get('body')!.valueChanges
    .pipe(
      startWith(''),
      map(value => {
        return this._filterGroup(value, this.variableList);
      })
    );
    
    this.font.whitelist = ['sans-serif', 'times-new-roman', 'arial'];
    Quill.register(this.font, true);

    let CustomClass = new this.parchment.Attributor.Class(
      'custom',
      'ql-custom',
      {
        scope: this.parchment.Scope.INLINE,
      }
    );
    Quill.register(CustomClass, true);

    class PreserveWhiteSpace {
      constructor(private quill: any, private options: {}) {
        quill.container.style.whiteSpace = 'pre-line';
      }
    }

    Quill.register('modules/preserveWhiteSpace', PreserveWhiteSpace);

    this.icons[
      'bold'
    ] = `<svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.38867 0.78125V15H0.9375V0.78125H3.38867ZM9.18945 7.00195V8.94531H2.76367V7.00195H9.18945ZM10 0.78125V2.73438H2.76367V0.78125H10Z" fill="black"/>
    </svg>
    `;
    this.icons[
      'italic'
    ] = `<svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.25195 0.78125L1.79102 15H0.609375L3.07031 0.78125H4.25195ZM12.8359 0.791016L5.73633 7.30469L2.17188 10.3613L2.28906 8.94531L5.12109 6.37695L11.2539 0.78125L12.8359 0.791016ZM9.38867 15L4.78906 7.64648L5.61914 6.72852L10.7266 15H9.38867Z" fill="black"/>
    </svg>
    `;
    this.icons[
      'underline'
    ] = `<svg width="20" height="30" viewBox="4 1 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.8086 3.78125H16.0098V13.4102C16.0098 14.4779 15.7852 15.3665 15.3359 16.0762C14.8867 16.7858 14.2812 17.3164 13.5195 17.668C12.7643 18.0195 11.9277 18.1953 11.0098 18.1953C10.0658 18.1953 9.21615 18.0195 8.46094 17.668C7.70573 17.3164 7.10677 16.7858 6.66406 16.0762C6.22786 15.3665 6.00977 14.4779 6.00977 13.4102V3.78125H7.20117V13.4102C7.20117 14.237 7.36393 14.9303 7.68945 15.4902C8.01497 16.0501 8.46419 16.4701 9.03711 16.75C9.61003 17.0299 10.2676 17.1699 11.0098 17.1699C11.7454 17.1699 12.3997 17.0299 12.9727 16.75C13.5456 16.4701 13.9948 16.0501 14.3203 15.4902C14.6458 14.9303 14.8086 14.237 14.8086 13.4102V3.78125Z" fill="black"/>
    <path d="M4.41797 19.9531H17.5723V20.9297H4.41797V19.9531Z" fill="black"/>
    </svg>`;

    this.route.paramMap.subscribe(({ params }: any) => {
      this.templateId = this.selectedTemplate ? this.selectedTemplate : params.id;
      this.fetchInitialDetails()
      this.subscribeDialogEvent();
    });

    this.route.queryParams.subscribe(params => {
      this.createNew = params['create_new'];
    });
  }

  fetchInitialDetails() {
    let fetchArr = [this.appService.listCaseVariable(), this.appService.textBlockList(), this.appService.caseValues()];
    fetchArr = this.templateId ? [...fetchArr, this.appService.templateDetail(this.templateId)] : fetchArr;

    forkJoin(fetchArr).subscribe(
      (res: any) => {
        const variableList = res[0]?.data?.result;
        variableList.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        this.emailVariableList = this.groupVariables(this.onlyEmailVariableList(variableList));
        this.variableList = this.groupVariables(variableList);

        this.textBlockList = res[1]?.data?.result?.rows?.map(({ name, body }) => ({ label: name, value: body, type: 'text-block' }));
        this.caseValueDetail = res[2]?.data?.result;
        
        if(this.templateId) {
          this.createForm(res[3].data.result);
        } else {
          this.createForm();
        }
      },
      err => {
        console.log(err);
      }
    )
  }

  groupVariables(variableList) {
    let updatedList = [];
    variableList?.forEach(({ name, description }) => {   
      if(updatedList.findIndex(({ group, list }) => group === name.split('_')[0] && list && list.findIndex(({ group: childGroup }) => childGroup && childGroup.split('_')[1] === name.split('_')[1]) !== -1) !== -1) {
        const parentIndex = updatedList.findIndex(({ group, list }) => group === name.split('_')[0] && list && list.findIndex(({ group: childGroup }) => childGroup && childGroup.split('_')[1] === name.split('_')[1]) !== -1);
        const childIndex = updatedList[parentIndex].list.findIndex(({ group: childGroup }) => childGroup && childGroup.split('_')[1] === name.split('_')[1]);
        updatedList[parentIndex].list[childIndex].list = [...updatedList[parentIndex].list[childIndex].list, { label: name, value: name, description, type: 'variable' }];
      } else if(updatedList.findIndex(({ group, list }) => group === name.split('_')[0] && list && list.findIndex(({ label }) =>label && label.split('_')[1] === name.split('_')[1]) !== -1) !== -1) {
        const parentIndex = updatedList.findIndex(({ group, list }) => group === name.split('_')[0] && list && list.findIndex(({ label }) => label && label.split('_')[1] === name.split('_')[1]) !== -1);
        const childIndex = updatedList[parentIndex].list.findIndex(({ label }) => label && label.split('_')[1] === name.split('_')[1]);
        const currVariable = updatedList[parentIndex].list[childIndex]; 
        updatedList[parentIndex].list.splice(childIndex, 1, { group: `${name.split('_')[0]}_${name.split('_')[1]}`, list: [currVariable, { label: name, value: name, description, type: 'variable' }]});
      } else if(updatedList.findIndex(({ group }) => group === name.split('_')[0]) !== -1) {
        const index = updatedList.findIndex(({ group }) => group === name.split('_')[0]);
        updatedList[index].list = [...updatedList[index].list, { label: name, value: name, description, type: 'variable' }];
      } else if(updatedList.findIndex(({ label }) => label && label.split('_')[0] === name.split('_')[0]) !== -1) {
        const index = updatedList.findIndex(({ label }) => label && label.split('_')[0] === name.split('_')[0]);
        const currVariable = updatedList[index];            
        updatedList.splice(index, 1, { group: name.split('_')[0], list: [currVariable, { label: name, value: name, description, type: 'variable' }]});
        if(currVariable.value.split('_')[1] === name.split('_')[1]) {
          updatedList[index].list = [{ group: `${name.split('_')[0]}_${name.split('_')[1]}`, list: updatedList[index].list }];
        }
      } else {
        updatedList = [...updatedList, { label: name, value: name, description, type: 'variable' }];
      }
    });
    return updatedList;
  }

  initializeEditor() {
      this.modules = {
        preserveWhiteSpace: true,
        toolbar: {
          container: [
            ['bold', 'italic', 'underline'],
            [{ font: ['sans-serif', 'times-new-roman', 'arial'] }],
            [{ size: ['small', false, 'large', 'huge'] }],
          ],
          handlers: {
            placeholder: function (value) {
              if (value) {
                const cursorPosition = this.quill.getSelection().index;

                const text = ` ${JSON.parse(value).value}`;
                const type = JSON.parse(value).type;

                const clipboardDelta = this.quill.clipboard.convert(
                  `${type === 'variable' ? '<span style="color: red">' + text + '</span><span> </span>' : '<span>' + text + ' </span>'} `
                );

                const delta = new Delta()
                  .retain(cursorPosition)
                  .concat(clipboardDelta);

                this.quill.updateContents(delta, 'user');
                this.quill.setSelection(cursorPosition + text.length + 1);
              }
            },
          },
        },
      };

      this.displayEditor = true;

      setTimeout(() => {
        const variableInput = document.getElementsByClassName('mat-autocomplete-trigger');
        const button = document.getElementsByTagName('button');
        const dorpdown = document.getElementsByClassName('ql-picker-label');
    
        for (let i = 0; i < variableInput.length; ++i) {
          variableInput[i].setAttribute('tabIndex', '-1');
        }

        for (let i = 0; i < button.length; ++i) {
          button[i].setAttribute('tabIndex', '-1');
        }

        for (let i = 0; i < dorpdown.length; ++i) {
          dorpdown[i].setAttribute('tabIndex', '-1');
        }
      }, 1000);

      this.replaceSvg();
  }

  createForm(data?) {
    this.templateForm = this.fb.group({
      name: [`${data?.name ? data?.name + (this.createNew ? '_Kopie' :'') : ''}`, Validators.compose([Validators.required])],
      subject: [data?.subject, Validators.compose([Validators.required])],
      body: [data?.body, Validators.compose([Validators.required])],
      recipient: [data?.recipient, Validators.compose([Validators.required])],
      cc: [data?.cc, Validators.compose([])],
      bcc: [data?.bcc, Validators.compose([])],
      selectedAttatchments: [data?.selectedAttatchments ? data?.selectedAttatchments?.split(',') : null, Validators.compose([])],
    });

    if(this.templateId) {
      this.scanContent();
    }
    this.initializeEditor();
  }

  scanContent() {
    Object.entries(this.filledVariables).forEach(([field, value]: [string, string]) => this.templateForm.patchValue({
      body: this.templateForm.value['body']?.replaceAll(`${field}`, value),
      subject: this.templateForm.value['subject']?.replaceAll(`${field}`, value),
      recipient: this.templateForm.value['recipient']?.replaceAll(`${field}`, value),
      cc: this.templateForm.value['cc']?.replaceAll(`${field}`, value),
      bcc: this.templateForm.value['bcc']?.replaceAll(`${field}`, value),
    }));
  }

  replaceSvg() {
    setTimeout(() => {
      const svgs = Array.from(
        document.querySelectorAll('.ql-picker-label svg')
      );
      svgs.forEach((svgElem) => {
        svgElem.innerHTML = `<path d="M.707.707l3.305 3.305M7.317.707L4.012 4.012" fill="none" stroke="#14294a" stroke-linecap="round"/>`;
        svgElem.setAttribute('viewBox', '0 0 8.024 4.719');
      });

      const quillToolbar = document.querySelectorAll('.ql-toolbar')[0];
      const span = document.createElement('span');
      span.setAttribute('class', 'ql-formats pull-right attachment-selector');
      span.innerHTML += `<button class="ql-attachment"><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.6525 10.1295L11.2283 18.5537C10.1963 19.5857 8.79655 20.1655 7.33704 20.1655C5.87754 20.1655 4.47782 19.5857 3.44579 18.5537C2.41377 17.5216 1.83398 16.1219 1.83398 14.6624C1.83398 13.2029 2.41377 11.8032 3.44579 10.7712L11.87 2.347C12.558 1.65898 13.4911 1.27246 14.4641 1.27246C15.4371 1.27246 16.3703 1.65898 17.0583 2.347C17.7463 3.03502 18.1328 3.96817 18.1328 4.94117C18.1328 5.91417 17.7463 6.84732 17.0583 7.53533L8.62496 15.9595C8.28095 16.3035 7.81438 16.4968 7.32788 16.4968C6.84138 16.4968 6.3748 16.3035 6.03079 15.9595C5.68679 15.6155 5.49352 15.1489 5.49352 14.6624C5.49352 14.1759 5.68679 13.7093 6.03079 13.3653L13.8133 5.592" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
      </svg></button>`;
      quillToolbar.appendChild(span);

      var customInput = document.querySelector('.ql-attachment');
      customInput.addEventListener('click', (e) => {
        this.isFileSelctDisabled = false;
        setTimeout(() => {
          this.fileButtonSelect.open();
          setTimeout(() => {
            const elementList: any = document.getElementsByClassName("cdk-overlay-pane");
            let selectEleList;
            for(let i = 0; i < elementList.length; ++i) {
              if(elementList[i].querySelector('.file-button-option') !== null) {
                selectEleList = elementList[i];
              }
            }
            selectEleList.classList.add("template-file-select");
            const fileElement = document.getElementById("filebtn-id");
            selectEleList.style.left = `${fileElement.getBoundingClientRect().left + 40}px`;

            const overlayContainerEleement: any = document.getElementsByClassName("cdk-overlay-container");
            overlayContainerEleement[0].style['z-index'] = 10000;
          })
        })
      });

      const placeholderPickerItems = Array.prototype.slice.call(
        document.querySelectorAll('.ql-placeholder .ql-picker-item')
      );

      placeholderPickerItems.forEach((item) => {
        item.textContent = JSON.parse(item.dataset.value).label;
        item.title = JSON.parse(item.dataset.value).description;
      });

      setTimeout(() => {
        this.quillContainer.nativeElement.style.height = `${
          this.templateCard.first.nativeElement.clientHeight - 430
        }px`;
      });
    });
  }

  onContentChanged(e) {
    // const currIndex = e.editor.getSelection().index;
    // if (e?.delta?.ops.length > 1) {
    //   if (e.delta.ops[1].insert == ' ') {
    //     const textArr = e.text.split(' ');
    //     let lengthTracker = 0;
    //     textArr.forEach((text, index) => {
    //       lengthTracker += text.length;
    //       if (
    //         this.placeholderList.findIndex((ele) => ele.value === text) !== -1
    //       ) {
    //         textArr[index] = `<span style="color: red">${text}</span>${
    //           index < textArr.length - 2 ? '<span></span>' : '<span> </span>'
    //         }`;
    //       }
    //       if (e.editor.getSelection().index - 1 === lengthTracker) {
    //         textArr[index] = `${textArr[index]}`;
    //       }
    //     });
    //     this.templateForm.patchValue({
    //       body: textArr.join(' '),
    //     });
    //     this.previousIndex = currIndex;
    //   } else {
    //     e.editor.setSelection(
    //       currIndex !== 0
    //         ? currIndex
    //         : this.previousIndex !== 0
    //         ? this.previousIndex
    //         : e.text.length + 1 || 0
    //     );
    //   }
    // }
  }

  saveTemplate() {
    this.showError = false;
    if (this.templateForm.valid) {
      if (this.templateId && !this.createNew) {
        this.updateTemplate();
      } else {
        this.createTemplate();
      }
    } else {
      this.showError = true;
      setTimeout(() => {
        const errors = document.getElementsByClassName('field-error');
        this.quillContainer.nativeElement.style.height = `${
          this.templateCard.first.nativeElement.clientHeight - (400 + (errors?.length * 10))
        }px`;
      })
    }
  }

  updateTemplate() {
    this.templateForm.patchValue({
      cc: this.templateForm.get('cc').value?.length ? this.templateForm.get('cc').value : null,
      bcc: this.templateForm.get('bcc').value?.length ? this.templateForm.get('bcc').value.length : null,
    })
    const payload = { ...this.templateForm.value, selectedAttatchments: this.templateForm.get('selectedAttatchments').value && this.templateForm.get('selectedAttatchments').value?.length ? this.templateForm.get('selectedAttatchments').value?.toString() : null }
    this.appService
      .editTemplate(this.templateId, payload)
      .subscribe(
        (res) => {
          this.sharedService.open(`Vorlage wurde erfolgreich gespeichert`, 'success');
          this.router.navigate(['/dashboard/templateList']);
        },
        (err) => {
          console.log(err);
        }
      );
  }

  createTemplate() {
    this.templateForm.patchValue({
      cc: this.templateForm.get('cc').value?.length ? this.templateForm.get('cc').value : null,
      bcc: this.templateForm.get('bcc').value?.length ? this.templateForm.get('bcc').value.length : null,
    })
    const payload = { ...this.templateForm.value, selectedAttatchments: this.templateForm.get('selectedAttatchments').value && this.templateForm.get('selectedAttatchments').value?.length ? this.templateForm.get('selectedAttatchments').value?.toString() : null };
    this.appService.createTemplate(payload).subscribe(
      (res) => {
        this.sharedService.open(`Vorlage wurde erfolgreich erstellt`, 'success');
        this.router.navigate(['/dashboard/templateList']);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  deleteTemplate() {
    this.appService.deleteTemplate(this.templateId).subscribe(
      (res) => {
        this.dialogRef.close();
        this.sharedService.open(`Vorlage wurde erfolgreich gelöscht`, 'success');
        this.router.navigate(['/dashboard/templateList']);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  openDialog(data) {
    this.dialogRef = this.dialog.open(DialogComponent, {
      width: '300px',
      disableClose: false,
      data,
    });

    this.dialogRef
      .afterClosed()
      .subscribe(
        () => this.selectedTemplate = this.templateId
      );
  }

  openDeleteModal() {
    this.openDialog({
      dialogType: 'CONFIRMATION',
      title: `Löschen`,
      message: `Möchten Sie die Vorlage wirklich löschen?`,
      allowTooltip: 'Confirm',
      denyTooltip: 'Deny',
    });
  }

  subscribeDialogEvent() {
    this.dialogSubscription = this.sharedService
      .getDialogSubmitEvent()
      .subscribe((res) => {
        switch (res?.type) {
          case 'CONFIRMATION_ALLOWED':
            this.deleteTemplate();
            break;
          case 'INFO_SUBMITTED':
            this.infoSubmitted();
          default:
            this.dialogRef.close();
        }
      });
  }

  back() {
    this.backTrigger.emit();
  }

  validateContent() {
    const payload = { ...this.templateForm.value, selectedAttatchments: this.templateForm.get('selectedAttatchments').value?.toString() };
    return Object.entries(payload).reduce((acc, [key, value]:[string, string] = ['', '']) => {
      return acc = value ? [...acc, ...Array.from(value.matchAll(/#[a-zA-Z_]*/g), (m) => m[0]).map(
      (ele) => ele
    )] : acc}, []);
  }

  sendMail() {
    const variableArr = this.validateContent();
    if (variableArr && variableArr.length) {
      return this.openInfoModal('Fehlende Informationen', 'Für folgende Variablen sind keine Informationen im Fall hinterlegt:', { variableArr });
    }

    if(this.templateForm.get('selectedAttatchments').value) {
      const emptyUploads = this.templateForm.get('selectedAttatchments').value.filter(attachment => !this.documentList.some(({ childFileAndFolder }) => childFileAndFolder.fileButtonMapping && childFileAndFolder.fileButtonMapping.name === attachment)).map(attachment => this.updatedName(attachment));
      if(emptyUploads && emptyUploads?.length) {
        return this.openInfoModal('Fehlende Datei', 'Folgende Dateien sind im Fall nicht hinterlegt:', { emptyUploads });
      }
    }

    let selectedAttatchments = [];
    if(this.templateForm.get('selectedAttatchments').value) {
      selectedAttatchments = this.documentList.filter(({ childFileAndFolder }) => childFileAndFolder.fileButtonMapping && this.templateForm.get('selectedAttatchments').value?.includes(childFileAndFolder.fileButtonMapping.name));
      selectedAttatchments = selectedAttatchments.map(({ childFileAndFolder }) => childFileAndFolder.path);
    }
    
    const payload = {
      subject: this.templateForm.get('subject').value,
      body: this.templateForm.get('body').value?.replaceAll('color: red', 'color: black'),
      recipient: this.templateForm.get('recipient').value,
      cc: this.templateForm.get('cc').value || null,
      bcc: this.templateForm.get('bcc').value || null,
      selectedAttatchments: selectedAttatchments?.toString() || null
    }
    
    this.appService.sendMail(this.caseDetail.id, payload).subscribe(
      (res: any) => {
        this.fileAdded.emit(res?.data?.result);
        this.sharedService.open(`E-Mail wurde erfolgreich versendet.`, 'success');
        this.closeDialog();
      },
      err => {
        console.log(err);
        this.sharedService.open(`Etwas ist schiefgelaufen.`, 'failure');
        this.closeDialog();
      }
    );
  }

  infoSubmitted() {
    this.dialogRef.close();
  }

  openInfoModal(title, message, data) {
    this.selectedTemplate = '';
    this.openDialog({
      dialogType: 'INFORMATION',
      title,
      message,
      data,
      infoTooltip: 'Okay',
    });
  }

  onlyEmailVariableList(variableList) {
    return variableList.filter(({ name }) => name && name.split('_')[name.split('_').length - 1]?.toLowerCase() === 'e-mail-adresse' )
  }

  variableChange(e, fieldName) {
      this.templateForm.patchValue({
        [fieldName]: `${this.templateForm.value[fieldName] || ''} ${e.option.value} `
      });
      
      setTimeout(() => this.variableForm.patchValue({[fieldName]: ''}));
  }

  updatedName(name) {
    return name
      .split('_')
      .map((ele) => `${ele.charAt(0).toUpperCase()}${ele.substring(1)}`)
      .join(' ');
  }

  fileChange() {    
  }
  
  triggerEvent(e) {
    if(!e) {
      const overlayContainerEleement: any = document.getElementsByClassName("cdk-overlay-container");
      overlayContainerEleement[0].style['z-index'] = 1000;
      this.sharedService.variableDropdownEvent.next(false);
      this.isFileSelctDisabled = true;
    }
  }

  closeDialog() {
    this.close.emit();
  }

  ngOnDestroy() {
    if (this.dialogSubscription) this.dialogSubscription.unsubscribe();
  }

  private _filterGroup(value: string, variableList: Array<any>) {
    let updatedVL = variableList;
    if (value) {
      updatedVL = variableList
        .map(group => ({ ...group.group && { group: group.group, list: this._filter(group.list, value) }, ...!group.group && { label: group.label, value: group.value, description: group.description }}))
        .filter(group => group?.list?.length > 0 || group?.value?.toLowerCase().includes(value.toLowerCase()));
    }

    const groupedArr = updatedVL.filter(({ group }) => group);
    const variableArr = updatedVL.filter(({ group }) => !group);
    return [...groupedArr, ...variableArr];
  }

  toggleSelection(event: any, group: any, field: any) {
    this.groupedVariables[field][group.group] = !this.groupedVariables[field][group.group] ? true : false;
    event.stopPropagation();
  }

  editorVariableSelected(e) {
    const cursorPosition = this.selectedIndex || 0;
    const clipboardDelta = this.quillInstance.clipboard.convert(
      '<span style="color: red">' + e.option.value + '</span><span> </span>'
    );

    const delta = new Delta()
      .retain(cursorPosition)
      .concat(clipboardDelta);
    
    this.quillInstance.focus();

    this.quillInstance.updateContents(delta, 'user');
    this.quillInstance.setSelection(cursorPosition + e.option.value.length + 1);
      
    setTimeout(() => this.variableForm.patchValue({body: ''}));
  }

  onEditorCreated(e) {
    this.quillInstance = e;
    e.root.addEventListener("blur", (e) => this.selectedIndex = this.quillInstance.getSelection().index);
  }

  _filter(opt: (string | any)[], value: string): string[]{
    const filterValue = value.toLowerCase();
    let temp = opt.filter(item => !item.value || item.value.toLowerCase().includes(filterValue));
    temp = temp.map(item => item.value ? item : {group: item.group, list: this._filter(item.list, value)});
    temp = temp.filter(item => item.value || item.list.length > 0);
    return temp;
  };

  variableFormClicked() {
    this.sharedService.variableDropdownEvent.next(true);
    const overlayContainerEleement: any = document.getElementsByClassName("cdk-overlay-container");
    overlayContainerEleement[0].style['z-index'] = 10000;
  }

}
