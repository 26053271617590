import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
  EventEmitter
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/services/app.service';
import { SharedService } from 'src/app/services/shared.service';
import { InformationTabComponent } from './information-tab/information-tab.component';
import { DialogComponent } from '../dialog/dialog.component';
import { finalize } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SocketService } from 'src/app/services/socket.service';
import { Subscription, forkJoin } from 'rxjs';
import { ComponentType } from '@angular/cdk/portal';

@Component({
  selector: 'app-case-create',
  templateUrl: './case-create.component.html',
})
export class CaseCreateComponent implements OnInit {
  @ViewChild(InformationTabComponent) informationTab: InformationTabComponent;
  @ViewChild('leftSide') leftSide: ElementRef;
  @ViewChild('mainCard') mainCard: ElementRef;
  @ViewChild('clientCard') clientCard: ElementRef;
  @ViewChild('template')
  public template: ComponentType<any>;

  public documentListEvent: EventEmitter<boolean> = new EventEmitter();

  public userDetail: any;
  public clientId = '';
  public loadingDetail = true;
  public caseValueDetail: any;
  public caseId = '';
  public viewMode = true;
  public caseDetail: any;
  public filledVariables: any;
  public documentList: any = [];
  public rightHeight = 0;
  public showCommentSection: boolean = false;
  public innerHeight = 0;
  public updating = false;
  public deleting = false;
  public feeRateForm: FormGroup;
  public dialogContent: { dialogType: string; dialogData: string } = {
    dialogType: '',
    dialogData: '',
  };
  public statusIconArr = [];
  public dialogRef: any;
  public lawyerUserList: any;
  public dialogSubscription: Subscription;
  public bottomCardMargin = '0px';
  public selectedTemplate = '';
  public selectedDocumentTemplate = '';
  public mainCardDetail: any;
  public insurerCardDetail: any;
  public expertCardDetail: any;
  public workshopCardDetail: any;
  public clientCardDetail: any;

  constructor(
    private route: ActivatedRoute,
    public sharedService: SharedService,
    public appService: AppService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    public router: Router,
    public socketService: SocketService,
    public cd: ChangeDetectorRef,
  ) {}

  @HostListener('window:beforeunload')
  canDeactivate() {
    // if (this.formChanged) {
    //   if (confirm('Möchten Sie die getätigten Änderungen verwerfen und ohne Speichern verlassen?')) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // }
    return true;
  }

  ngOnInit(): void {
    this.statusIconArr = this.sharedService.getStatusIconArr();
    this.sharedService.progress.next(true);
    this.route.paramMap.subscribe(({ params }: any) => {
      this.clientId = params.clientId;
      this.caseId = params.caseId;
      if(this.clientId) {
        this.fetchClientDetail();
      }
      this.userDetail = this.sharedService.userDetail();
    });
    this.caseValues();
    this.socketService.connect();
    this.subscribeDialogEvent();
    this.fetchLawyerUserList();
  }

  fetchClientDetail() {
    this.appService.clientDetail(this.clientId).subscribe(
      ({ data }: any) => {
        this.updateClientCardDetail({ client: data?.result });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  subscribeDialogEvent() {
    this.dialogSubscription = this.sharedService.getDialogSubmitEvent().subscribe(res => {      
      if(res?.type === 'CASE_CARD_DETAIL_SUBMITTED') {
        if(this.viewMode && this.caseDetail) {
          return;
        }
        this.updateMainCardDetail(res.data);
        this.dialogRef.close();
      }
    })
  }
  
  updateMainCardDetail(data) {
    this.mainCardDetail = {
      assignee: this.lawyerUserList.find(assignee => assignee.id === data?.assigneeId),
      lawyer: this.lawyerUserList.find(lawyer => lawyer.id === data?.lawyerId),
      caseCode: data?.caseCode,
      referrer: this.getReferrerList()?.find(referrer => referrer.id === data?.referrerId),
    };
  }

  updateInsurerCardDetail(data) {
    this.insurerCardDetail = {
      institutionMapping: data?.insuranceMapping,
      assignee: data?.insurerAssignee
    }
  }

  updateExpertCardDetail(data) {
    this.expertCardDetail = {
      institutionMapping: data?.carexpertOfficeMapping,
      assignee: data?.carExpertEmployee
    }
  }

  updateWorkshopCardDetail(data) {
    this.workshopCardDetail = {
      institutionMapping: data?.repairShopMapping,
      assignee: data?.carRepairShopEmployee
    }
  }

  updateClientCardDetail(data) {
    this.clientCardDetail = {
      client: data?.client
    }
  }

  caseValues() {
    this.appService.caseValues().subscribe(
      ({ data }: any) => {
        this.caseValueDetail = data.result;
        if (this.caseId) {
          this.fetchCaseDetail();
        } else {
          this.loadingDetail = false;
          this.sharedService.progress.next(false);
          this.updateCardMargin();
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  save() {
    if (!this.updating) {
      const payload = this.informationTab.informationTabValue();
      if (this.mainCardDetail) {
        const updatedFields = ['caseCode', 'lawyerId', 'assigneeId', 'referrerId'];
        updatedFields.forEach(ele => {
          payload.delete(ele);
          payload.append(ele, ele === 'caseCode' ? this.mainCardDetail?.caseCode : this.mainCardDetail[ele.split('Id')[0]]?.id || null);
        });
      }
      if (this.insurerCardDetail && Object.keys(this.insurerCardDetail)?.length) {
        payload.delete('insuranceMappingId');
        payload.delete('insurerAssigneeId');
        payload.append('insuranceMappingId', this.insurerCardDetail?.institutionMapping?.id || null);
        payload.append('insurerAssigneeId', this.insurerCardDetail?.assignee?.id || null);
      }
      if(this.expertCardDetail && Object.keys(this.expertCardDetail)?.length) {
        payload.delete('carexpertOfficeMappingId');
        payload.delete('carExpertEmployeeId');
        payload.append('carexpertOfficeMappingId', this.expertCardDetail?.institutionMapping?.id || null);
        payload.append('carExpertEmployeeId', this.expertCardDetail?.assignee?.id || null);
      }
      if(this.workshopCardDetail && Object.keys(this.workshopCardDetail)?.length) {
        payload.delete('repairShopMappingId');
        payload.delete('carRepairShopEmployeeId');
        payload.append('repairShopMappingId', this.workshopCardDetail?.institutionMapping?.id || null);
        payload.append('carRepairShopEmployeeId', this.workshopCardDetail?.assignee?.id || null);
      }
      if(this.clientCardDetail && Object.keys(this.clientCardDetail)?.length) {
        payload.delete('clientId');
        payload.append('clientId', this.clientCardDetail?.client?.id || null);
      }
      this.updating = true;
      this.appService.createCase(payload).pipe(finalize(() => (this.updating = false))).subscribe(
        (data:any) => {
          this.sharedService.open(`Fall wurde angelegt.`, 'success');
          this.router.navigate(['/dashboard'])
        },
        (error) => {
          console.log(error);
          this.sharedService.open(error.error.message, 'failure');
        }
      )
    }
  }

  deleteCase() {
    if(confirm('Wollen sie den Fall wirklich löschen?')){
      this.deleting = true;
        this.appService.deleteCase(this.caseId).subscribe(
            () => {
                this.sharedService.open(`Fall wurde erfolgreich gelöscht`, 'success');
                this.router.navigate(['/dashboard']);
                this.deleting = false;
            },
            (error) => {
                console.log(error);
                this.sharedService.open(error.error.message, 'failure');
                this.deleting = false;
            }
        )
    }
  }

  refreshTimeline() {
    this.fetchCaseDetail(true);
  }

  fetchCaseDetail(refreshTimeline?) {
    let apiArr = [this.appService.caseDetail(this.caseId)];
    if(!refreshTimeline) {
      apiArr = [...apiArr, this.appService.documentList(this.caseId)];
    }
    forkJoin(apiArr)
    .subscribe(
      ([ caseData, documentList ]: any) => {
        if(refreshTimeline) {
          this.caseDetail['timelines'] = caseData?.data?.result.caseValue['timelines'];
        } else {
          
          this.caseDetail = caseData?.data?.result.caseValue;
          this.filledVariables = caseData?.data?.result.filledVariables;
          this.documentList = documentList?.data?.result || [];
          this.createFeeForm();
          this.loadingDetail = false;
          this.sharedService.progress.next(false);
          this.cd.detectChanges(); 
          this.updateCardMargin();
          this.updateMainCardDetail(this.caseDetail);
          this.updateInsurerCardDetail(this.caseDetail);
          this.updateExpertCardDetail(this.caseDetail);
          this.updateWorkshopCardDetail(this.caseDetail);
          this.updateClientCardDetail(this.caseDetail);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  createFeeForm() {
    this.feeRateForm = this.fb.group({
      feeRateId: [this.caseDetail?.feeRateId || 'null', Validators.compose([])],
    });
  }

  editCase() {
    if (!this.updating)
      if (this.viewMode) {
        this.viewMode = !this.viewMode;
        this.informationTab.edit();
      } else {
        let payload = this.informationTab.edit() as FormData;
        payload.append('feeRateId', this.feeRateForm.get('feeRateId').value);
        if(this.mainCardDetail) {
          const updatedFields = ['caseCode', 'lawyerId', 'assigneeId', 'referrerId'];          
          updatedFields.forEach(ele => {
            payload.delete(ele);
            payload.append(ele, ele === 'caseCode' ? this.mainCardDetail?.caseCode : this.mainCardDetail[ele.split('Id')[0]]?.id || null);
          });
        }
        if(this.insurerCardDetail && Object.keys(this.insurerCardDetail)?.length) {
          payload.delete('insuranceMappingId');
          payload.delete('insurerAssigneeId');
          payload.append('insuranceMappingId', this.insurerCardDetail?.institutionMapping?.id || null);
          payload.append('insurerAssigneeId', this.insurerCardDetail?.assignee?.id || null);
        }
        if(this.expertCardDetail && Object.keys(this.expertCardDetail)?.length) {
          payload.delete('carexpertOfficeMappingId');
          payload.delete('carExpertEmployeeId');
          payload.append('carexpertOfficeMappingId', this.expertCardDetail?.institutionMapping?.id || null);
          payload.append('carExpertEmployeeId', this.expertCardDetail?.assignee?.id || null);
        }
        if(this.workshopCardDetail && Object.keys(this.workshopCardDetail)?.length) {
          payload.delete('repairShopMappingId');
          payload.delete('carRepairShopEmployeeId');
          payload.append('repairShopMappingId', this.workshopCardDetail?.institutionMapping?.id || null);
          payload.append('carRepairShopEmployeeId', this.workshopCardDetail?.assignee?.id || null);
        }
        if(this.clientCardDetail && Object.keys(this.clientCardDetail)?.length) {
          payload.delete('clientId');
          payload.append('clientId', this.clientCardDetail?.client?.id || null);
        }
        const editCallback = () => {
          this.sharedService.open(
            `Änderungen wurden gespeichert`,
            'success'
          );
          this.router.navigate(['/dashboard']);
          this.viewMode = !this.viewMode;
        }
        this.updating = true;
        this.updateCase(payload, editCallback);
      }
  }

  updateCase(payload, editCallback) {
    this.appService
      .updatedCase(this.caseId, payload)
      .pipe(finalize(() => (this.updating = false)))
      .subscribe(
        (data: any) => editCallback(data),
        (error) => {
          console.log(error);
          this.sharedService.open(error.error.message, 'failure');
        }
      );
  }

  openDialog(dialogType, dialogDetails): void {
    if(this.viewMode && this.caseDetail) {
      switch(dialogType) {
        case 'CREATE_CLIENT': if(!this.clientCardDetail?.client?.id) return; break;
        case 'CREATE_INSURANCE_INSTITUTE': if(!this.insurerCardDetail?.institutionMapping?.id) return; break;
        case 'CREATE_EXPERT_INSTITUTE': if(!this.expertCardDetail?.institutionMapping?.id) return; break;
        case 'CREATE_WORKSHOP_INSTITUTE': if(!this.workshopCardDetail?.institutionMapping?.id) return; break;
      }
    }
    if (this.dialogContent?.dialogType === '') {
      this.dialogContent['dialogType'] = dialogType;
      if(dialogDetails && Object.keys(dialogDetails)?.length) {
        Object.entries(dialogDetails).forEach((ele) => {
          this.dialogContent[ele[0]] = ele[1];
        })
      }
      if(!dialogDetails?.dialogData && dialogType.includes('CREATE'))
        this.backTrigger();
      this.displayDialog();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateCardMargin();
  }

  stopPropagation(e) {
    e.stopPropagation();
  }

  fetchText(...params) {
    return params.reduce(
      (acc, currVal) => (currVal ? acc + currVal + ' ' : acc),
      ''
    );
  }

  statusIcon() {
    return this.caseDetail?.statusId
      ? this.statusIconArr.find(
          ({ status }) =>
            this.caseDetail?.status?.name.toLowerCase() === status.toLowerCase()
        ) || this.statusIconArr[0]
      : this.statusIconArr[0];
  }

  initialStatus() {
    return this.caseValueDetail?.status.find(({ value }) => value === 1)?.name;
  }

  backTrigger(e?) {
    this.dialogContent['dialogType'] = this.dialogContent?.dialogType.includes(
      'INSTITUTE'
    )
      ? `${this.dialogContent?.dialogType.split('_')[1]}_INSTITUTION_LIST`
      : this.dialogContent['dialogType'] === 'CREATE_CLIENT' ? 'CLIENT_LIST' : this.dialogContent['dialogType'] === 'TEMPLATE_CREATE' ? 'TEMPLATE_LIST' : 'DOCUMENT_TEMPLATE_LIST';
  }

  navigateDetail(e) {
    this.dialogContent['dialogData'] = e;
    if (this.dialogContent?.dialogType.includes('INSTITUTION_LIST')) {
      this.dialogContent['dialogType'] = `CREATE_${this.dialogContent?.dialogType.split('_')[0]}_INSTITUTE`;
    }
  }

  displayDialog() {
    const data =
      this.dialogContent['dialogType'] === 'CREATE_CLIENT'
        ? { clientId: this.dialogContent['dialogData'] }
        : { instituteId: this.dialogContent['dialogData'] };

    this.dialogRef = this.dialog.open(this.template, {
      width: 'auto',
      disableClose: false,
      data,
    });

    let element = document
      .getElementsByClassName('cdk-global-overlay-wrapper')[0] as HTMLElement;
      if (element) {
        element.classList.add("custom-dialog-box");
      }

    this.dialogRef
      .afterClosed()
      .subscribe(
        () => {this.dialogContent = { dialogType: '', dialogData: '' };}
      );
  }

  unlinkInstitute() {
    this.dialogContent['dialogData'] = null;
    this.selectedEmployee();
  }

  selectedEmployee(e?) {
    // Object.keys(this.caseDetail).forEach((ele) => {
    //   updatedDetails.append(ele, this.caseDetail[ele]);
    // });
    switch(this.dialogContent['dialogType']){
      case 'CREATE_INSURANCE_INSTITUTE': {
        this.updateInsurerCardDetail({
          insuranceMapping: this.dialogContent['dialogData'],
          insurerAssignee: e ? e : null
        });
        break;
      };
      case 'CREATE_WORKSHOP_INSTITUTE': {
        this.updateWorkshopCardDetail({
          repairShopMapping: this.dialogContent['dialogData'],
          carRepairShopEmployee: e ? e : null
        });
        break;
      };
      case 'CREATE_EXPERT_INSTITUTE': {
        this.updateExpertCardDetail({
          carexpertOfficeMapping: this.dialogContent['dialogData'],
          carExpertEmployee: e ? e : null
        });
        break;
      };
      case 'CLIENT_LIST': {
        this.updateClientCardDetail({
          client: this.dialogContent['dialogData'],
        });
        break;
      };
    };
    this.dialogRef.close();
    
    // this.updateCase(updatedDetails, (res) => {
    //   this.caseDetail = res?.data?.result;
    //   this.dialogRef.close();
    // });
  }

  closeModal() {
    this.dialogRef.close();
  }

  updateCardMargin() {
    if(this.leftSide && this.leftSide.nativeElement && this.mainCard && this.mainCard.nativeElement && this.clientCard && this.clientCard.nativeElement) {
      this.bottomCardMargin = '0px';
      this.cd.detectChanges();
      if(this.leftSide.nativeElement.offsetHeight > 690) {
        this.bottomCardMargin = `${(this.leftSide.nativeElement.offsetHeight - (this.mainCard.nativeElement.offsetHeight + (4 * this.clientCard.nativeElement.offsetHeight))) / 4}px`;
      } 
      else {
        this.bottomCardMargin = '10px';
      }
    }
  }

  openDetailDialog() {
    this.dialogRef = this.dialog.open(DialogComponent, {
      width: '300px',
      disableClose: false,
      data: {
        lawyerUsers: this.lawyerUserList,
        caseDetail: this.mainCardDetail,
        referrerList: this.getReferrerList(),
        dialogType: 'CASE_CARD_DETAIL',
        isDisabled: this.viewMode && this.caseDetail
      }
    });
  }

  getReferrerList() {
    let referrerArr = [];

    if(this.caseDetail?.carexpertOfficeMapping) referrerArr = [this.caseDetail?.carexpertOfficeMapping?.linkedInstitution];

    if(this.caseDetail?.repairShopMapping) referrerArr = [...referrerArr, this.caseDetail?.repairShopMapping?.linkedInstitution];

    return referrerArr;
  }

  fetchLawyerUserList() {
    this.appService.instituteTypeUser('lawyer').subscribe(
      (res: any) => {
        this.lawyerUserList = res?.data?.result;
      },
      err => {
        console.log(err);
      }
    )
  }

  selectTemplate(e) {
    this.selectedTemplate = e;
    this.dialogContent['dialogType'] = `TEMPLATE_CREATE`;
  }

  selectDocumentTemplate(e) {
    this.selectedDocumentTemplate = e;
    this.dialogContent['dialogType'] = `DOCUMENT_TEMPLATE_CREATE`;
  }

  damageType() {
    const isTotalschaden = this.caseValueDetail?.typeOfDamage
        ?.find(
          (damage) =>
            damage.id === this.caseDetail?.typeOfDamageId
        )
        ?.name.toLowerCase() === 'totalschaden';
    
    const isFiktiv =
      this.caseValueDetail?.typeOfBilling
        ?.find(
          (bill) =>
            bill.id === this.caseDetail?.typeOfBillingId
        )
        ?.name.toLowerCase() === 'fiktiv';

    return isTotalschaden ? 'Totalschaden' :  isFiktiv ? 'Fiktiv' : 'Konkret';
  }

  rentalFailSelected() {
    return (
      this.caseValueDetail?.typeOfFailure
        ?.find(
          (failure) =>
            failure.id === this.caseDetail?.typeOfFailureId
        )
        ?.name.toLowerCase() === 'mietwagen'
    );
  }

  updateDocList(e) {
    this.documentList = e || [];
  }

  fileAdded(e) {
    this.documentList = [...this.documentList, e];
    this.documentListEvent.emit(this.documentList);
  }

  todayDate() {
    return new Date();
  }

  ngOnDestroy() {
    this.socketService.disconnect();
    this.dialogSubscription.unsubscribe();
  }
}
