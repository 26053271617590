import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { SharedService } from 'src/app/services/shared.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-institutions',
  templateUrl: './institutions.component.html'
})
export class InstitutionsComponent implements OnInit {

  @Input() clientId: any;
  @Input() caseDetail: any;
  @Input() documentList: any;

  @Output() deleteFiles: EventEmitter<string> = new EventEmitter<string>();
  @Output() downloadAttachment: EventEmitter<string> = new EventEmitter<string>();

  public sachbearbeiter = [];
  public kfzAnsprechpartner = [];
  public werkstattAnsprechpartner = [];
  public userDetail: any;
  public workshopTypeDetail: any;
  public expertTypeDetail: any;
  public insurerTypeDetail: any;
  private subs = new SubSink();
  public institutionForm: FormGroup;
  public insurerList: any = [];
  public insurerClerkList: any = [];
  public expertList: any = [];
  public expertClerkList: any = [];
  public workshopList: any = [];
  public workshopClerkList: any = [];
  public viewMode = true;

  constructor(public appService: AppService, public sharedService: SharedService, public fb: FormBuilder) { }

  ngOnInit(): void {
    this.userDetail = this.sharedService.userDetail();
    this.fetchInstituteTypes();
  }

  fetchInstituteTypes(){
    this.appService.instituteTypeList().subscribe(({data}: any) => {
      this.workshopTypeDetail = data?.result?.typeOfInstitution.find(({name}) => name === 'werkstatten');
      this.expertTypeDetail = data?.result?.typeOfInstitution.find(({name}) => name === 'sachverstandige');
      this.insurerTypeDetail = data?.result?.typeOfInstitution.find(({name}) => name === 'versicherungen');
      this.fetchInstitutionList();
    })
  }

  fetchInstitutionList() {
    this.subs.sink = forkJoin([
      this.appService.institutionList(this.userDetail?.user?.institutionId, this.workshopTypeDetail?.id),
      this.appService.institutionList(this.userDetail?.user?.institutionId, this.expertTypeDetail?.id),
      this.appService.institutionList(this.userDetail?.user?.institutionId, this.insurerTypeDetail?.id),
    ])
    .subscribe(
      (data:any) => {
        [this.workshopList, this.expertList, this.insurerList] = data;
        this.createForm();
      },
      error => {
        console.log(error);
      }
    )
  }

  createForm(){
    this.institutionForm = this.fb.group({
      insuranceId: [this.caseDetail?.insuranceId, Validators.compose([])],
      insurerAssigneeId: [this.caseDetail?.insurerAssigneeId, Validators.compose([])],
      insuranceMappingId: [this.caseDetail?.insuranceMappingId, Validators.compose([])],
      carexpertOfficeId: [this.caseDetail?.carexpertOfficeId, Validators.compose([])],
      carExpertEmployeeId: [this.caseDetail?.carExpertEmployeeId, Validators.compose([])],
      carexpertOfficeMappingId: [this.caseDetail?.carexpertOfficeMappingId, Validators.compose([])],
      repairShopId: [this.caseDetail?.repairShopId, Validators.compose([])],
      carRepairShopEmployeeId: [this.caseDetail?.carRepairShopEmployeeId, Validators.compose([])],
      repairShopMappingId: [this.caseDetail?.repairShopMappingId, Validators.compose([])],
      insurancePolicyID: [this.caseDetail?.insurancePolicyID, Validators.compose([])],
      insuranceClaimID: [this.caseDetail?.insuranceClaimID, Validators.compose([])],
      svReport: [this.caseDetail?.svReport, Validators.compose([])],
    })
    if(this.caseDetail) {
      this.institutionForm.disable();
    }
  }

  fetchInstitutionUser(event, instituteType) {
    var institutionId;
    if(instituteType === 'werkstatten') {
      institutionId = this.workshopList?.data?.result?.rows.find(ele => ele.id === event.value)?.linkedInstitutionId;
    }
    if(instituteType === 'sachverstandige') {
      institutionId = this.expertList?.data?.result?.rows.find(ele => ele.id === event.value)?.linkedInstitutionId;
    }
    if(instituteType === 'versicherungen') {
      institutionId = this.insurerList?.data?.result?.rows.find(ele => ele.id === event.value)?.linkedInstitutionId;
    }
    this.subs.sink = this.appService.institutionUser(institutionId).subscribe(
      ({data}: any) => {
        if(instituteType === 'werkstatten')
          this.workshopClerkList = data.result;
        if(instituteType === 'sachverstandige')
          this.expertClerkList = data.result;
        if(instituteType === 'versicherungen')
          this.insurerClerkList = data.result;
      },
      error => {
        console.log(error);
      }
    )
  }

  fileChanged(e) {
    if(!this.institutionForm.get('instituteFile'))
      this.institutionForm.addControl('instituteFile', new FormControl('', Validators.compose([])));
      
    this.institutionForm.patchValue({ instituteFile : e.target.files[0] });
  }

  formValue() {
    return this.institutionForm.value;
  }

  edit() {
    this.viewMode = !this.viewMode;
    if(this.viewMode) {
      this.institutionForm.disable();
    } else {
      this.institutionForm.enable();
    }
  }

  removeFile() {
    this.deleteFiles.emit(this.documentList.instituteFile.id);
    delete this.documentList.instituteFile;
  }

  download() {
    this.downloadAttachment.emit(this.documentList.instituteFile.filename);
  }

}
