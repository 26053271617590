import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, finalize, tap } from 'rxjs/operators';
import { AppService } from 'src/app/services/app.service';
import { SharedService } from 'src/app/services/shared.service';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-institution-list',
  templateUrl: './institution-list.component.html',
})
export class InstitutionListComponent implements OnInit {
  @Output() navigateInstitution: EventEmitter<string> = new EventEmitter<any>();
  @Output() close: EventEmitter<string> = new EventEmitter<any>();

  @Input() refInstitutionType: any;

  public userDetail: any;
  public instituteTypeDetail: any;
  public instituteType = '';
  public dataSource: any;
  public displayedColumns = ['initials', 'name', 'plz', 'ort', 'email', 'telefonNummer'];
  public pageSize = 10;
  public pageIndex = 0;
  public sortBy = '';
  public sortOrder = '';
  public isInitialLoad = true;
  public matSortActive='';
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('table') table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('searchInput') searchInput: ElementRef;

  constructor(
    private route: ActivatedRoute,
    public sharedService: SharedService,
    public appService: AppService,
    public router: Router
  ) { }

  ngOnInit(): void {
    if(this.refInstitutionType)
      this.instituteType = this.refInstitutionType;

    this.route.paramMap.subscribe(({params}:any) => {
      this.sharedService.progress.next(true);
      this.sortBy = '';
      this.sortOrder = '';
      this.matSortActive = '';
      if(this.searchInput && this.searchInput.nativeElement) {
        this.searchInput.nativeElement.value = '';
      }
      this.instituteType = params.type || this.instituteType;
      this.userDetail = this.sharedService.userDetail();
      this.fetchInstituteTypes();
    })

  }
  
  listenSearch() {
    fromEvent(this.searchInput.nativeElement, 'keydown')
    .pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => {
        this.institutionList();
      })
    )
    .subscribe();
  }

  fetchInstituteTypes(){
    this.appService.instituteTypeList().subscribe(({data}: any) => {
      this.instituteTypeDetail = data?.result?.typeOfInstitution.find(({name}) => name === this.instituteType);
      this.institutionList();
    })
  }

  institutionList(){
    const search = this.searchInput && this.searchInput.nativeElement ? this.searchInput.nativeElement.value.trim() : ''; 
    this.appService.institutionList(this.userDetail?.user?.institutionId, this.instituteTypeDetail?.id, this.pageIndex, this.pageSize, { sortBy: this.sortBy, sortOrder: this.sortOrder, search })
    .pipe(finalize(() => this.sharedService.progress.next(false)))
    .subscribe(
      ({data}: any) => {
        this.convertToTableData(data.result);
        if(this.isInitialLoad) {
          this.listenSearch();
          this.isInitialLoad = false;
        }
      },
      error => {
        console.log(error);
      }
    )
  }

  isWerkstattenInstitute(){
    return this.instituteTypeDetail?.name === 'werkstatten';
  }

  isSachverstandigeInstitute(){
    return this.instituteTypeDetail?.name === 'sachverstandige';
  }

  isVersicherungenInstitute(){
    return this.instituteTypeDetail?.name === 'versicherungen';
  }

//   showNewBtn() {
//     if(this.isWerkstattenInstitute())
//       return this.userDetail?.permissions?.includes('canAddNewCRS');
    
//     if(this.isSachverstandigeInstitute())
//       return this.userDetail?.permissions?.includes('canAddNewExpert');

//     if(this.isVersicherungenInstitute())
//       return this.userDetail?.permissions?.includes('canAddNewInsurance');

//     return false;
//   }

  navigate(institution?){
    if(institution) {
      return this.refInstitutionType ? this.navigateInstitution.emit(institution) : this.router.navigate([`/dashboard/edit/${institution.id}`]);
    } 
    this.router.navigate([`/dashboard/create/${this.instituteType}`]);
  }

  pageSizeChange(e){
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    this.institutionList();
  }

  listTitle() {
    return !this.instituteTypeDetail?.name ? '' : this.isWerkstattenInstitute() ? 'Werkstätten' : this.isSachverstandigeInstitute() ? 'Sachverständige' : 'Versicherungen';
  }

  closeDetailModal() {
    this.close.emit();
  }

  sortData(e) {
    this.matSortActive = e.active;
    this.sortBy = e.active === 'ort' ? 'city' : e.active === 'plz' ? 'postcode' : e.active === 'telefonNummer' ? 'phone' : e.active;
    this.sortOrder = e.direction;
    this.institutionList();
  }

  convertToTableData(data) {
    let tableData = {};
    tableData['header'] = [
      {id: 'initials', value: '', isSortable: false, isInitial: true },
      {id: 'name', value: 'Name', isSortable: true, isInitial: false },
      {id: 'plz', value: 'PLZ', isSortable: true, isInitial: false },
      {id: 'ort', value: 'Ort', isSortable: true, isInitial: false },
      {id: 'email', value: 'E-Mail', isSortable: true, isInitial: false },
      {id: 'telefonNummer', value: 'Telefonnummer', isSortable: true, isInitial: false },
    ];
    tableData['rows'] = data?.rows?.map((institution) => ({
      initials: institution?.linkedInstitution?.name?.charAt(0),
      name: institution?.linkedInstitution?.name,
      plz: institution?.linkedInstitution?.adress?.postcode,
      ort: institution?.linkedInstitution?.adress?.city,
      email: institution?.linkedInstitution?.email,
      telefonNummer: institution?.linkedInstitution?.phone,
      ...institution
    }));
    tableData['count'] = data?.count;
    this.dataSource = tableData;
  }

}
