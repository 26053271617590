import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AppService } from "src/app/services/app.service";
import { SharedService } from "src/app/services/shared.service";
import * as moment from 'moment';

@Component({
    selector: 'app-case-detail',
    templateUrl: './case-detail.component.html' 
})

export class CaseDetailComponent implements OnInit {
    @Input() set updatedCaseId(value: string) {
        this.caseId = value;
        this.caseValues();
     }
    @Output() closeDetailModal: EventEmitter<string> = new EventEmitter<any>();

    public caseDetail: any;
    public statusIconArr = [];
    public caseValueDetail: any;
    public caseId: any;


    constructor(public appService: AppService, public sharedService: SharedService) {}

    ngOnInit() {
        this.statusIconArr = this.sharedService.getStatusIconArr();
    }

    caseValues() {
        this.appService.caseValues().subscribe(
          ({ data }: any) => {
            this.caseValueDetail = data.result;
            this.fetchCaseDetail(this.caseId);
          },
          (error) => {
            console.log(error);
          }
        );
      }

    fetchCaseDetail(caseId) {
        this.appService.caseDetail(caseId).subscribe((res: any) => {
            this.caseDetail = res?.data?.result?.caseValue;
        },
            (error) => {
                console.log(error);
            }
        )
    }

    statusIcon() {
        return this.caseDetail?.statusId
          ? this.statusIconArr.find(
              ({ status }) =>
                this.caseDetail?.status?.name.toLowerCase() === status.toLowerCase()
            ) || this.statusIconArr[0]
          : this.statusIconArr[0];
    }

    timelineArr() {
        if(!this.caseDetail?.timelines) return [];
    
        return this.caseDetail?.timelines;
    }

    stepIcon(step) {
        const stepIconArr = this.statusIconArr.map(({ icon, ...rest }) => ({
          ...rest,
          icon: `step-icon-${icon.split('-')[2]}`,
        }));
        return step?.type === 'COMMENT_CREATED'
          ? { icon: 'comment-icon' }
          : stepIconArr.find(
              ({ status }) => step?.header.toLowerCase() === status.toLowerCase()
            ) || stepIconArr[0];
    }

    currStatus() {
        const newCase = this.caseValueDetail?.status.find(({ name }) => name?.toLowerCase() === 'fall neu');
        return this.caseDetail?.status || newCase;
    }

    stepDate(step) {
        return moment(step?.updatedAt).format('H:mm');
      }

    fetchText(...params) {
        return params.reduce(
            (acc, currVal) => (currVal ? acc + currVal + ' ' : acc),
            ''
        );
    }

    initialStatus() {
        return this.caseValueDetail?.status.find(({ value }) => value === 1)?.name;
    }

    damageType() {
        const isTotalschaden = this.caseValueDetail?.typeOfDamage
            ?.find(
              (damage) =>
                damage.id === this.caseDetail?.typeOfDamageId
            )
            ?.name.toLowerCase() === 'totalschaden';
        
        const isFiktiv =
          this.caseValueDetail?.typeOfBilling
            ?.find(
              (bill) =>
                bill.id === this.caseDetail?.typeOfBillingId
            )
            ?.name.toLowerCase() === 'fiktiv';
    
        return isTotalschaden ? 'Totalschaden' :  isFiktiv ? 'Fiktiv' : 'Konkret';
    }

    rentalFailSelected() {
        return (
          this.caseValueDetail?.typeOfFailure
            ?.find(
              (failure) =>
                failure.id === this.caseDetail?.typeOfFailureId
            )
            ?.name.toLowerCase() === 'mietwagen'
        );
    }

    todayDate() {
        return new Date();
    }
}