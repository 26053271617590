import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { forkJoin } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { SharedService } from 'src/app/services/shared.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-affected-parties',
  templateUrl: './affected-parties.component.html',
})
export class AffectedPartiesComponent implements OnInit {
  @Input() clientId: any;
  @Input() caseValueDetail: any;
  @Input() caseDetail: any;
  @Input() documentList: EventEmitter<boolean> = new EventEmitter();

  @Output() deleteFiles: EventEmitter<string> = new EventEmitter<string>();
  @Output() downloadAttachment: EventEmitter<object> =
    new EventEmitter<object>();
  
  @ViewChild('kennzeichenContainer') kennzeichenContainer: any;
  @ViewChild('kennzeichenField') kennzeichenField: any;

  @ViewChild('opponentKennzeichenContainer') opponentKennzeichenContainer: any;
  @ViewChild('opponentKennzeichenField') opponentKennzeichenField: any;

  public vehicleClass = [];
  public objectTypes = [
    {
      id: 1,
      value: 'Natürliche Person ',
    },
    {
      id: 2,
      value: 'Unternehmen ',
    },
  ];
  public anreden = [];
  public titels = [];
  public affectedPartiesForm: FormGroup;
  private subs = new SubSink();
  public lessorId: any = 1;
  public policyHolderId: any = 1;
  public isLeasedVehicle = false;
  public isClientDriver = false;
  public isOpposingVehicleDriver = false;
  public viewMode = true;
  public userDetail: any;
  public insurerList: any = [];
  public insurerClerkList: any = [];
  public insurerTypeDetail: any;
  public kennzeichenValues = {
    areaCode: '',
    letter: '',
    digit: '',
  };
  public opponentKennzeichenValues = {
    areaCode: '',
    letter: '',
    digit: '',
  };
  public focusKennzeichen = false;
  public focusOpponentKennzeichen = false;
  public tabFileChanged: any = [];
  public docList: any = [];
  public initialKennzeichenFocus = false;
  public initialOpponentKennzeichenFocus = false;

  constructor(
    public fb: FormBuilder,
    public appService: AppService,
    private sharedService: SharedService,
    private renderer: Renderer2
  ) {
    this.renderer.listen('window', 'click',(e:Event)=>{
      if(!this.kennzeichenContainer.nativeElement.contains(e.target) && this.kennzeichenContainer.nativeElement !== e.target) {
        this.initialKennzeichenFocus = false;
      }
      if(!this.opponentKennzeichenContainer.nativeElement.contains(e.target) && this.opponentKennzeichenContainer.nativeElement !== e.target) {
        this.initialOpponentKennzeichenFocus = false;
      }
    });
  }

  ngOnInit(): void {
    this.documentList.subscribe((data) => {
      if (this.tabFileChanged?.length) {
        this.docList.forEach(({ childFileAndFolder }, index) => {
          if(childFileAndFolder?.fileButtonMappingId){
            if(!this.tabFileChanged.includes(childFileAndFolder?.fileButtonMapping?.name)) {
              const newEle = data.find(
                ({ childFileAndFolder }) =>
                  childFileAndFolder?.fileButtonMappingId === childFileAndFolder?.fileButtonMappingId
              );
              this.docList.splice(index, 1, newEle ? newEle : {});
            }
          }
        });
      } else {
        this.docList = JSON.parse(JSON.stringify(data));;
      }
    });
    this.fetchInstituteTypes();
    this.userDetail = this.sharedService.userDetail();
  }

  createForm() {
    this.affectedPartiesForm = this.fb.group({
      clientPowerAttorney: [
        this.caseDetail?.clientPowerAttorney,
        Validators.compose([Validators.required]),
      ],
      vehicleLicenceNumber: [
        this.caseDetail?.vehicleLicenceNumber,
        Validators.compose([Validators.required]),
      ],
      vehicleMake: [this.caseDetail?.vehicleMake, Validators.compose([])],
      vehicleModel: [this.caseDetail?.vehicleModel, Validators.compose([])],
      vehicleClassId: [this.caseDetail?.vehicleClassId, Validators.compose([])],
      vehicleLFirstRegistration: [
        this.caseDetail?.vehicleLFirstRegistration,
        Validators.compose([]),
      ],
      leasingPersonFirstName: [
        this.caseDetail?.leasingPersonFirstName,
        Validators.compose([]),
      ],
      leasingPersonLastName: [
        this.caseDetail?.leasingPersonLastName,
        Validators.compose([]),
      ],
      leasingSalutationId: [
        this.caseDetail?.leasingSalutationId,
        Validators.compose([]),
      ],
      leasingTitleId: [this.caseDetail?.leasingTitleId, Validators.compose([])],
      leasingProviderName: [
        this.caseDetail?.leasingProviderName,
        Validators.compose([]),
      ],
      leasingContractNumber: [
        this.caseDetail?.leasingContractNumber,
        Validators.compose([]),
      ],
      leasingCity: [
        this.caseDetail?.leasingAddress?.city,
        Validators.compose([]),
      ],
      leasingStreet: [
        this.caseDetail?.leasingAddress?.street,
        Validators.compose([]),
      ],
      leasingPostcode: [
        this.caseDetail?.leasingAddress?.postcode,
        Validators.compose([]),
      ],
      leasingHouseNumber: [
        this.caseDetail?.leasingAddress?.houseNumber,
        Validators.compose([]),
      ],
      driverSalutationId: [
        this.caseDetail?.driverSalutationId,
        Validators.compose([]),
      ],
      driverTitleId: [this.caseDetail?.driverTitleId, Validators.compose([])],
      driverFirstName: [
        this.caseDetail?.driverFirstName,
        Validators.compose([]),
      ],
      driverLastName: [this.caseDetail?.driverLastName, Validators.compose([])],
      driverCity: [
        this.caseDetail?.driverAddress?.city,
        Validators.compose([]),
      ],
      driverStreet: [
        this.caseDetail?.driverAddress?.street,
        Validators.compose([]),
      ],
      driverPostcode: [
        this.caseDetail?.driverAddress?.postcode,
        Validators.compose([]),
      ],
      driverHouseNumber: [
        this.caseDetail?.driverAddress?.houseNumber,
        Validators.compose([]),
      ],
      opponentLicenceNumber: [
        this.caseDetail?.opponentLicenceNumber,
        Validators.compose([]),
      ],
      opponentSalutationId: [
        this.caseDetail?.opponentSalutationId,
        Validators.compose([]),
      ],
      opponentTitleId: [
        this.caseDetail?.opponentTitleId,
        Validators.compose([]),
      ],
      opponentFirstName: [
        this.caseDetail?.opponentFirstName,
        Validators.compose([]),
      ],
      opponentLastName: [
        this.caseDetail?.opponentLastName,
        Validators.compose([]),
      ],
      opponentCompanyName: [
        this.caseDetail?.opponentCompanyName,
        Validators.compose([]),
      ],
    //   opponentUstId: [this.caseDetail?.opponentUstId, Validators.compose([])],
      opponentVehicleMake: [
        this.caseDetail?.opponentVehicleMake,
        Validators.compose([]),
      ],
      opponentCity: [
        this.caseDetail?.opponentAddress?.city,
        Validators.compose([]),
      ],
      opponentStreet: [
        this.caseDetail?.opponentAddress?.street,
        Validators.compose([]),
      ],
      opponentPostcode: [
        this.caseDetail?.opponentAddress?.postcode,
        Validators.compose([]),
      ],
      opponentHouseNumber: [
        this.caseDetail?.opponentAddress?.houseNumber,
        Validators.compose([]),
      ],
      opponentDriverSalutationId: [
        this.caseDetail?.opponentDriverSalutationId,
        Validators.compose([]),
      ],
      opponentDriverTitleId: [
        this.caseDetail?.opponentDriverTitleId,
        Validators.compose([]),
      ],
      opponentDriverFirstName: [
        this.caseDetail?.opponentDriverFirstName,
        Validators.compose([]),
      ],
      opponentDriverLastName: [
        this.caseDetail?.opponentDriverLastName,
        Validators.compose([]),
      ],
      opponentDriverCity: [
        this.caseDetail?.opponentDriverAddress?.city,
        Validators.compose([]),
      ],
      opponentDriverStreet: [
        this.caseDetail?.opponentDriverAddress?.street,
        Validators.compose([]),
      ],
      opponentDriverPostcode: [
        this.caseDetail?.opponentDriverAddress?.postcode,
        Validators.compose([]),
      ],
      opponentDriverHouseNumber: [
        this.caseDetail?.opponentDriverAddress?.houseNumber,
        Validators.compose([]),
      ],

      insuranceId: [this.caseDetail?.insuranceId, Validators.compose([])],
      insurerAssigneeId: [
        this.caseDetail?.insurerAssigneeId,
        Validators.compose([]),
      ],
      insuranceMappingId: [
        this.caseDetail?.insuranceMappingId,
        Validators.compose([]),
      ],
      insurancePolicyID: [
        this.caseDetail?.insurancePolicyID,
        Validators.compose([]),
      ],
      insuranceClaimID: [
        this.caseDetail?.insuranceClaimID,
        Validators.compose([]),
      ],
    });
    if (this.caseDetail) {
      this.affectedPartiesForm.disable();
      this.isLeasedVehicle = !!(
        this.caseDetail.leasingSalutationId ||
        this.caseDetail.leasingTitleId ||
        this.caseDetail.leasingPersonFirstName ||
        this.caseDetail.leasingPersonLastName ||
        this.caseDetail.leasingProviderName ||
        this.caseDetail.leasingContractNumber
      );
      this.isClientDriver = !(
        this.caseDetail.driverSalutationId ||
        this.caseDetail.driverTitleId ||
        this.caseDetail.driverFirstName ||
        this.caseDetail.driverLastName ||
        this.caseDetail.driverAddress?.city ||
        this.caseDetail.driverAddress?.street ||
        this.caseDetail.driverAddress?.postcode ||
        this.caseDetail.driverAddress?.houseNumber
      );
      this.isOpposingVehicleDriver = !(
        this.caseDetail.opponentDriverSalutationId ||
        this.caseDetail.opponentDriverTitleId ||
        this.caseDetail.opponentDriverFirstName ||
        this.caseDetail.opponentDriverLastName ||
        this.caseDetail.opponentDriverAddress?.city ||
        this.caseDetail.opponentDriverAddress?.street ||
        this.caseDetail.opponentDriverAddress?.postcode ||
        this.caseDetail.opponentDriverAddress?.houseNumber
      );
      this.kennzeichenValues = {
        areaCode: this.affectedPartiesForm
          .get('vehicleLicenceNumber')
          .value?.split('-')?.[0],
        letter: this.affectedPartiesForm
          .get('vehicleLicenceNumber')
          .value?.split('-')?.[1],
        digit: this.affectedPartiesForm
          .get('vehicleLicenceNumber')
          .value?.split('-')?.[2],
      };
      this.opponentKennzeichenValues = {
        areaCode: this.affectedPartiesForm
          .get('opponentLicenceNumber')
          .value?.split('-')?.[0],
        letter: this.affectedPartiesForm
          .get('opponentLicenceNumber')
          .value?.split('-')?.[1],
        digit: this.affectedPartiesForm
          .get('opponentLicenceNumber')
          .value?.split('-')?.[2],
      };
      this.policyHolderId =
        this.caseDetail.opponentCompanyName || this.caseDetail.opponentUstId
          ? 2
          : 1;
    }
  }

  initialValues() {
    this.subs.sink = this.appService.salutationTitleList().subscribe(
      ({ data }: any) => {
        this.anreden = data.result.salutation;
        this.titels = data.result.title;
        this.vehicleClass = this.caseValueDetail?.classOfVehicle;
        this.createForm();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  fileChanged(e) {
    const attorneyFile = this.caseValueDetail?.fileButtonMapping.find(
      ({ name }) => name === 'vollmacht'
    );
    if (!attorneyFile)
      return this.sharedService.open('Etwas ist schief gelaufen!', 'failure');

    const { id: attorneyControl } = attorneyFile;

    if (!this.affectedPartiesForm.get(attorneyControl)) {
      this.affectedPartiesForm.addControl(
        attorneyControl,
        new FormControl('', Validators.compose([]))
      );
    }
    this.tabFileChanged.push('vollmacht');
    this.affectedPartiesForm.patchValue({
      [attorneyControl]: e.target.files[0],
    });
  }

  formValue() {
    let formValue = this.affectedPartiesForm.value;
    if (this.isLeasedVehicle) {
      if (this.lessorId === 1) {
        delete formValue.leasingProviderName;
        delete formValue.leasingContractNumber;
      } else {
        delete formValue.leasingSalutationId;
        delete formValue.leasingTitleId;
        delete formValue.leasingPersonFirstName;
        delete formValue.leasingPersonLastName;
      }
    } else {
      delete formValue.leasingProviderName;
      delete formValue.leasingContractNumber;
      delete formValue.leasingSalutationId;
      delete formValue.leasingTitleId;
      delete formValue.leasingPersonFirstName;
      delete formValue.leasingPersonLastName;
    }
    if (this.isClientDriver) {
      formValue.driverSalutationId = null;
      formValue.driverTitleId = null;
      formValue.driverFirstName = null;
      formValue.driverLastName = null;
      formValue.driverStreet = null;
      formValue.driverHouseNumber = null;
      formValue.driverPostcode = null;
      formValue.driverCity = null;
    }
    if (this.policyHolderId === 1) {
      formValue.opponentCompanyName = null;
    //   formValue.opponentUstId = null;
    } else {
      formValue.opponentSalutationId = null;
      formValue.opponentTitleId = null;
    }
    if (this.isOpposingVehicleDriver) {
      formValue.opponentDriverSalutationId = null;
      formValue.opponentDriverTitleId = null;
      formValue.opponentDriverFirstName = null;
      formValue.opponentDriverLastName = null;
      formValue.opponentDriverStreet = null;
      formValue.opponentDriverHouseNumber = null;
      formValue.opponentDriverPostcode = null;
      formValue.opponentDriverCity = null;
    }
    return formValue;
  }

  edit() {
    this.viewMode = !this.viewMode;
    if (this.viewMode) {
      this.affectedPartiesForm.disable();
    } else {
      this.affectedPartiesForm.enable();
    }
  }

  removeFile() {
    const index = this.docList.findIndex(
      ({ childFileAndFolder }) =>
        childFileAndFolder?.fileButtonMapping?.name === 'vollmacht'
    );
    this.deleteFiles.emit(this.docList[index].childFileAndFolder?.id);
    this.docList.splice(index, 1);
    this.tabFileChanged.push('vollmacht');
  }

  download() {
    const file = this.isFilePresent('vollmacht');
    this.downloadAttachment.emit({
      path: file?.childFileAndFolder?.path,
      name: file?.childFileAndFolder?.name,
    });
  }

  fetchInstituteTypes() {
    this.appService.instituteTypeList().subscribe(({ data }: any) => {
      this.insurerTypeDetail = data?.result?.typeOfInstitution.find(
        ({ name }) => name === 'versicherungen'
      );
      this.fetchInstitutionList();
    });
  }

  fetchInstitutionList() {
    this.subs.sink = forkJoin([
      this.appService.institutionList(
        this.userDetail?.user?.institutionId,
        this.insurerTypeDetail?.id
      ),
    ]).subscribe(
      (data: any) => {
        [this.insurerList] = data;
        this.initialValues();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  fetchInstitutionUser(event, instituteType) {
    var institutionId;
    if (instituteType === 'versicherungen') {
      institutionId = this.insurerList?.data?.result?.rows.find(
        (ele) => ele.id === event.value
      )?.linkedInstitutionId;
    }
    this.subs.sink = this.appService.institutionUser(institutionId).subscribe(
      ({ data }: any) => {
        if (instituteType === 'versicherungen')
          this.insurerClerkList = data.result;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onFocus() {
    this.focusKennzeichen = true;
    if(!this.initialKennzeichenFocus) {
      this.initialKennzeichenFocus = true;
      this.kennzeichenField.nativeElement.focus();
    }
  }

  onBlur() {
    this.focusKennzeichen = false;
  }

  onOpponentFocus() {
    this.focusOpponentKennzeichen = true;
    if(!this.initialOpponentKennzeichenFocus) {
      this.initialOpponentKennzeichenFocus = true;
      this.opponentKennzeichenField.nativeElement.focus();
    }
  }

  onOpponentBlur() {
    this.focusOpponentKennzeichen = false;
  }

  kennzeichenInputChange(isOpponent?) {
    setTimeout(() => {
      const kennzeichenField = isOpponent
        ? this.opponentKennzeichenValues
        : this.kennzeichenValues;
      const formField = isOpponent
        ? 'opponentLicenceNumber'
        : 'vehicleLicenceNumber';
      if (Object.values(kennzeichenField).some((ele) => ele && ele.length)) {
        const value = `${
          kennzeichenField.areaCode ? kennzeichenField.areaCode : ''
        }-${kennzeichenField.letter ? kennzeichenField.letter : ''}-${
          kennzeichenField.digit ? kennzeichenField.digit : ''
        }`;
        this.affectedPartiesForm.patchValue(
          isOpponent
            ? {
                opponentLicenceNumber: value,
              }
            : {
                vehicleLicenceNumber: value,
              }
        );
        if (
          this.affectedPartiesForm
            ?.get('vehicleLicenceNumber')
            ?.value?.split('-')?.length !== 3 ||
          !this.affectedPartiesForm
            ?.get('vehicleLicenceNumber')
            ?.value?.split('-')
            ?.every((ele) => ele.length)
        ) {
          this.affectedPartiesForm.controls[formField].setErrors({
            invalidValue: true,
          });
        }
      } else {
        this.affectedPartiesForm.patchValue(
          isOpponent
            ? {
                opponentLicenceNumber: '',
              }
            : {
                vehicleLicenceNumber: '',
              }
        );
        this.affectedPartiesForm.controls[formField].setErrors({
          invalidValue: false,
        });
      }
    });
  }

  isFilePresent(fileName) {
    return this.docList?.find(
      ({ childFileAndFolder }) =>
        childFileAndFolder?.fileButtonMapping?.name === fileName
    );
  }
}
