import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-personal-damage',
  templateUrl: './personal-damage.component.html',
})
export class PersonalDamageComponent implements OnInit {

  @Input() clientId: any;
  @Input() caseDetail: any;
  @Input() documentList: any;
  @Input() caseValueDetail: any;

  @Output() deleteFiles: EventEmitter<string> = new EventEmitter<string>();
  @Output() downloadAttachment: EventEmitter<object> = new EventEmitter<object>();

  public otherDamageForm: FormGroup;
  public viewMode = true;
  public tabFileChanged: any = [];
  public docList: any = [];

  constructor(public fb: FormBuilder, public sharedService: SharedService, public cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.documentList.subscribe((data) => {
      if (this.tabFileChanged?.length) {
        this.docList.forEach(({ childFileAndFolder }, index) => {
          if(childFileAndFolder?.fileButtonMappingId){
            if(!this.tabFileChanged.includes(childFileAndFolder?.fileButtonMapping?.name)) {
              const newEle = data.find(
                ({ childFileAndFolder }) =>
                  childFileAndFolder?.fileButtonMappingId === childFileAndFolder?.fileButtonMappingId
              );
              this.docList.splice(index, 1, newEle ? newEle : {});
            }
          }
        });
      } else {
        this.docList = JSON.parse(JSON.stringify(data));
        this.cd.detectChanges();
      }
    });
    this.createForm();
  }

  createForm() {
    this.otherDamageForm = this.fb.group({
      personalInjuryDescription: [this.caseDetail?.personalInjuryDescription, Validators.compose([])],
      diagnosis: [this.caseDetail?.diagnosis, Validators.compose([])],
      personalInjuryCompensation: [this.caseDetail?.personalInjuryCompensation, Validators.compose([])],
      personalContribution: [this.caseDetail?.personalContribution, Validators.compose([])],
      transport: [this.caseDetail?.transport, Validators.compose([])],
      lostProfit: [this.caseDetail?.lostProfit, Validators.compose([])],
      earningLoss: [this.caseDetail?.earningLoss, Validators.compose([])],
      ride: [this.caseDetail?.ride, Validators.compose([])],
      housekeeping: [this.caseDetail?.housekeeping, Validators.compose([])],
      frustrated: [this.caseDetail?.frustrated, Validators.compose([])],
      downgrade: [this.caseDetail?.downgrade, Validators.compose([])],
    });
    if(this.caseDetail) {
      this.otherDamageForm.disable();
    }
  }

  formValue() {
    return this.otherDamageForm.value;
  }

  fileChanged(e, fieldName) {
    const fieldFile = this.caseValueDetail?.fileButtonMapping.find(
      ({ name }) => name === fieldName
    );
    if (!fieldFile)
      return this.sharedService.open('Etwas ist schief gelaufen!', 'failure');

    const { id: fieldControl } = fieldFile;

    if (!this.otherDamageForm.get(fieldControl)) {
      this.otherDamageForm.addControl(
        fieldControl,
        new FormControl('', Validators.compose([]))
      );
    }
    this.tabFileChanged.push(fieldName);
    this.otherDamageForm.patchValue({
      [fieldControl]: e.target.files[0],
    });
  }

  edit() {
    this.viewMode = !this.viewMode;
    if(this.viewMode) {
      this.otherDamageForm.disable();
    } else {
      this.otherDamageForm.enable();
    }
  }

  removeFile(field) {
    const index = this.docList.findIndex(
      ({ childFileAndFolder }) =>
        childFileAndFolder?.fileButtonMapping?.name === field
    );
    if(index !== -1) {
      this.deleteFiles.emit(this.docList[index].childFileAndFolder?.id);
      this.docList.splice(index, 1);
    }
    this.tabFileChanged.push(field);
  }

  download(field) {
    const file = this.isFilePresent(field);
    this.downloadAttachment.emit({
      path: file?.childFileAndFolder?.path,
      name: file?.childFileAndFolder?.name,
    });
  }

  isFilePresent(fileName) {
    return this.docList?.find(
      ({ childFileAndFolder }) =>
        childFileAndFolder?.fileButtonMapping?.name === fileName
    );
  }
}
