import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { SharedService } from "src/app/services/shared.service";

@Component({
    selector: 'app-variable-dropdown',
    templateUrl: './variable-dropdown.component.html'
  })
  export class VariableDropdownComponent implements OnInit {
    @Output() logicVariableSelected: EventEmitter<any> = new EventEmitter<any>();

    @Input() groupedVariableList: any = [];
    @Input() currVariable: any = '';

    variableForm: FormGroup;
    groupedVariables: any = {
        isExpandBody: {}
    };
    bodyObservable: Observable<any>;

    constructor(private fb: FormBuilder, private sharedService:SharedService) {

    }

    ngOnInit() {
        this.variableForm = this.fb.group({
            variable: [this.currVariable ? this.currVariable : null]
        });

        this.bodyObservable = this.variableForm.get('variable')!.valueChanges
          .pipe(
            startWith(''),
            map(value => {
              return this._filterGroup(value, this.groupedVariableList);
            })
        )
    }

    variableFormClicked() {
        this.sharedService.variableDropdownEvent.next(true);
        const overlayContainerEleement: any = document.getElementsByClassName("cdk-overlay-container");
        overlayContainerEleement[0].style['z-index'] = 10000;
    }

    triggerEvent(e) {
        if (!e) {
          const overlayContainerEleement: any = document.getElementsByClassName("cdk-overlay-container");
          overlayContainerEleement[0].style['z-index'] = 1000;
          this.sharedService.variableDropdownEvent.next(false);
        }
    }

    editorVariableSelected(e) {
        this.logicVariableSelected.emit({ value: e?.option?.value });
    }

    displayProperty(value) {
        if (value) {
            return value.value;
        }
    }

    _filterGroup(value: any, variableList: Array<any>) {
        let updatedVL = variableList;
        if (value) {
            const filterValue = typeof value === 'string' ? value.toLowerCase() : value?.value.toLowerCase();
            updatedVL = variableList
                .map(group => ({ ...group.group && { group: group.group, list: this._filter(group.list, value) }, ...!group.group && { ...group, label: group.label, value: group.value, description: group.description } }))
                .filter(group => group?.list?.length > 0 || group?.value?.toLowerCase().includes(filterValue));
        }
    
        const groupedArr = updatedVL.filter(({ group }) => group);
        const variableArr = updatedVL.filter(({ group }) => !group);
        return [...groupedArr, ...variableArr];
    }
    
    _filter(opt: (string | any)[], value: any): string[] {
        const filterValue = typeof value === 'string' ? value.toLowerCase() : value?.value.toLowerCase();
        let temp = opt.filter(item => !item.value || item.value.toLowerCase().includes(filterValue));
        temp = temp.map(item => item.value ? item : { group: item.group, list: this._filter(item.list, value) });
        temp = temp.filter(item => item.value || item.list.length > 0);
        return temp;
    };

    toggleSelection(event: any, group: any, field: any) {
        this.groupedVariables[field][group.group] = !this.groupedVariables[field][group.group] ? true : false;
        event.stopPropagation();
    }
}
