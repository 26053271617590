export const configData = {
    SERVER: '', //'http://localhost:3000',
    version: '/api/v1',
    login: '/users/login',
    getUserInformation: '/users/getUserInformation',
    register: '/users/register',
    deleteUser: '/users/removeUser',
    invite: '/users/invite',
    addMapping: '/users/addMapping',
    removeMapping: '/users/removeMapping',
    forgotPassword: '/users/forgotpassword',
    resetPassword: '/users/reset-password',
    changePassword: '/users/change-password',
    updateUserInformation: '/users/updateUserInformation',
    logout: '/users/logout',
    updateTheme: '/users/updateTheme',
    typeOfInstitution: '/shared/typeOfInstitution',
    institute: '/institute',
    user: '/user',
    coworker: '/coworker',
    coworkerRole: '/coworker/role',
    titleAndSalutation: '/shared/titleAndSalutation',
    client: '/client',
    caseValues: '/shared/caseValues',
    case: '/case',
    download: '/download',
    comments: '/comments',
    instituteType: '/instituteType',
    document: '/documents',
    folder: '/folder',
    file: '/file',
    mailTemplate: '/mailTemplate',
    favorite: '/favorite',
    caseVariable: '/caseVariable',
    textBlockTemplate: '/textBlockTemplate',
    sendMail: '/send-mail',
    documentTemplate: '/documentTemplate',
    conditions: '/conditions',
    permissions: '/users/permissions',
    tokenInformation: '/users/getTokenInformation'
};
