import { DatePipe } from '@angular/common';
import { Component, OnInit, Input, EventEmitter, Output, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-document-template-list',
  templateUrl: './document-template-list.component.html'
})
export class DocumentTemplateListComponent implements OnInit {
  @ViewChild('searchInput') searchInput: ElementRef;

  @Input() isDialogView: any;
  
  @Output() close: EventEmitter<string> = new EventEmitter<any>();
  @Output() selectTemplate: EventEmitter<string> = new EventEmitter<any>();

  public pageSize = 20;
  public pageIndex = 0;
  public sortBy = '';
  public sortOrder = '';
  public dataSource: any = [];
  public displayedColumns = ['name', 'createdBy', 'createdAt', 'actions'];
  public tempObj = { id: '43o8543jeori' };
  public isInitialLoad = true;
  public matSortActive='';

  constructor(public appService: AppService, public router: Router, private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.documentTemplateList();
  }

  documentTemplateList() {
    const search = this.searchInput && this.searchInput.nativeElement ? this.searchInput.nativeElement.value.trim() : ''; 
    this.appService.documentTemplateList(this.pageIndex, this.pageSize, { sortBy: this.sortBy, sortOrder: this.sortOrder, search }).subscribe(
      (res: any) => {
        this.convertToTableData(res?.data.result);
        if(this.isInitialLoad) {
          this.listenSearch();
          this.isInitialLoad = false;
        }
      }, 
      err => {
        console.log(err);
      }
    )
  }

  navigate(template?){
    if(template) {
      if (this.isDialogView) {
        return this.selectTemplate.emit(template.id);
      }
      return this.router.navigate([`/dashboard/editDocument/${template.id}`]);
    }

    this.router.navigate([`/dashboard/createDocument`]);
  }

  listenSearch() {
    fromEvent(this.searchInput.nativeElement, 'keydown')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.documentTemplateList();
        })
      )
      .subscribe();
  }

  pageSizeChange(e){
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    this.documentTemplateList();
  }

  fetchText(...params) {
    return params.reduce((acc, currVal) => currVal ? acc + currVal + ' ': acc, '');
  }

  copy(e, template) {
    e.stopPropagation();
    return this.router.navigate([`/dashboard/editDocument/${template.id}`], {queryParams : { create_new: true }});
  }

  markFavorite(e, template) {
    e.stopPropagation();
    if(!this.isDialogView) {
      this.appService.markDocumentTemplateFavorite(template.id, { favorite:  template.favorite === 'false' ? 'true' : 'false' }).subscribe(
        res => {
          this.documentTemplateList();
        },
        error => {
          console.log(error);
        }
      )
    }
  }

  closeDialog() {
    this.close.emit();
  }

  sortData(e) {
    this.matSortActive = e.active;
    this.sortBy = e.active;
    this.sortOrder = e.direction;
    this.documentTemplateList();
  }

  convertToTableData(data) {
    let tableData = {};
    tableData['header'] = [
      {id: 'name', value: 'Titel', isSortable: true, isInitial: false },
      {id: 'createdBy', value: 'Autor', isSortable: true, isInitial: false },
      {id: 'createdAt', value: 'Erstellungsdatum', isSortable: true, isInitial: false },
      {id: 'actions', value: '', isSortable: false, isInitial: false}
    ];
    tableData['rows'] = data?.rows?.map((document) => ({
      ...document,
      createdBy: this.fetchText(
        document?.createdBy?.firstName,
        document?.createdBy?.lastName
      ),
      createdAt: this.datePipe.transform(document?.createdAt, 'dd.MM.yyyy'),
      actions: [
        ...(!this.isDialogView ? [{
          icon: "copy", onClick: this.copy.bind(this)
        }] : []),
        {
          icon: document.favorite === "true" ? "heart-filled-icon" : "heart", onClick: this.markFavorite.bind(this)
        }
      ],
    }));
    tableData['count'] = data?.count;
    this.dataSource = tableData;
  }

}
