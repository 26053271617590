import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MustMatch } from '../../components/shared/MustMatch.validator';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { SharedService } from 'src/app/services/shared.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-resetpwdlink',
  templateUrl: './resetpwdlink.component.html',
  styleUrls: ['./resetpwdlink.component.css']
})
export class ResetpwdlinkComponent implements OnInit, OnDestroy {
  resetLinkForm: FormGroup;
  public errMsg = {
    message: 'No alerts'
  };
  submitted = false;
  invalidLink = false;
  private subs = new SubSink();
  routeParams: any;
  username: '';
  token: '';

  constructor(
    private router: Router,
    // private userService: UserService,
    private formBuilder: FormBuilder, private currentRoute: ActivatedRoute,
    private snackbarService: SnackbarService, private sharedService: SharedService) {
    this.subs.sink = this.routeParams = this.currentRoute.params.subscribe(
      params => {
        this.username = params.username;
        this.token = params.token;
      }
    );
    // this.subs.sink = this.userService.resetlinkVerify(this.username, this.token)
    //   .subscribe(
    //     data => { console.log(data); },
    //     error => {
    //       {
    //         console.error(error); this.resetError(error); this.invalidLink = true;
    //         this.snackbarService.open(this.errMsg.message, 'failure');
    //       }
    //     }
    //   );
  }
  ngOnInit() {
    this.resetLinkForm = this.formBuilder.group({
      password: new FormControl(null, [Validators.required, Validators.minLength(5)]),
      cpass: new FormControl(null, Validators.required)
    },
      {
        validator: MustMatch('password', 'cpass')
      });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.sharedService.progress.next(false);
  }

  get getPassword() { return this.resetLinkForm.get('password'); }
  get getCPass() { return this.resetLinkForm.get('cpass'); }

  resetPwdLink() {
    this.submitted = true;
    if (!this.resetLinkForm.valid) {
      this.validateAllFormFields(this.resetLinkForm);
      console.log('invalid');
      return;
    } else {
      this.sharedService.progress.next(true);
      // this.subs.sink = this.userService.resetPwdLink(JSON.stringify(this.resetLinkForm.value), this.username)
      //   .subscribe(
      //     data => {
      //       console.log(data);
      //       this.sharedService.logoutUser();
      //       this.snackbarService.open('Password reset has been successful !', 'success');
      //     },
      //     error => {
      //       { console.error(error); this.resetError(error); this.snackbarService.open(this.errMsg.message, 'failure'); }
      //     }
      //   );
      this.sharedService.progress.next(false);
    }
  }

  resetError(error: HttpErrorResponse) {
    this.errMsg = error.error;
  }
  // ===================== Validations ========================== //

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  get validations() { return this.resetLinkForm.controls; }

}
