import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { AppService } from 'src/app/services/app.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-case-list',
  templateUrl: './case-list.component.html'
})
export class CaseListComponent implements OnInit {

  @Input() clientId: any;
  @Input() refClientId: any;
  @Input() clientDetail: any;
  @ViewChild('searchInput') searchInput: ElementRef;

  public dataSource: any;
  public pageSize = 20;
  public pageIndex = 0;
  public firstColumns = ['aktenzeichen', 'unfallDatum', 'sachbearbeiter', 'kennzeichen', 'sachverständiger'];
  public secondColumns = ['mandant', 'unfallgegner', 'gegnerischeHaftpflichtversicherung', 'markeModell', 'werkstatt'];
  public displayedColumns = ['caseCode', 'opponentName', 'status', 'expert_workshop', 'sachbearbeiter_anwalt', 'license',]
  public currentIndex = -1;
  public sortBy = 'caseCode';
  public sortOrder = 'desc';
  public isInitialLoad = true;
  public userDetail: any;
  public caseId: any;

  constructor(public appService: AppService, public router: Router, public sharedService: SharedService) { }

  ngOnInit(): void {
    this.listCase();
    this.userDetail = this.sharedService.userDetail();
  }

  fetchText(...params) {
    return params.reduce((acc, currVal) => currVal ? acc + currVal + ' ': acc, '');
  }

  listCase() {
    const search = this.searchInput && this.searchInput.nativeElement ? this.searchInput.nativeElement.value.trim() : ''; 
    this.appService.caseList(this.clientId, { sortBy: this.sortBy, sortOrder: this.sortOrder, search }).subscribe(
      ({data}: any) => {
        this.dataSource = data.result;
        this.vehicleContainerWidth();
        if(this.isInitialLoad) {
          this.listenSearch();
          this.isInitialLoad = false;
        }
      },
      (error) => {
        console.log(error);
      }
    )
  }

  mouseover(index){
    this.currentIndex = index;
  }

    pageView(){
        if (this.userDetail.permissions.includes('lawyerView')) {
            return 'lawyerView'
        }
        if (this.userDetail.permissions.includes('carRepairView')) {
            return 'carRepairView'
        }
        if (this.userDetail.permissions.includes('carExpertView')) {
            return 'carExpertView'
        }
    }

  showDetails(mandat) {
    if(this.userDetail.permissions.includes('canViewCompleteCaseDetail')) {
        const url = this.router.serializeUrl(
            this.router.createUrlTree(['dashboard/editCase', mandat.id])
          );
          window.open(url, '_blank');
    } else {
      this.caseId = mandat.id;
    }
  }

  createCase() {
    this.router.navigate(['dashboard/createCase', this.clientDetail.id]);
  }

  sortData(e) {
    this.sortBy = e.active === 'license' ? 'license' : e.active;
    this.sortOrder = e.direction;
    this.listCase();
  }

  stickerLeft(mandat) {
    const areaCodeLength = mandat?.vehicleLicenceNumber?.split('-')[0]?.length;
    return areaCodeLength === 1 ? '35px' : areaCodeLength === 2 ? '48px' : '60px';
  }

  licenseContainerLeft(mandat) {
    const areaCodeLength = mandat?.vehicleLicenceNumber?.split('-')[0]?.length;
    return areaCodeLength === 1 ? '30px' : areaCodeLength === 2 ? '44px' : '55px';
  }

  vehicleContainerWidth() {
    setTimeout(() => {
      this.dataSource.rows.forEach((ele, index) => {
        const countryWidth = document.getElementById(`countryId${index}`)?.clientWidth;
        const areaWidth = document.getElementById(`areaId${index}`)?.clientWidth;
        const stickerWidth = document.getElementById(`stickerId${index}`)?.clientWidth;
        const licenseWidth = document.getElementById(`licenseId${index}`)?.clientWidth;
        ele['licenseWidth'] = `${countryWidth + areaWidth + stickerWidth + licenseWidth + 25}px`;
      });
    });
  }

  listenSearch() {
    fromEvent(this.searchInput.nativeElement, 'keydown')
    .pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => {
        this.listCase();
      })
    )
    .subscribe();
  }

  closeDetailModal() {
    this.caseId = null;
  }

}
