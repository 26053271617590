import { Component, ViewChild, ViewEncapsulation, Input, EventEmitter, Output, HostListener, ElementRef } from '@angular/core';
import {
  DocumentEditorComponent, EditorService, SelectionService, EditorHistoryService, SfdtExportService, ContextMenuService, TextExportService,
  CustomToolbarItemModel
} from '@syncfusion/ej2-angular-documenteditor';

import { DocumentEditorContainerComponent, ImageFormat } from '@syncfusion/ej2-angular-documenteditor';
import { AppService } from 'src/app/services/app.service';
import { forkJoin, Observable, Subscription, of } from 'rxjs';
import { NgForm } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { SharedService } from 'src/app/services/shared.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';
import {
  PdfBitmap,
  PdfDocument,
  PdfPageOrientation,
  PdfPageSettings,
  PdfSection,
  SizeF,
} from '@syncfusion/ej2-pdf-export';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from "file-saver";
import { finalize } from 'rxjs/operators';
import { TitleBar } from './title-bar';
import { ClickEventArgs, MenuItemModel } from '@syncfusion/ej2-angular-navigations';
interface SFDTInline {
    text: string;
    characterFormat: {
      [key: string]: any;
    };
  }
  
  interface SFDTParagraph {
    inlines: SFDTInline[];
  }
  
  interface SFDTBlock {
    paragraph?: SFDTParagraph;
  }
  import { sfdtService } from "./../../services/sfdtService"
import { element } from 'protractor';

@Component({
  selector: 'app-document-template',
  templateUrl: './document-template.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [EditorService, SelectionService, EditorHistoryService, SfdtExportService, ContextMenuService, TextExportService]
})
export class DocumentTemplateComponent {

  @ViewChild('document_editor')
  public documentEditor: DocumentEditorComponent;

  @ViewChild('documenteditor_default')
  public hiddenEditor: DocumentEditorContainerComponent;

  @ViewChild('form') ngForm: NgForm;

  @ViewChild('documenteditor_default', { static: true })
  container: DocumentEditorContainerComponent;

  @Input() selectedDocumentTemplate: any;
  @Input() filledVariables: any = {};
  @Input() caseDetail: any = {};

  @Output() backTrigger: EventEmitter<string> = new EventEmitter<any>();

  public groupedVariableList: any = [];
  public variableList: any = [];
  public textBlockList: any;
  public caseValueDetail = [];
  public bodyObservable: Observable<any>;
  public groupedVariables: any = {
    isExpandBody: {}
  };
  public fields: Object = { text: 'label', value: 'value' };
  public dialogRef: any;
  public dialogSubscription: Subscription;
  public selectedAttachment = [];
  public showHiddenContainer = false;
  public fontStyle: string[] = ['Sans Serif', 'Times New Roman', 'Arial'];
  public fontSize: string[] = ['10', '12', '14', '16'];
  public lineSpace: string[] = ['1', '1.5', '2'];
  public name: string;
  public documentId: string;
  public createNew = false;
  public caseValues: any;
  public conditionValues: any;
  public conditionList: any = [];
  public deletedConditions = [];
  public currCaret = '';
  public deletedLogics = [];
  public saveDisabled = true;
  public formChanged = false;
  public toolItem: CustomToolbarItemModel = {};
  public items = ['Undo', 'Redo', 'Image', 'Table', 'TableOfContents', 'Header', 'Footer', 'PageSetup', 'PageNumber', 'Find', 'Break', 'LocalClipboard', 'RestrictEditing'];
  public titleBar: TitleBar;
  public isEmptyNameOnSave = false;
  simpleBody: string;
  filteredTextBlockList!: Observable<any[]>;

  amountVariableKeys = [
                            '#Schaden_An-und-Abmeldekosten',
                            '#Schaden_Benzinschaden',
                            '#Schaden_Entsorgungskosten-für-Fahrbahnreinigung',
                            '#Schaden_Eigenanteil-an-Heilbehandlungskosten',
                            '#Schaden_Erwerbsschaden',
                            '#Schaden_Entgangener-Gewinn',
                            '#Schaden_Fahrkosten',
                            '#Schaden_Frustrierte-Aufwendungen',
                            '#Schaden_Haushaltsfürungskosten',
                            '#Schaden_Kosten-der-Anfrage-für-Vorhaltekosten',
                            '#Schaden_Rückstufungskosten',
                            '#Schaden_Reparaturkosten-brutto',
                            '#Schaden_Reparaturkosten-netto',
                            '#Schaden_Schmerzensgeld',
                            '#Schaden_Standgebühren',
                            '#Schaden_TransportkostenRTW',
                            '#Schaden_Totalschaden-Restwert',
                            '#Schaden_Totalschaden-Wiederbeschaffungswert',
                            '#Schaden_Neuwagenpreis',
                            '#Schaden_Mietwagenkosten',
                            '#Schaden_Summe-aller-Schäden'
                        ]

  @ViewChild('simpleInput') simpleInput: ElementRef<HTMLInputElement>;

  constructor(public appService: AppService, public sharedService: SharedService, public dialog: MatDialog, public router: Router, private route: ActivatedRoute, private SfdtService: sfdtService) { }

  @HostListener('window:beforeunload')
  canDeactivate() {
    if (this.formChanged) {
      if (
        confirm(
          'Möchten Sie die getätigten Änderungen verwerfen und ohne Speichern verlassen?'
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  

  ngOnInit() {
    this.route.paramMap.subscribe(({ params }: any) => {
      this.documentId = this.selectedDocumentTemplate ? this.selectedDocumentTemplate : params.id;;
      this.fetchInitialDetails();
      this.subscribeDialogEvent();
    });
    this.container.enableLocalPaste = false;

    this.route.queryParams.subscribe(params => {
      this.createNew = params['create_new'];
    });
  }

  fetchInitialDetails() {
    let fetchArr = [this.appService.listCaseVariable(), this.appService.textBlockList(), this.appService.caseValues(), this.appService.conditionCommonValues()];
    fetchArr = this.documentId ? [...fetchArr, this.appService.documentTemplateDetail(this.documentId), this.appService.conditionList(this.documentId)] : fetchArr;
    forkJoin(fetchArr).subscribe(
      (res: any) => {
        this.bodyObservable = this.ngForm.form.get('body')!.valueChanges
          .pipe(
            startWith(''),
            map(value => {
              return this._filterGroup(value, this.groupedVariableList);
            })
          )
        const variableList = res[0]?.data?.result;
        variableList.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        this.variableList = variableList;
        this.groupedVariableList = this.groupVariables(variableList);
        this.textBlockList = [...res[1]?.data?.result.rows?.map(({ name, body, id }) => ({ id, label: name, value: body, type: 'text-block' }))];
        // loading the initial textblocklist
        this.filteredTextBlockList = of(this.textBlockList).pipe(
        startWith(''),
        map(() => this.textBlockList)
        );
        this.caseValues = res[2]?.data?.result;
        this.conditionValues = res[3]?.data?.result;
        if (this.documentId) {
          this.name = this.createNew ? res[4]?.data?.result?.name + '_Kopie' : res[4]?.data?.result?.name;
          let updatedDoc = res[4]?.data?.result?.body;
          this.selectedAttachment = res[4]?.data?.result?.attachments?.length ? res[4]?.data?.result?.attachments?.split(',') : [];
          res[5]?.data?.result?.forEach(ele => {
            let logic = [];
            ele.logic.forEach(logicEle => {
              let temp = {
                specificVariableOperatorId: logicEle.specificVariableOperatorId,
                specificVariableOperator: logicEle.specificVariableOperator,
                value: logicEle.value,
                ...(!this.createNew && { id: logicEle.id }),
              }
              logic.push(temp);
            });
            let temp = {
              name: ele.name,
              textblockTemplateId: ele.textblockTemplateId,
              textblockTemplate: ele.textblockTemplate,
              documentTemplateId: ele.documentTemplateId,
              valueTypeOfConditionId: ele.valueTypeOfConditionId,
              valueTypeOfCondition: ele.valueTypeOfCondition,
              ...(!this.createNew && { id: ele.id }),
              logic: logic
            }
            this.conditionList.push(temp);
          })
          const condnList = this.conditionList.map(({ name, ...rest }) => ({ ...rest, name, order: parseInt(name.split('BEDINGUNG')[1]) }));
          this.conditionList = condnList.sort((a, b) => { return a.order - b.order });
          if (this.selectedDocumentTemplate) {
            updatedDoc = this.validateConditions(updatedDoc);
            updatedDoc = this.refreshValues(updatedDoc);
          }
          this.container.documentEditor.open(updatedDoc);
          if(this.selectedDocumentTemplate) {
            this.validateExport();
          }
        }
      },
      err => {
        console.log(err);
      }
    )
  }

  refreshValues(sfdt) {
    let documentString = sfdt;
    Object.entries(this.filledVariables).forEach(([field, value]: [string, string]) => {
      if (this.amountVariableKeys.includes(field)) {
          value = this.convertToGermanNotation(value)
      }
      documentString = documentString?.replaceAll(`${field}`, value)
    });
    return documentString;
  }

  convertToGermanNotation(value) {
    let parts = parseFloat(value).toFixed(2).toString().split(".");
    let formattedNumber = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") + (parts[1] ? "," + parts[1] : "");
    value = formattedNumber
    return `${value}€`;
  }

  getheight() {
    return window.innerHeight - 400;
  }

  public toolbarClickHandler(arg: any) {
    switch (arg.item.id) {
      case 'bold':
        this.documentEditor.editor.toggleBold();
        break;
      case 'italic':
        this.documentEditor.editor.toggleItalic();
        break;
      case 'underline':
        this.documentEditor.editor.toggleUnderline('Single');
        break;
      case 'header':
        this.documentEditor.selection.goToHeader();
        break;
      case 'footer':
        this.documentEditor.selection.goToFooter();
        break;
    }
  }

  public onFontFamilyChange(args) {
    this.documentEditor.selection.characterFormat.fontFamily = args.value;
    this.documentEditor.focusIn();
  }
  public onFontSizeChange(args) {
    this.documentEditor.selection.characterFormat.fontSize = args.value;
    this.documentEditor.focusIn();
  }
  public onLineSpaceChange(args) {
    this.documentEditor.selection.paragraphFormat.lineSpacing = args.value;
  }

  groupVariables(variableList) {
    let updatedList = [];
    variableList?.forEach(({ name, description }) => {
      if (updatedList.findIndex(({ group, list }) => group === name.split('_')[0] && list && list.findIndex(({ group: childGroup }) => childGroup && childGroup.split('_')[1] === name.split('_')[1]) !== -1) !== -1) {
        const parentIndex = updatedList.findIndex(({ group, list }) => group === name.split('_')[0] && list && list.findIndex(({ group: childGroup }) => childGroup && childGroup.split('_')[1] === name.split('_')[1]) !== -1);
        const childIndex = updatedList[parentIndex].list.findIndex(({ group: childGroup }) => childGroup && childGroup.split('_')[1] === name.split('_')[1]);
        updatedList[parentIndex].list[childIndex].list = [...updatedList[parentIndex].list[childIndex].list, { label: name, value: name, description, type: 'variable' }];
      } else if (updatedList.findIndex(({ group, list }) => group === name.split('_')[0] && list && list.findIndex(({ label }) => label && label.split('_')[1] === name.split('_')[1]) !== -1) !== -1) {
        const parentIndex = updatedList.findIndex(({ group, list }) => group === name.split('_')[0] && list && list.findIndex(({ label }) => label && label.split('_')[1] === name.split('_')[1]) !== -1);
        const childIndex = updatedList[parentIndex].list.findIndex(({ label }) => label && label.split('_')[1] === name.split('_')[1]);
        const currVariable = updatedList[parentIndex].list[childIndex];
        updatedList[parentIndex].list.splice(childIndex, 1, { group: `${name.split('_')[0]}_${name.split('_')[1]}`, list: [currVariable, { label: name, value: name, description, type: 'variable' }] });
      } else if (updatedList.findIndex(({ group }) => group === name.split('_')[0]) !== -1) {
        const index = updatedList.findIndex(({ group }) => group === name.split('_')[0]);
        updatedList[index].list = [...updatedList[index].list, { label: name, value: name, description, type: 'variable' }];
      } else if (updatedList.findIndex(({ label }) => label && label.split('_')[0] === name.split('_')[0]) !== -1) {
        const index = updatedList.findIndex(({ label }) => label && label.split('_')[0] === name.split('_')[0]);
        const currVariable = updatedList[index];
        updatedList.splice(index, 1, { group: name.split('_')[0], list: [currVariable, { label: name, value: name, description, type: 'variable' }] });
        if (currVariable.value.split('_')[1] === name.split('_')[1]) {
          updatedList[index].list = [{ group: `${name.split('_')[0]}_${name.split('_')[1]}`, list: updatedList[index].list }];
        }
      } else {
        updatedList = [...updatedList, { label: name, value: name, description, type: 'variable' }];
      }
    });
    return updatedList;
  }

  _filterGroup(value: string, variableList: Array<any>) {
    let updatedVL = variableList;
    if (value) {
      updatedVL = variableList
        .map(group => ({ ...group.group && { group: group.group, list: this._filter(group.list, value) }, ...!group.group && { label: group.label, value: group.value, description: group.description } }))
        .filter(group => group?.list?.length > 0 || group?.value?.toLowerCase().includes(value.toLowerCase()));
    }

    const groupedArr = updatedVL.filter(({ group }) => group);
    const variableArr = updatedVL.filter(({ group }) => !group);
    return [...groupedArr, ...variableArr];
  }

  _filter(opt: (string | any)[], value: string): string[] {
    const filterValue = value.toLowerCase();
    let temp = opt.filter(item => !item.value || item.value.toLowerCase().includes(filterValue));
    temp = temp.map(item => item.value ? item : { group: item.group, list: this._filter(item.list, value) });
    temp = temp.filter(item => item.value || item.list.length > 0);
    return temp;
  };

  variableFormClicked() {
    this.sharedService.variableDropdownEvent.next(true);
    const overlayContainerEleement: any = document.getElementsByClassName("cdk-overlay-container");
    overlayContainerEleement[0].style['z-index'] = 10000;
  }

  editorVariableSelected(e) {
    this.container.documentEditor.editor.insertText(e.option.value);
    this.ngForm.form.patchValue({ body: '' });
    if(this.selectedDocumentTemplate) {
      this.container.documentEditor.selection.selectAll();
      let documentContent = this.container.documentEditor.selection.sfdt;
      this.container.documentEditor.selection.select('0;0;0', '0;0;0');
      documentContent = this.refreshValues(documentContent);
      this.container.documentEditor.open(documentContent);
    }
  }

  editorTextblockSelectedSfdt(e) {
    this.container.documentEditor.editor.paste(e.option.value);
    this.ngForm.form.patchValue({ simpleBody: '' });
    if(this.selectedDocumentTemplate) {
      this.container.documentEditor.selection.selectAll();
      let documentContent = this.container.documentEditor.selection.sfdt;
      this.container.documentEditor.selection.select('0;0;0', '0;0;0');
      documentContent = this.refreshValues(documentContent);
      this.container.documentEditor.open(documentContent);
    }
  }

  toggleSelection(event: any, group: any, field: any) {
    this.groupedVariables[field][group.group] = !this.groupedVariables[field][group.group] ? true : false;
    event.stopPropagation();
  }

  triggerEvent(e) {
    if (!e) {
      const overlayContainerEleement: any = document.getElementsByClassName("cdk-overlay-container");
      overlayContainerEleement[0].style['z-index'] = 1000;
      this.sharedService.variableDropdownEvent.next(false);
    }
  }

  getFilteredTextBlocks(): Observable<any[]> {

    return of(this.simpleBody).pipe(
      startWith(''),
      map(value => this._filterTextBlockList(value || ''))
    );
  }
  
  private _filterTextBlockList(value: string): any[] {
    const filterValue = value.toLowerCase();
    return filterValue ? 
    this.textBlockList.filter(option => option.label.toLowerCase().includes(filterValue)) : 
    this.textBlockList;
  }

  textBlockSelected(e) {
    if (e.value.length) {
      var wrapper = document.createElement('div');
      wrapper.innerHTML = e.value;
      var div = wrapper;
      this.documentEditor.editor.insertText(div.textContent);
    }
  }

  openUploadAttachment() {
    this.dialogRef = this.dialog.open(DialogComponent, {
      width: '500px',
      disableClose: false,
      height: '500px',
      data: {
        dialogType: 'UPLOAD_ATTACHMENT',
        title: 'Dateianhänge auswählen',
        variableList: this.caseValues?.fileButtonMapping.map(ele => ({ ...ele, checked: this.selectedAttachment.includes(ele.id) })),
        selectedAttachment: this.selectedAttachment
      }
    });
  }

  subscribeDialogEvent() {
    this.dialogSubscription = this.sharedService
      .getDialogSubmitEvent()
      .subscribe((res) => {
        switch (res?.type) {
          case 'DOCUMENT_ATTACHMENT_UPDATED': { this.selectedAttachment = res.value; this.saveDisabled = false; }
            break;
          case 'CONFIRMATION_ALLOWED': this.deleteTemplate();
            break;
          case 'CONDITION_UPSERTED': this.updateCondnList(res.value);
            break;
          case 'CONDITION_DELETED': this.deleteCondn(res.value);
            break;
          default:
            this.dialogRef.close();
        }
      });
  }

  validateExport() {
    this.container.documentEditor.selection.selectAll();
    const documentContent = this.container.documentEditor.selection.sfdt;
    this.container.documentEditor.selection.select('0;0;0', '0;0;0');
    let variableArr = [];
    for (const variable of this.variableList) {
      if (documentContent.includes(variable?.name)) {
        variableArr.push(variable?.name);
      }
    }
    if (variableArr?.length) {
      this.openDialog({
        dialogType: 'INFORMATION',
        title: 'Fehlende Informationen',
        message: 'Für folgende Variablen sind keine Informationen im Fall hinterlegt:',
        allowTooltip: 'Confirm',
        denyTooltip: 'Deny',
        data: { variableArr },
        infoTooltip: 'Okay',
      });
      return false;
    }

    return true;
  }

  onClick() {
    if(this.validateExport()) {
      this.appService.compressedDocument(this.caseDetail.id, this.documentId).pipe(finalize(() => {
        this.onContainerCreate();
      })).subscribe(blobData => {
        saveAs(blobData.body, blobData.headers.get('Content-Disposition').split('="')[1].split('"')[0]);
      });
    }
  }

  updateCondnList({ condition, updatedIndex, deletedLogics }) {
    if (~updatedIndex) {
      this.conditionList.splice(updatedIndex, 1, condition);
      this.deletedLogics = [...this.deletedLogics, ...deletedLogics];
    } else {
      this.conditionList.unshift(condition);
      this.container.documentEditor.editor.insertText(`{{${condition.name}}} `);
    }
    const condnList = this.conditionList.map(({ name, ...rest }) => ({ ...rest, name, order: parseInt(name.split('BEDINGUNG')[1]) }));
    this.conditionList = condnList.sort((a, b) => { return a.order - b.order });
    this.saveDisabled = false;
    this.dialogRef.close();
  }

  deleteCondn({ index }) {
    const condn = this.conditionList[index];
    this.conditionList.splice(index, 1);
    this.dialogRef.componentInstance.data.conditionList = this.conditionList;
    this.container.documentEditor.selection.selectAll();

    let updatedDoc = (this.container.documentEditor.selection.sfdt as any).replaceAll(`{{${condn.name}}}`, '');
    this.container.documentEditor.open(updatedDoc);
    if (this.currCaret) {
      this.container.documentEditor.selection.select(this.currCaret, this.currCaret);
    }

    if (condn.id) {
      this.deletedConditions.push(condn.id);
    }
    this.saveDisabled = false;
  }

  onContainerCreate() {
    let pdfdocument: PdfDocument = new PdfDocument();
    let count: number = this.container.documentEditor.pageCount;
    this.container.documentEditor.documentEditorSettings.printDevicePixelRatio = 2;
    let loadedPage = 0;
    for (let i = 1; i <= count; i++) {
      setTimeout(() => {
        let format: ImageFormat = 'image/jpeg' as ImageFormat;
        // Getting pages as image
        let image = this.container.documentEditor.exportAsImage(i, format);
        const self = this;
        image.onload = function () {
          let imageHeight = parseInt(
            image.style.height.toString().replace('px', '')
          );
          let imageWidth = parseInt(
            image.style.width.toString().replace('px', '')
          );
          let section: PdfSection = pdfdocument.sections.add() as PdfSection;
          let settings: PdfPageSettings = new PdfPageSettings(0);
          if (imageWidth > imageHeight) {
            settings.orientation = PdfPageOrientation.Landscape;
          }
          settings.size = new SizeF(imageWidth, imageHeight);
          (section as PdfSection).setPageSettings(settings);
          let page = section.pages.add();
          let graphics = page.graphics;
          let imageStr = image.src.replace('data:image/jpeg;base64,', '');
          let pdfImage = new PdfBitmap(imageStr);
          graphics.drawImage(pdfImage, 0, 0, imageWidth, imageHeight);
          loadedPage++;
          let title = self.name.trim().length ? `${self.name}.pdf` : 'Sample.pdf';
          if (loadedPage == count) {
            // Exporting the document as pdf
            pdfdocument.save(title);

          }
        };
      }, 500);
    }


  }

  back() {
    if (this.selectedDocumentTemplate) {
      this.backTrigger.emit();
    } else {
      this.router.navigate(['/dashboard/documentList']);
    }
  }

  openConditionModal() {
    if (this.container.documentEditor.selection?.startOffset) {
      this.currCaret = this.container.documentEditor.selection.startOffset;
    }
    this.openDialog({
      dialogType: 'UPSERT_CONDITION',
      title: 'Bedingungen',
      conditionList: this.conditionList,
      conditionValues: this.conditionValues,
      textBlockList: this.textBlockList.filter(ele => ele.id),
      selectedDocumentTemplate: this.selectedDocumentTemplate,

    });

    let element = document
      .getElementsByClassName('cdk-overlay-dark-backdrop')[1] as HTMLElement;
      if (element && this.selectedDocumentTemplate) {
        setTimeout(() => {
          element.style.opacity = "0.4";
        });
      }
  }

  openDialog(data) {
    this.dialogRef = this.dialog.open(DialogComponent, {
      width: data.dialogType === 'UPSERT_CONDITION' ? '500px' : 'auto',
      disableClose: false,
      height: data.dialogType === 'UPSERT_CONDITION' ? `${window.innerHeight - 100}px` : 'auto',
      data
    });
  }

  saveDocument() {
    if (!this.saveDisabled) {
      if (this.name && this.name.trim().length) {
        if (this.documentId && !this.createNew) {
          this.editDocTemplate(this.container.documentEditor.serialize());
        } else {
          this.createDocTemplate(this.container.documentEditor.serialize());
        }
        this.container.documentEditor.selection.select('0;0;0', '0;0;0');
      }
      else {
        this.sharedService.open('Bitte geben sie einen Namen für die Vorlage ein', 'warning');
        this.isEmptyNameOnSave = true;
      }
    }
  }

  createDocTemplate(sfdt) {
    this.appService.createDocTemplate({ name: this.name, docString: sfdt, attachments: this.selectedAttachment.toString(), conditions: this.conditionList }).subscribe(
      res => {
        this.formChanged = false;
        this.sharedService.open('Änderungen erfolgreich gespeichert.', 'success');
        this.router.navigate(['/dashboard/documentList']);
      },
      err => {
        console.log(err);
        this.sharedService.open(err.error.message, 'failure');
      }
    )
  }

  editDocTemplate(sfdt) {
    this.appService.editDocTemplate(this.documentId, { name: this.name, docString: sfdt, attachments: this.selectedAttachment.toString(), conditions: this.conditionList, deletedConditions: this.deletedConditions, deletedLogics: this.deletedLogics }).subscribe(
      res => {
        this.formChanged = false;
        this.sharedService.open('Änderungen erfolgreich gespeichert.', 'success');
        this.router.navigate(['/dashboard/documentList']);
      },
      err => {
        console.log(err);
      }
    )
  }

  openDeleteModal() {
    this.openDialog({
      dialogType: 'CONFIRMATION',
      title: `Löschen`,
      message: `Möchten Sie die Vorlage wirklich löschen?`,
      allowTooltip: 'Confirm',
      denyTooltip: 'Deny',
    });
  }

  deleteTemplate() {
    this.appService.deleteDocumentTemplate(this.documentId).subscribe(
      (res) => {
        this.dialogRef.close();
        this.sharedService.open(`Vorlage wurde erfolgreich gelöscht`, 'success');
        this.router.navigate(['/dashboard/documentList']);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  validateConditions(updatedDoc) {
    this.conditionList.forEach(condn => {
      let isValidCondition = false;

      if (condn?.valueTypeOfCondition?.value === 'all') {
        isValidCondition = condn.logic.every(logic => this.validateLogic(logic));
      } else {
        isValidCondition = condn.logic.some(logic => this.validateLogic(logic));
      }
      if(isValidCondition) {
        updatedDoc = this.replaceCondition(updatedDoc, condn.textblockTemplate.body, `{{${condn.name}}}`);
      } else {
        updatedDoc = updatedDoc.replaceAll(`{{${condn.name}}}`, '');
      }
    });

    return updatedDoc;
  }

  validateLogic(logic) {
    const variableValue = this.filledVariables[logic.specificVariableOperator.caseVariable.name];
    let isValid = false;

    switch (logic.specificVariableOperator.operator.value) {
      case '==': isValid = variableValue === logic.value;
        break;
      case '<=': isValid = parseFloat(variableValue) <= parseFloat(logic.value);
        break;
      case '<': isValid = parseFloat(variableValue) < parseFloat(logic.value);
        break;
      case '>=': isValid = parseFloat(variableValue) >= parseFloat(logic.value);
        break;
      case '>': isValid = parseFloat(variableValue) > parseFloat(logic.value);
        break;
      case '!=': isValid = variableValue != logic.value;
        break;
      case 'is empty': isValid = !variableValue?.trim();
        break;
      case 'is not empty': isValid = !!variableValue?.trim();
        break;
      default: isValid = false;
    }

    return isValid;
  }

  nameChange() {
    this.saveDisabled = false;
  }

  onEditorContentChange() {
    this.formChanged = true;
    this.saveDisabled = false;
  }

  public onToolbarClick(args: ClickEventArgs): void {
    switch (args.item.id) {
      case 'Custom':
        //Disable image toolbar item.
        this.container.toolbar.enableItems(4, false);
        break;
    }
  };

  onCreate(): void {
    this.container.documentEditorSettings.showRuler = true;
    let titleBarElement: HTMLElement = document.getElementById('default_title_bar');
    this.titleBar = new TitleBar(titleBarElement, this.container.documentEditor, () => this.openConditionModal(), true);
    this.titleBar.updateDocumentTitle();
  }

  replaceCondition(sfdtString, textBlockString, conditionName) {
    const sfdtExtracted = this.SfdtService.extractBContent(sfdtString);
    const textBlock = this.SfdtService.extractBContent(textBlockString);

    let replaceString = '';
    for (let i = 0; i < textBlock.length; i++) {
      replaceString += JSON.stringify(textBlock[i]);
      if (i < textBlock.length - 1) {
        replaceString += ',';
      }
    }

    let index = -1;
    sfdtExtracted.forEach((element, i) => {
      if(element.i?.length) {
        element?.i.forEach(ielement => {
          if(conditionName === ielement?.tlp?.trim())
          index = i;
        });
      }
    });

    if(index !== -1) {
      return sfdtString.replace(JSON.stringify(sfdtExtracted[index]), replaceString)
    }

    return sfdtString;

  }

  ngOnDestroy() {
    if (this.dialogSubscription) this.dialogSubscription.unsubscribe();
  }

}

