import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  public mobileQuery: MediaQueryList;
  public progress;
  public userDetail: any;

  public navListsOptions = [
    {
      name: 'Fälle',
      routeLink: '/dashboard',
      defaultDisplay: true
    },
    {
      name: 'Mandanten',
      routeLink: '/dashboard/clientList',
      defaultDisplay: true
    },
    {
      name: 'Werkstätten',
      routeLink: '/dashboard/list/werkstatten',
      defaultDisplay: false,
      validateField: 'canViewCRS'
    },
    {
      name: 'Sachverständige',
      routeLink: '/dashboard/list/sachverstandige',
      defaultDisplay: false,
      validateField: 'canViewExpert'
    },
    {
      name: 'Versicherungen',
      routeLink: '/dashboard/list/versicherungen',
      defaultDisplay: true
    },
    // {
    //   name: 'Buchhaltung',
    //   routeLink: '/insurer/list'
    // }
  ];
  public navLists = [];

  constructor(public media: MediaMatcher, public sharedService: SharedService, private cd: ChangeDetectorRef) {
    this.mobileQuery = media.matchMedia('(max-width: 1024px)');
    this.sharedService.getProgressValue().subscribe(data => {
      this.progress = data;
      this.cd.detectChanges();
    });
    this.userDetail = sharedService.userDetail();
    this.navLists = this.navListsOptions.filter((option: any) => this.checkSidebarOption(option));
  }

  ngOnInit(): void {
  }

  public checkSidebarOption({ defaultDisplay, validateField }) {
    return defaultDisplay ? true : this.userDetail?.permissions.includes(validateField);
  }

}
