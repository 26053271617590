import { ApplayoutComponent } from '../shared/applayout/applayout.component';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { Injectable, ViewChild } from "@angular/core";
import { Router } from '@angular/router';
import { AppService } from './app.service';
import { SharedService } from './shared.service';
const MINUTES_UNITL_AUTO_LOGOUT = 10000 // in mins
const CHECK_INTERVAL = 5000 // in ms
const STORE_KEY = 'lastAction';
@Injectable()
export class AutoLogoutService {
  public getLastAction() {
    return parseInt(localStorage.getItem(STORE_KEY));
  }
  public setLastAction(lastAction: number) {
    localStorage.setItem(STORE_KEY, lastAction.toString());
  }

  constructor(private router: Router, private snackbarService: SnackbarService,
    public appService: AppService, public sharedService: SharedService) {
    this.check();
    this.initListener();
    this.initInterval();
    localStorage.setItem(STORE_KEY, Date.now().toString());
  }

  initListener() {
    document.body.addEventListener('click', () => this.reset());
    document.body.addEventListener('mouseover', () => this.reset());
    document.body.addEventListener('mouseout', () => this.reset());
    document.body.addEventListener('keydown', () => this.reset());
    document.body.addEventListener('keyup', () => this.reset());
    document.body.addEventListener('keypress', () => this.reset());
  }

  reset() {
    this.setLastAction(Date.now());
  }

  initInterval() {
    setInterval(() => {
      this.check();
    }, CHECK_INTERVAL);
  }

  check() {
    const now = Date.now();
    const timeleft = this.getLastAction() + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;
    const isTimeoutNotify = diff == 4;

    if (isTimeoutNotify) {
      console.log("You will timeout" + isTimeoutNotify)
    }

    // if (isTimeout && this.userService.getToken()) {
    //   console.log(`Sie wurden automatisch nach ${MINUTES_UNITL_AUTO_LOGOUT} Minuten Inaktivität ausgeloggt.`);
    //   this.appService.logout()
    //     .subscribe(
    //       data => {
    //         console.log(data);
    //         window.location.reload();
    //         this.sharedService.logoutUser();
    //         this.snackbarService.open('Logout due to inactivity', 'success');
    //         this.router.navigate(['login']);
    //       }, // this.sendLogoutStatus(`$event`);
    //       error => console.error(error)
    //     );
    // }
  }

}