import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class sfdtService {

    constructor() { }

    extractBContent(jsonString: string): any[] {
        try {
            const jsonObject = JSON.parse(jsonString);
            return this.getBContent(jsonObject);
        } catch (error) {
            console.error('Invalid JSON string provided', error);
            return [];
        }
    }

    private getBContent(obj: any): any[] {
        if (obj.hasOwnProperty('b')) {
            return obj['b'];
        }

        if (typeof obj === 'object' && obj !== null) {
            for (let key of Object.keys(obj)) {
                const result = this.getBContent(obj[key]);
                if (result.length > 0) {
                    return result;
                }
            }
        }

        return [];
    }
}