import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientCreateComponent } from './client-create/client-create.component';
import { ClientListComponent } from './client-list/client-list.component';
import { InstitutionCreateComponent } from './institution-create/institution-create.component';
import { InstitutionListComponent } from './institution-list/institution-list.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { CheckDeactivationGuard } from '../guards/checkDeactivation.guard';
import { CaseCreateComponent } from './case-create/case-create.component';
import { CaseListComponent } from './case-list/case-list.component';
import { InstituteGuard } from '../guards/institute.guard';
import { TemplateComponent } from './template/template.component';
import { TemplateListComponent } from './template-list/template-list.component';
import { TextBlockComponent } from './text-block/text-block.component';
import { TextBlockListComponent } from './text-block-list/text-block-list.component';
import { DocumentTemplateComponent } from './document-template/document-template.component';
import { DocumentTemplateListComponent } from './document-template-list/document-template-list.component';

const routes: Routes = [
  // { path: '', component: SidebarComponent, children: [
    { path: '', component: CaseListComponent },
    { path: 'list/:type', component: InstitutionListComponent, canActivate: [InstituteGuard] },
    { path: 'create/:type', component: InstitutionCreateComponent, canActivate: [InstituteGuard], canDeactivate: [CheckDeactivationGuard] },
    { path: 'edit/:id', component: InstitutionCreateComponent, canDeactivate: [CheckDeactivationGuard] },
    { path: 'createClient', component: ClientCreateComponent, canDeactivate: [CheckDeactivationGuard] },
    { path: 'editClient/:id', component: ClientCreateComponent, canDeactivate: [CheckDeactivationGuard] },
    { path: 'clientList', component: ClientListComponent },
    { path: 'createCase/:clientId', component: CaseCreateComponent, canDeactivate: [CheckDeactivationGuard] },
    { path: 'editCase/:caseId', component: CaseCreateComponent, canDeactivate: [CheckDeactivationGuard] },
    // { path: 'createTemplate', component: TemplateComponent, canDeactivate: [CheckDeactivationGuard] },
    // { path: 'editTemplate/:id', component: TemplateComponent, canDeactivate: [CheckDeactivationGuard] },
    // { path: 'templateList', component: TemplateListComponent },
    { path: 'textblockList', component: TextBlockListComponent },
    { path: 'createTextblock', component: TextBlockComponent },
    { path: 'editTextblock/:id', component: TextBlockComponent },
    { path: 'createDocument', component: DocumentTemplateComponent, canDeactivate: [CheckDeactivationGuard] },
    { path: 'editDocument/:id', component: DocumentTemplateComponent, canDeactivate: [CheckDeactivationGuard]  },
    { path: 'documentList', component: DocumentTemplateListComponent },
  // ]},
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }