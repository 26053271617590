import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../../dialog/dialog.component';
import { SharedService } from 'src/app/services/shared.service';
import { Subscription, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { AppService } from 'src/app/services/app.service';

interface DocumentNode {
  name: string;
  children?: DocumentNode[];
  type: 'folder' | 'file';
  parentFileAndFolderId: number | null;
  id: number;
  fileType?: string;
  createdAt: string;
}

/** Flat node with expandable and level information */
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
})
export class DocumentComponent implements OnInit {
  @Input() private tabChanged: EventEmitter<string>;
  @Input() caseValueDetail: any;
  @Input() public documentList: any;
  @Input() caseDetail: any;
  @Input() documentListSub: any;

  @Output() downloadAttachment: EventEmitter<object> =
    new EventEmitter<object>();
  @Output() updateDocList: EventEmitter<object> = new EventEmitter<object>();

  @ViewChild('leftSide') leftSide: ElementRef;
  @ViewChild('rightSide') rightSide: ElementRef;
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('tree') tree;

  public documentSource = [];
  public sortedData: any = [];
  public sortBy = '';
  public sortDirection = '';
  public displayDocumentColumns = [
    'name',
    // 'createdDate',
    'fileType',
    'uploadButton',
    'options',
  ];
  public showRightBorder = true;
  public dialogRef: any;
  public currParentId: any;
  public currMaxId = 15;
  public sourceArr = [];
  public dialogSubscription: Subscription;
  public selectedDoc: any;

  constructor(
    public cd: ChangeDetectorRef,
    public dialog: MatDialog,
    public sharedService: SharedService,
    public appService: AppService
  ) {}

  ngOnInit(): void {
    this.tabChanged.subscribe((data) => {
      this.updateBorder();
    });
    this.updateSource(this.documentList);
   
    this.documentListSub.subscribe(documentList => this.updateSource(documentList));
  }

  updateSource(documentList) {
    this.sourceArr = documentList.map(
      ({ childFileAndFolder, ...rest }) => ({
        ...rest,
        childFileAndFolder: {
          ...childFileAndFolder,
          ...(childFileAndFolder.fileButtonMapping
            ? {
                fileButtonMapping: {
                  ...childFileAndFolder.fileButtonMapping,
                  displayBtnName: this.updatedName(
                    childFileAndFolder.fileButtonMapping.name
                  ),
                },
              }
            : childFileAndFolder.fileButtonMapping),
        },
      })
    );
    this.updateTree();
    if(!this.dialogSubscription) {
      this.subscribeDialogEvent();
    }
    this.cd.detectChanges();
    this.tree.treeControl.expandAll();
  }

  updatedName(name) {
    return name
      .split('_')
      .map((ele) => `${ele.charAt(0).toUpperCase()}${ele.substring(1)}`)
      .join(' ');
  }

  updateTree() {
    const tree = this.convertToTree(null);
    this.dataSource.data = tree;
    if (!this.currParentId) {
      this.clickedTree(tree[0]);
    }
  }

  ngAfterViewInit() {
    this.updateBorder();
    this.listenSearch();
  }

  updateBorder() {
    this.showRightBorder =
      this.leftSide?.nativeElement?.offsetHeight >=
      this.rightSide?.nativeElement?.offsetHeight;
    this.cd.detectChanges();
  }

  private _transformer = (node, level: number) => {
    return {
      ...node,
      expandable: !!node.children && node.children.length > 0,
      level: level,
      children: node.children || [],
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  clickedTree(node) {
    if (node?.childFileAndFolder?.valueTypeOfDoc?.name === 'folder') {
      this.documentSource = node.allChildren || [];
      this.currParentId = node?.childFileAndFolderId;
      this.sortData({ active: this.sortBy, direction: this.sortDirection });
      this.cd.detectChanges();
      this.updateBorder();
    }
  }

  convertToTree(parentId) {
    let nodeList = this.sourceArr.filter(
      ({ parentFileAndFolderId: currParentId }) => parentId === currParentId
    );
    nodeList = nodeList.map((node) => ({
      ...node,
      ...(this.convertToTree(node.childFileAndFolder?.id)?.length
        ? {
          allChildren: this.convertToTree(node.childFileAndFolder?.id),
          children: this.convertToTree(node.childFileAndFolder?.id).filter(
              ({ childFileAndFolder }) =>
                childFileAndFolder?.valueTypeOfDoc?.name === 'folder'
            ),
          }
        : {}),
    }));
    return nodeList;
  }

  createDoc() {
    this.selectedDoc = {};
    this.openDialog({ dialogType: 'DOCUMENT_DETAIL' });
  }

  openDialog(data) {
    this.dialogRef = this.dialog.open(DialogComponent, {
      width: '300px',
      disableClose: false,
      data,
    });
  }

  subscribeDialogEvent() {
    this.dialogSubscription = this.sharedService
      .getDialogSubmitEvent()
      .subscribe((res) => {
        switch (res?.type) {
          case 'DOCUMENT_DETAIL_SUBMITTED':
            this.documentDetailUpdated(res);
            break;
          case 'CONFIRMATION_ALLOWED':
            this.confirmationSelected();
            break;
          default:
            if(this.dialogRef)
            this.dialogRef.close();
        }
      });
  }

  documentDetailUpdated(response) {
    const callback = () => {
      this.searchInput.nativeElement.value = '';
      this.updateTree();
      const node = this.getNode(this.dataSource.data, this.currParentId);
      if (node) this.clickedTree(node);
      this.updateDocList.emit(this.sourceArr);
      this.dialogRef.close();
      this.tree.treeControl.expandAll();
    };
    if (this.selectedDoc && Object.keys(this.selectedDoc)?.length) {
      this.updatedDoc(response, callback);
    } else {
      this.createFolder(response, callback);
    }
  }

  updatedDoc(response, callback) {
    this.appService
      .updateDoc(
        { name: response?.data?.name, buttonId: response?.data?.buttonId },
        this.selectedDoc?.childFileAndFolderId
      )
      .subscribe(
        (data: any) => {
          const index = this.sourceArr.findIndex(
            ({ childFileAndFolderId }) =>
              childFileAndFolderId === this.selectedDoc.childFileAndFolderId
          );
          if (data?.data?.result?.fileButtonMappingId) {
            const currBtnFileIndex = this.sourceArr.findIndex(
              ({ childFileAndFolder }) =>
                childFileAndFolder?.fileButtonMappingId ===
                data?.data?.result?.fileButtonMappingId
            );
            if (currBtnFileIndex !== -1) {
              this.sourceArr[
                currBtnFileIndex
              ].childFileAndFolder.fileButtonMappingId = null;
              delete this.sourceArr[currBtnFileIndex].childFileAndFolder
                .fileButtonMapping;
            }
          }
          this.sourceArr[index].childFileAndFolder = {
            ...data?.data?.result,
            fileButtonMapping: data?.data?.result.fileButtonMapping
              ? {
                  ...data?.data?.result.fileButtonMapping,
                  displayBtnName: this.updatedName(
                    data?.data?.result.fileButtonMapping.name
                  ),
                }
              : data?.data?.result.fileButtonMapping,
          };
          callback();
          this.sharedService.open('Änderungen wurde erfolgreich gespeichert', 'success');
        },
        (err) => {
          console.log(err);
          this.sharedService.open(
            err.error.message || 'Something went wrong!',
            'failure'
          );
        }
      );
  }

  createFolder(response, callback) {
    this.appService
      .createFolder({
        caseId: this.caseDetail?.id,
        name: response?.data?.name,
        parentId: this.currParentId,
      })
      .subscribe(
        (data: any) => {
          this.sourceArr.push(data?.data?.result);
          callback();
          this.sharedService.open('Ordner erforlgreich erstellt', 'success');
        },
        (err) => {
          console.log(err);
          this.sharedService.open(
            err.error.message || 'Something went wrong!',
            'failure'
          );
        }
      );
  }

  confirmationSelected() {
    this.appService.deleteDoc(this.selectedDoc.childFileAndFolderId).subscribe(
      () => {
        const index = this.sourceArr.findIndex(
          ({ childFileAndFolderId }) =>
            childFileAndFolderId === this.selectedDoc.childFileAndFolderId
        );
        if (index !== -1) {
          this.sourceArr.splice(index, 1);
          this.updateTree();
          const node = this.getNode(this.dataSource.data, this.currParentId);
          if (node) this.clickedTree(node);
          this.updateDocList.emit(this.sourceArr);
        }
        if (this.selectedDoc?.childFileAndFolder?.valueTypeOfDoc?.name == 'folder') {
            this.sharedService.open('Der Ordner wurde erfolgreich gelöscht.', 'success');
        }
        else {
            this.sharedService.open('Das Dokument wurde erfolgreich gelöscht.', 'success');
        }
        this.dialogRef.close();
        this.tree.treeControl.expandAll();
        this.cd.detectChanges();
        this.updateBorder();
      },
      (err) => {
        console.log(err);
        this.sharedService.open(
          err?.error?.message || 'Something went wrong!',
          'failure'
        );
      }
    );
  }

  sortData(sort) {
    this.sortBy = sort.active;
    this.sortDirection = sort.direction;
    const docArr = JSON.parse(JSON.stringify(this.documentSource));

    if (!sort.active || sort.direction === '') {
      this.sortedData = docArr;
      return;
    }
    this.sortedData = docArr.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return this.compare(
            a.childFileAndFolder.name,
            b.childFileAndFolder.name,
            isAsc
          );
        case 'createdDate':
          return this.compare(
            a.childFileAndFolder.createdAt,
            b.childFileAndFolder.createdAt,
            isAsc,
            true
          );
        case 'fileType':
          return this.compare(
            a?.childFileAndFolder?.valueTypeOfDoc?.name,
            b?.childFileAndFolder?.valueTypeOfDoc?.name,
            isAsc
          );
        case 'uploadButton':
          return this.compare(
            a?.childFileAndFolder?.fileButtonMapping?.displayBtnName,
            b?.childFileAndFolder?.fileButtonMapping?.displayBtnName,
            isAsc
          );
        default:
          return 0;
      }
    });
  }

  compare(a: any, b: any, isAsc: boolean, isDate?: boolean) {
    a = isDate ? new Date(a) : a;
    b = isDate ? new Date(b) : b;

    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  listenSearch() {
    fromEvent(this.searchInput.nativeElement, 'keydown')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.sortBy = '';
          this.sortDirection = '';
          const search =
            this.searchInput && this.searchInput.nativeElement
              ? this.searchInput.nativeElement.value.trim()
              : '';
          if (search.length) {
            const docArr = JSON.parse(JSON.stringify(this.sourceArr));
            this.sortedData = docArr.filter(
              ({ childFileAndFolder, parentFileAndFolderId }) =>
                (childFileAndFolder?.name
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                  childFileAndFolder?.valueTypeOfDoc?.name
                    .toLowerCase()
                    .includes(search.toLowerCase()) ||
                    childFileAndFolder?.fileButtonMapping?.displayBtnName
                    ?.toLowerCase()
                    .includes(search.toLowerCase())) &&
                parentFileAndFolderId !== null
            );
          } else {
            this.sortData({
              active: this.sortBy,
              direction: this.sortDirection,
            });
          }
          this.cd.detectChanges();
          this.updateBorder();
        })
      )
      .subscribe();
  }

  fileChanged(e) {
    const formData = new FormData();
    formData.append('name', e.target.files[0].name);
    formData.append('file', e.target.files[0]);
    formData.append('parentId', this.currParentId);
    formData.append('caseId', this.caseDetail.id);
    this.appService.createFile(formData).subscribe(
      (data: any) => {
        this.sharedService.open('File created successfully', 'success');
        this.newFileAdded(data?.data?.result);
      },
      (err) => {
        console.log(err);
        this.sharedService.open(
          err.error.message || 'Something went wrong!',
          'failure'
        );
      }
    );
  }

  newFileAdded(file) {
    this.sourceArr.push(file);
    this.updateTree();
    const node = this.getNode(this.dataSource.data, this.currParentId);
    if (node) this.clickedTree(node);
    this.updateBorder();
    this.updateDocList.emit(this.sourceArr);
    this.tree.treeControl.expandAll()
  }

  getNode(arrayData, currId) {
    let selNode;
    for (let i = 0; i < arrayData.length; ++i) {
      if (arrayData[i].childFileAndFolderId === currId) {
        selNode = arrayData[i];
        break;
      }
      if (arrayData[i].children) {
        selNode = this.getNode(arrayData[i].children, currId);
      }
    }
    return selNode;
  }

  delDoc(doc) {
    this.selectedDoc = doc;
    const documentType = doc?.childFileAndFolder?.valueTypeOfDoc?.name === 'folder' ? 'folder' : 'file';
    this.openDialog({
      dialogType: 'CONFIRMATION',
      title: `${documentType === 'folder' ? 'Ordner löschen' : 'Datei löschen'}`,
      message: `${documentType === 'folder' ? 'Der ausgewählte Ordner wird gelöscht.' : 'Die ausgewählte Datei wird gelöscht.'}`,
      allowTooltip: 'Confirm',
      denyTooltip: 'Deny',
    });
  }

  editDoc(doc) {
    this.selectedDoc = doc;
    this.openDialog({
      dialogType: 'DOCUMENT_DETAIL',
      document: this.selectedDoc,
      caseValue: this.caseValueDetail,
    });
  }

  downloadFile(file) {
    this.downloadAttachment.emit({
      path: file?.childFileAndFolder?.path,
      name: file?.childFileAndFolder?.name,
    });
  }

  viewFile(row) {}

  fileIcon(fileType) {
    return fileType === 'pdf' ? 'pdf-icon' : ['png', 'svg', 'jpg', 'jpeg'].includes(fileType) ? 'img-icon' : 'bullet-icon';
  }

  ngOnDestroy() {
    if (this.dialogSubscription) {
      this.dialogSubscription.unsubscribe();
    }
  }
}
