import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { InstitutionCreateComponent } from './institution-create/institution-create.component';
import { InstitutionListComponent } from './institution-list/institution-list.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SharedModule } from '../shared/shared.module';
import { ClientCreateComponent } from './client-create/client-create.component';
import { ClientListComponent } from './client-list/client-list.component';
import { CaseCreateComponent } from './case-create/case-create.component';
import { CaseListComponent } from './case-list/case-list.component';
import { InformationTabComponent } from './case-create/information-tab/information-tab.component';
import { AffectedPartiesComponent } from './case-create/information-tab/affected-parties/affected-parties.component';
import { AccidentComponent } from './case-create/information-tab/accident/accident.component';
import { InstitutionsComponent } from './case-create/information-tab/institutions/institutions.component';
import { VehicleDamageComponent } from './case-create/information-tab/vehicle-damage/vehicle-damage.component';
import { OtherDamageComponent } from './case-create/information-tab/other-damage/other-damage.component';
import { CommentContainer } from './case-create/comments-container/comments-container.component';
import { CommentFormComponent } from './case-create/comments-container/commentForm/commentForm.component';
import { CommentListComponent } from './case-create/comments-container/commentList/commentList.component';
import { CommentsComponent } from './case-create/comments-container/comments/comments.component';
import { PersonalDamageComponent } from './case-create/information-tab/personal-damage/personal-damage.component';
import { DateAdapter } from '@angular/material/core';
import { CustomDateAdapter } from '../components/shared/custom-date-format';
import { TimelineComponent } from './case-create/information-tab/timeline/timeline.component';
import { DialogComponent } from './dialog/dialog.component';
import { DocumentComponent } from './case-create/information-tab/document/document.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from 'ng2-currency-mask/src/currency-mask.config';
import { MycurrencyPipe } from '../components/shared/custom.currencypipe';
import { TemplateComponent } from './template/template.component';
import { QuillModule } from 'ngx-quill';
import { TemplateListComponent } from './template-list/template-list.component';
import { TextBlockComponent } from './text-block/text-block.component';
import { TextBlockListComponent } from './text-block-list/text-block-list.component';
import { DocumentTemplateComponent } from './document-template/document-template.component';
import { DocumentEditorContainerAllModule, DocumentEditorModule, } from '@syncfusion/ej2-angular-documenteditor';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import { ComboBoxModule, DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { DocumentTemplateListComponent } from './document-template-list/document-template-list.component';
import { TableComponent } from './table/table.component';
import { VariableDropdownComponent } from './dialog/variable-dropdown/variable-dropdown.component';
import { WorkshopDashboardComponent } from './workshop-dashboard/workshop-dashboard.component';
import { CaseDetailComponent } from './case-list/case-detail/case-detail.component';


export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'left',
  allowNegative: false,
  decimal: ',',
  precision: 2,
  prefix: '€ ',
  suffix: '',
  thousands: '.'
};
@NgModule({
  declarations: [
    InstitutionCreateComponent,
    InstitutionListComponent,
    SidebarComponent,
    ClientCreateComponent,
    ClientListComponent,
    CaseCreateComponent,
    CaseListComponent,
    InformationTabComponent,
    AffectedPartiesComponent,
    AccidentComponent,
    InstitutionsComponent,
    VehicleDamageComponent,
    OtherDamageComponent,
    PersonalDamageComponent,
    CommentContainer,
    CommentFormComponent,
    CommentListComponent,
    CommentsComponent,
    TimelineComponent,
    DialogComponent,
    DocumentComponent,
    MycurrencyPipe,
    TemplateComponent,
    TemplateListComponent,
    TextBlockComponent,
    TextBlockListComponent,
    DocumentTemplateComponent,
    DocumentTemplateListComponent,
    TableComponent,
    VariableDropdownComponent,
    WorkshopDashboardComponent,
    CaseDetailComponent
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    SharedModule,
    CurrencyMaskModule,
    DocumentEditorContainerAllModule,
    DocumentEditorModule,
    ToolbarModule,
    DropDownListModule,
    ComboBoxModule,
    QuillModule.forRoot()
  ],
  entryComponents: [
    ClientCreateComponent,
    DialogComponent
  ],
  providers:[
    {provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    DialogComponent,
  ]
})
export class DashboardModule {
  constructor(private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('de-DE');
  }
}
