import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { SocketService } from 'src/app/services/socket.service';
@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
})
export class TimelineComponent implements OnInit {
  @Output() refreshTimeline: EventEmitter<string> = new EventEmitter<any>();

  @Input() caseValueDetail: any;
  @Input() clientId: any;
  @Input() caseDetail: any;
  @Input() statusIconArr: any;
  @Input() userDetail: any;

  public timelineForm: FormGroup;
  public viewMode = true;
  public commentDescription = '';
  public timelineSubscriber : Subscription;
  public maxCharacter = 255;
  private window: any;
  public filter = '';

  constructor(private fb: FormBuilder, public appService: AppService, public socketService: SocketService, @Inject(DOCUMENT) private document: Document) {
    this.window = this.document.defaultView;
  }

  ngOnInit(): void {
    this.createForm(this.caseDetail);
    this.startSubscribing();
  }

  createForm(caseDetail?) {
    this.timelineForm = this.fb.group({
      statusId: [
        caseDetail?.statusId,
        Validators.compose([Validators.required]),
      ],
    });
    if(!caseDetail || !caseDetail.statusId) {
      const newCase = this.caseValueDetail.status.find(({ name }) => name?.toLowerCase() === 'fall neu');
      this.timelineForm.patchValue({
        statusId: newCase?.id
      });
    }
    this.timelineForm.disable();
  }

  formValue() {
    return this.timelineForm.value;
  }

  edit() {
    this.viewMode = !this.viewMode;
    if (this.viewMode) {
      this.timelineForm.disable();
    } else {
      this.timelineForm.enable();
    }
  }

  statusIcon() {
    return this.caseDetail?.statusId
      ? this.statusIconArr.find(
          ({ status }) =>
            this.caseDetail?.status?.name.toLowerCase() === status.toLowerCase()
        ) || this.statusIconArr[0]
      : this.statusIconArr[0];
  }

  stepDate(step) {
    return moment(step?.updatedAt).format('H:mm');
  }

  stepIcon(step) {
    const stepIconArr = this.statusIconArr.map(({ icon, ...rest }) => ({
      ...rest,
      icon: `step-icon-${icon.split('-')[2]}`,
    }));
    return step?.type === 'COMMENT_CREATED'
      ? { icon: 'comment-icon' }
      : stepIconArr.find(
          ({ status }) => step?.header.toLowerCase() === status.toLowerCase()
        ) || stepIconArr[0];
  }

  createComment() {
    if (this.commentDescription?.length && !this.clientId) {
      const payload = {
        description: this.commentDescription,
        caseId: this.caseDetail.id,
      };
      this.appService.createComment(payload).subscribe(
        (res: any) => {
          this.commentDescription = '';
          this.caseDetail['timelines'] = [
            res?.data?.result,
            ...this.caseDetail['timelines'],
          ];
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  keyDownHandle(e) {
    if (e.keyCode == 13 && !e.shiftKey) {
      this.createComment();
      return false;
    }
    const input = String.fromCharCode(e.keyCode);

    if(this.commentDescription?.length >= this.maxCharacter) {
      if (/[a-zA-Z0-9-_ ]/.test(input)) {
        // alert('input was a letter, number, hyphen, underscore or space');
        return false;
      }
    }
  }

  pastedEvent(e) {
    let clipboardData = e.clipboardData || this.window.clipboardData;
    let pastedText = clipboardData.getData('text');
    if((this.commentDescription?.length || 0) + pastedText?.length >= this.maxCharacter) {
      return false;
    }
  }

  startSubscribing() {
    this.timelineSubscriber = this.socketService.socketSubject.subscribe(
      ({ type, step }) => {
        this.subscribedComment({ type, step, });
      }
    );
  }

  subscribedComment({ type, step }) {
    if (
      step.userId !== this.userDetail.user.id &&
      step.caseId === this.caseDetail?.id &&
      type === 'new_step'
    ) {
      this.caseDetail['timelines'] = [
        step,
        ...this.caseDetail['timelines'],
      ];
    }
  }

  currStatus() {
    const newCase = this.caseValueDetail.status.find(({ name }) => name?.toLowerCase() === 'fall neu');
    return this.caseDetail?.status || newCase;
  }

  updateTimelineFilter(filterName) {
    this.filter = filterName !== this.filter ? filterName : '';
  }

  timelineArr() {
    if(!this.caseDetail?.timelines) return [];

    if(!this.filter) return this.caseDetail?.timelines;

    return this.caseDetail?.timelines.filter(({ type }) => this.filter === type );
  }

  deleteComment(commentId) {
    this.appService.deleteComment(commentId).subscribe(
      res => {
        const index = this.caseDetail['timelines'].find(step => step.id === commentId);
        if(~index) {
          this.caseDetail['timelines'].splice(index, 1);
        }
      },
      err => {
        console.log(err);
      }
    )
  }

  ngOnDestroy() {
    this.timelineSubscriber.unsubscribe();
  }
}
