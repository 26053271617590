import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CommentInterface } from "src/app/types/comment.interface";

@Component({
    selector: 'app-comment-list',
    templateUrl: './commentList.component.html',
    styleUrls: ['./commentList.component.css']
})

export class CommentListComponent implements OnInit {
    @Input() commentList = [];
    @Input() currentUserId = '';
    @Input() replies = [];
    @Input() rootId;
    @Input() allComment = [];
    @Input() height = 0;

    @Output() createComment = new EventEmitter<{text:string, parentId:string}>();
    @Output() updateComment = new EventEmitter<any>();
    @Output() deleteComment = new EventEmitter<any>();

    ngOnInit(): void {
    }

    getReplies(commentId: number): CommentInterface[] {
        return this.allComment
          .filter((comment) => comment.parentId == commentId)
          .sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
    }

    getRootComments(): CommentInterface[] {
        return this.commentList.filter((comment) => comment.parentId == this.rootId);
    }
}