import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

interface TableData {
  header : Array<{ id: string; value: string; isSortable: boolean; isInitial: boolean; }>;
  rows: Array<{[name: string]: string;}>;
  count: number;
};

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html'
})
export class TableComponent implements OnInit {

  @Input() tableData: TableData;
  @Input() pageSize: number = 10;
  @Input() pageIndex: number = 0;

  @Output() sortTableData: EventEmitter<string> = new EventEmitter();
  @Output() rowClick: EventEmitter<string> = new EventEmitter();
  @Output() pageSizeChange: EventEmitter<string> = new EventEmitter();

  public matSortActive='';
  public pageSizeOptions

  constructor() { }

  ngOnInit(): void {}

  sortData(e) {
    this.sortTableData.emit(e);
  }

  handleRowClick(row) {
    this.rowClick.emit(row);
  }

  headerList() {
    return this.tableData.header.map(({ id }) => id);
  }

  pageOptionChange(e) {
    this.pageSizeChange.emit(e);
  }

}
