import { Component, Inject, OnInit, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { add } from 'cypress/types/lodash';
import { SharedService } from 'src/app/services/shared.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppService } from 'src/app/services/app.service';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { DateVariableService } from 'src/app/services/dateVariable.service';

interface Role {
    id: string;
    name: string;
}
interface Coworker {
    id: string;
    firstName: string;
    lastName: string;
    selectedRoles: string[];
    hiddenRoles: string[];
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html'
})

export class DialogComponent implements OnInit {

    globalRoles: Role[] = [];

    coworkers: Coworker[] = [];

    currentRoles: Role[] = [];
    possibleRoles: Role[] = [];
    
    currentCoworker: Coworker | null = null;
    dialogRef: MatDialogRef<any> | null = null;

    validatedConditions: { [name: string]: boolean } = {};
  public conditionForm: FormGroup;
  public combinedData: any = {};
  selectedVariable: string;
  public selectedVariableControl = new FormControl('');
  public selectedOperators = [];
  public detailForm: FormGroup;
  public assigneeUsers: any = [];
  public nullValue = null;
  public selectedAttachment = [];
  public conditionType = 'list';
  public selectedCondnIndex;
  public condnName;
  public deletedLogics = [];
  public selectedDocumentTemplate;
  public groupedVariableList: any = [];
  public profileForm: FormGroup;
  public institutionForm: FormGroup;
  public addressForm: FormGroup;
  public passwordForm: FormGroup;
  public userData: any;
  public showPasswordChange = false;
  public isEditingAll: boolean = false;
  public invitationAdress;
  public validMailAdress: boolean = false;
  public isEditingPassword: boolean = false;
  public submitting: boolean = false;
  public currentUser;
    public dropdownValues: any[] = [];
    public lastVariableOperatorId: string | null = null;
    public dropdownValueMap: Map<string, string> = new Map();

  public pages = [
    { id: 'profile', label: 'Profil' },
    { id: 'password', label: 'Passwort ändern' },
    { id: 'institution', label: 'Institution' },
    { id: 'Coworker', label: 'Mitarbeiter' },
    { id: 'Invite', label: 'Einladung' }
  ];
  public currentSection: string = 'profile';
  public currentTitle: string = 'Profil';
    public containers: any[] = [];
    public savedContainers: any[] = [];
    dateOptions = [];
    isEditing: boolean = false; // Flag, um zu prüfen, ob gerade bearbeitet wird


  constructor(
  public fb: FormBuilder,
  public appService: AppService,
  private snackBar: MatSnackBar,
  public sharedService: SharedService,
  @Inject(MAT_DIALOG_DATA) public data: any,
  private dialog: MatDialog,
  private dateVariableService: DateVariableService,
  private cdr: ChangeDetectorRef
) {}

  ngOnInit(): void {
    if (this.data.dialogType === 'CASE_CARD_DETAIL') {
      this.createCardDetailForm(this.data?.caseDetail);
    }

    if (this.data.dialogType === 'DOCUMENT_DETAIL') {
      this.createDocumentDetailForm(this.data);
    }

    if (this.data.dialogType === 'UPLOAD_ATTACHMENT') {
      this.selectedAttachment = this.data.selectedAttachment;
    }

    if (this.data.dialogType === 'UPSERT_CONDITION') {
        this.initializeDropdownValueMap();
        const variableList = this.variableList();
        this.groupedVariableList = this.groupVariables(variableList);
        if (this.data.selectedDocumentTemplate) {
            this.selectedDocumentTemplate = this.data.selectedDocumentTemplate
        }
        this.conditionForm = this.fb.group({
            valueTypeOfConditionId: [null],
            textblockTemplateId: [null, Validators.required],
            conditionArray: this.fb.array([])
        });
        this.addLogic();

        this.computeConditionValidity();
    }

    if (this.data.dialogType === 'USERSETTINGS') {
        this.currentUser = this.userDetail().user;
        this.createProfileForm();
        this.createPasswordForm();
        this.populateProfileForm();
        this.createInstitutionForm();
        this.populateInstitutionForm();
        this.updateTitle();
        this.updateCoworker();
    }

    if (this.data.dialogType === 'DateVariable') {
        this.dateOptions = [
            { value: 'accidentDate', label: 'Unfalldatum' },
            { value: 'vehicleLFirstRegistration', label: 'Erstzulassung Fahrzeug' },
            { value: 'residualValueOfferDeadline', label: 'Restwertangebot Frist' },
            { value: 'dateOfPurchaseContract', label: 'Kaufvertragsdatum' },
            { value: 'repairStartDate', label: 'Reparaturbeginn' },
            { value: 'repairEndDate', label: 'Reparaturende' },
            { value: '#Datum_Heute', label: 'Heutiges Datum' },
        ];
        if (this.data.selectedDocumentTemplate) {
            this.selectedDocumentTemplate = this.data.selectedDocumentTemplate
        }
        this.loadContainers();
    } 
}

    addContainer() {
        if (this.isEditing) {
            this.sharedService.open('Bitte beenden Sie die aktuelle Bearbeitung, bevor Sie eine neue Datumsvariable erstellen.', 'failure');
            return;
        }

        this.isEditing = true;
        this.containers.push({
            variableName: '',
            dateType: '',
            operator: '',
            days: '',
            isValid: false,
            isEditable: true
        });
    }

    removeContainer(index: number) {
        if (this.isEditing) {
            this.sharedService.open('Bitte beenden Sie die aktuelle Bearbeitung, bevor Sie eine andere Aktion ausführen.', 'failure');
            return;
        }

        const confirmDelete = window.confirm('Möchten Sie diese Datumsvariable wirklich löschen?');
        if (confirmDelete) {
            const container = this.containers[index];
            if (container?.id) {
                this.dateVariableService.deleteDateVariable(this.data.documentTemplateId, container.id).subscribe({
                    next: () => {
                        this.sharedService.open('Datumsvariable erfolgreich gelöscht.', 'success');
                        this.sharedService.variableDeletedEvent.next(container.variableName);
                    },
                    error: () => {
                        this.sharedService.open('Fehler beim Löschen der Datumsvariable.', 'failure');
                    }
                });
            }
            this.containers.splice(index, 1);
        }
    }

    isEqualOperator(operatorId: string): boolean {
        const EQUAL_OPERATOR_ID = 'de1f0bb6-47ed-4d1a-b688-bb17bb4c518a';
        return operatorId === EQUAL_OPERATOR_ID;
    }

    fetchDropdownValues(specificVariableOperatorId: string) {
        if (!specificVariableOperatorId || !this.data) return [];
    
        if (this.lastVariableOperatorId === specificVariableOperatorId) {
            return this.dropdownValues;
        }
    
        this.lastVariableOperatorId = specificVariableOperatorId;
    
        const variableOperator = this.data?.conditionValues?.specificVariableOperator
            ?.find(variableOperator => variableOperator?.id === specificVariableOperatorId);
    
        if (!variableOperator) {
            console.warn('Kein spezifischer Variablenoperator gefunden für:', specificVariableOperatorId);
            return [];
        }
    
        const mappedField = variableOperator.caseVariable?.mappedField;
        if (!mappedField) {
            console.warn('Kein mappedField vorhanden für:', specificVariableOperatorId);
            return [];
        }
        const mappingCorrection = {
            "vehicleClass.class": "classOfVehicle",
            "tripType.name": "typeOfTrip",
            "guiltReason.name": "reasonOfGuilt",
            "status.name": "status"
        };
        const dropdownKey = mappingCorrection[mappedField] || mappedField;
    
        if (!this.data?.dropdownsValues[dropdownKey]) {
            console.warn(`Kein Datenfeld gefunden für: ${dropdownKey}`);
            return [];
        }
        this.dropdownValues = this.data.dropdownsValues[dropdownKey].map(item => ({
            id: item.id,
            name: item.class || item.name
        }));
    
        return this.dropdownValues;
    }



  deleteCoworker(coworker: Coworker): void {
    const confirmDelete = window.confirm(
      `Wollen Sie wirklich ${coworker.firstName} ${coworker.lastName} löschen?`
    );
    if (confirmDelete) {
      const index = this.coworkers.indexOf(coworker);
      if (index > -1) {
        this.appService.deleteUser(this.coworkers[index].id).subscribe();
        this.coworkers.splice(index, 1);
      }
    }
  }

    getRoleName(roleId: string): string {
        const role = this.globalRoles.find(r => r.id === roleId);
        return role ? role.name : roleId;
    }

    openRoleEditor(coworker: Coworker, templateRef: TemplateRef<any>): void {
        this.currentCoworker = coworker;
        this.currentRoles = this.globalRoles.filter(role => coworker.selectedRoles.includes(role.id));
        this.possibleRoles = this.globalRoles.filter(role => !coworker.selectedRoles.includes(role.id));

        this.dialogRef = this.dialog.open(templateRef, {
            width: '600px',
            panelClass: 'role-manager'
        });
    }

    drop(event: CdkDragDrop<Role[]> , listType: 'possible' | 'current'): void {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
        }
    }

    saveEditor(): void {
        if (this.currentCoworker) {
            const originalRoles = [...this.currentCoworker.selectedRoles];
            const newRoles = this.currentRoles.map(role => role.id);
            const removedRoles = originalRoles.filter(roleId => !newRoles.includes(roleId));
            const addedRoles = newRoles.filter(roleId => !originalRoles.includes(roleId));
            this.currentCoworker.selectedRoles = newRoles;
            removedRoles.forEach((roleId: string) => {
                this.appService.removeMapping({ userId: this.currentCoworker.id, roleId }).subscribe();
            });
            addedRoles.forEach((roleId: string) => {
                this.appService.addMapping({ userId: this.currentCoworker.id, roleId }).subscribe();
            });
        }
        this.dialogRef?.close();
    }

    closeEditor(): void {
        this.dialogRef?.close();
    }

    createCardDetailForm(detail?) {
        this.detailForm = this.fb.group({
            caseCode: [detail?.caseCode, Validators.compose([Validators.required, Validators.maxLength(20)])],
            lawyerId: [detail?.lawyer?.id, Validators.compose([Validators.required])],
            assigneeId: [detail?.assignee?.id, Validators.compose([Validators.required])],
            referrerId: [detail?.referrer?.id, Validators.compose([])]
        });
        if (detail?.lawyer?.id) {
            this.updateAssigneeUsers();
        }
        if (this.data?.isDisabled) {
            this.detailForm.disable();
        } 
        else {
            this.detailForm.enable();
        }
    }

    submitDetails() {
        if (this.detailForm.valid) {
        this.sharedService.dialogSubmitEvent.next({ data: this.detailForm.value, type: 'CASE_CARD_DETAIL_SUBMITTED' });
        }
    }

    updateAssigneeUsers() {
        const institutionId = this.data?.lawyerUsers.find(lawyer => lawyer.id === this.detailForm.get('lawyerId').value)?.institutionId;
        this.assigneeUsers = this.data?.lawyerUsers.filter(ele => ele?.institutionId === institutionId);
    }

    lawyerUserChange() {
        this.detailForm.patchValue({ assigneeId: null });
        this.updateAssigneeUsers();
    }

    updateCoworker() {
        this.appService.allCoworkerRolls().subscribe((response: any) => {
            const roles = response.data.result;
            roles.forEach((r: any) => {
                this.globalRoles.push({ id: r.id, name: r.name });
            }); 
        })
        this.appService.coworkerList(this.userDetail().user.institutionId)
        .subscribe((response: any) => {
            const data = response.data.result;
            this.coworkers = data.map((user: any) => {
            const allRoles = user.userRoleMapping
                ? user.userRoleMapping.map((urm: any) => urm.role.id)
                : [];
            const selectedRoles = allRoles.filter((roleId: string) =>
                this.globalRoles.some((globalRole: Role) => globalRole.id === roleId)
            );
            const hiddenRoles = allRoles.filter((roleId: string) =>
                !this.globalRoles.some((globalRole: Role) => globalRole.id === roleId)
            );
            return {
                id: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                selectedRoles: selectedRoles,
                hiddenRoles: hiddenRoles
            };
            });
        });
    }

  createDocumentDetailForm(detail?) {
    this.detailForm = this.fb.group({
      name: [detail?.document?.childFileAndFolder?.name, Validators.compose([Validators.required, Validators.maxLength(50)])],
    });
    if (detail?.document?.childFileAndFolder?.valueTypeOfDoc?.name === 'file') {
      this.detailForm.addControl('buttonId', new FormControl(detail?.document?.childFileAndFolder?.fileButtonMapping?.id, Validators.compose([])));
    }
  }

  submitDocumentDetails() {
    if (this.detailForm.valid) {
      this.sharedService.dialogSubmitEvent.next({ data: this.detailForm.value, type: 'DOCUMENT_DETAIL_SUBMITTED' });
    }
  }

  deny() {
    this.sharedService.dialogSubmitEvent.next({ type: 'CONFIRMATION_DENIED' });
  }

  allow() {
    this.sharedService.dialogSubmitEvent.next({ type: 'CONFIRMATION_ALLOWED' });
  }

  updatedName(name) {
    return name
      .split('_')
      .map((ele) => `${ele.charAt(0).toUpperCase()}${ele.substring(1)}`)
      .join(' ');
  }

  infoSubmit() {
    this.sharedService.dialogSubmitEvent.next({ type: 'INFO_SUBMITTED' });
  }

  checkboxChange(event, variable) {
    if (event.checked) {
      this.selectedAttachment.push(variable.id);
    } else {
      if (~this.selectedAttachment.indexOf(variable.id)) {
        const index = this.selectedAttachment.indexOf(variable.id);
        this.selectedAttachment.splice(index, 1);
      }
    }
    this.sharedService.dialogSubmitEvent.next({ type: 'DOCUMENT_ATTACHMENT_UPDATED', value: this.selectedAttachment });
  }

  conditionTypeChange(value) {
    if (value === 'create') {
      this.conditionForm.patchValue({
        valueTypeOfConditionId: null,
        textblockTemplateId: null,
      });
      const conditionArray = this.conditionForm.get('conditionArray') as FormArray;
      for (let i = conditionArray.length - 1; i >= 0; i--) {
        conditionArray.removeAt(i);
      }
      if (~this.selectedCondnIndex) {
        const condition = this.data?.conditionList[this.selectedCondnIndex];
        this.conditionForm.patchValue({
          valueTypeOfConditionId: condition.valueTypeOfConditionId,
          textblockTemplateId: condition.textblockTemplateId,
        });
        this.conditionForm.get('valueTypeOfConditionId').setValidators(Validators.required);
        for (let i = 0; i < condition?.logic?.length; ++i) {
          this.addLogic(condition.logic[i]);
        }
      } else {
        this.addLogic();
      }
    }
    this.conditionType = value;
  }

  onSubmit() {
    const valueTypeOfConditionId = this.conditionForm.value.valueTypeOfConditionId ?? this.data?.conditionValues?.typeOfCondition[0]?.id;
    const condition = {
      name: this.condnName,
      textblockTemplateId: this.conditionForm.value.textblockTemplateId,
      valueTypeOfConditionId,
      valueTypeOfCondition: this.data?.conditionValues?.typeOfCondition.find(variableOperator => variableOperator?.id === valueTypeOfConditionId),
      logic: this.conditionForm.value.conditionArray?.map(logic => ({ ...logic, specificVariableOperator: this.fetchSpecificVariableOperator(logic.specificVariableOperatorId) })),
      ...(~this.selectedCondnIndex && { id: this.data?.conditionList[this.selectedCondnIndex].id }),
    };

    this.sharedService.dialogSubmitEvent.next({ type: 'CONDITION_UPSERTED', value: { condition, updatedIndex: this.selectedCondnIndex, deletedLogics: this.deletedLogics } });
  }

  addLogic(logic?) {
    let variableArr = [];

    this.groupedVariableList.forEach(ele => {
      if (!ele.group) {
        variableArr = [...variableArr, ele];
      }

      if (ele.group) {
        variableArr = [...variableArr, ...ele.list];
      }
    })

    const variable = variableArr.find(ele => ele?.caseVariable?.id === logic?.specificVariableOperator?.caseVariable?.id);

    const conditionGroup = this.fb.group({
      attribute: [logic?.specificVariableOperator?.caseVariable?.id || null, Validators.required],
      value: [logic?.value],
      specificVariableOperatorId: [logic?.specificVariableOperator?.id || null, Validators.required],
      selectedVariable: [variable || null],
      ...(logic?.id && { id: logic.id })
    });

    (<FormArray>this.conditionForm.get('conditionArray')).push(conditionGroup);
    if((this.conditionForm.get('conditionArray') as FormArray).length !== 1) {
      this.conditionForm.get('valueTypeOfConditionId').setValidators([Validators.required]);
      this.conditionForm.get('valueTypeOfConditionId').updateValueAndValidity(); 
    }
  }

  removeCondition(index: number) {
    const grouped = (this.conditionForm.get('conditionArray') as FormArray).controls[index];
    if (grouped.value?.id) {
      this.deletedLogics.push(grouped.value.id);
    }
    (this.conditionForm.get('conditionArray') as FormArray).removeAt(index);

    if((this.conditionForm.get('conditionArray') as FormArray).length === 1) {
      this.conditionForm.get('valueTypeOfConditionId').setValidators([]);
      this.conditionForm.get('valueTypeOfConditionId').updateValueAndValidity(); 
    }
  }

  operatorList(caseVariableId) {
    return this.data?.conditionValues?.specificVariableOperator.filter(variableOperator => variableOperator?.caseVariable?.id === caseVariableId) || [];
  }

  variableList() {
    return [...new Map(this.data?.conditionValues?.specificVariableOperator.map(item =>
      [item?.caseVariable?.id, item])).values()].sort((a: any, b: any) => a.caseVariable?.name.localeCompare(b.caseVariable?.name));
  }

  fetchSpecificVariableOperator(specificVariableOperatorId) {
    return this.data?.conditionValues?.specificVariableOperator.find(variableOperator => variableOperator?.id === specificVariableOperatorId);
  }

  updateSpecificOperator(conditionGroup: FormGroup) {
    conditionGroup.patchValue({ specificVariableOperatorId: "", value: "" });
  }

//   editCondn(index) {
//     this.selectedCondnIndex = index;
//     this.condnName = this.data?.conditionList[this.selectedCondnIndex].name;
//     this.conditionTypeChange('create');
//   }
    editCondn(index) {
        this.selectedCondnIndex = index;
        const condition = this.data?.conditionList[this.selectedCondnIndex];
        this.condnName = condition.name;
        condition.oldName = condition.name;
        this.conditionTypeChange('create');
    }
    

  createCondn() {
    this.selectedCondnIndex = -1;
    const condnNameArr = this.data?.conditionList.map(ele => parseInt(ele?.name?.split('BEDINGUNG')?.[1]));
    const condnName = 'Neue Bedingung';
    this.condnName = condnName;
    this.conditionTypeChange('create');
    this.deletedLogics = [];
  }

  deleteCondn(index) {
    this.sharedService.dialogSubmitEvent.next({ type: 'CONDITION_DELETED', value: { index } });
  }

  groupVariables(variableList) {
    let updatedList = [];
    
    variableList?.forEach(variable => {
      const name = variable?.caseVariable?.name;
      if (updatedList.findIndex(({ group, list }) => group === name.split('_')[0] && list && list.findIndex(({ group: childGroup }) => childGroup && childGroup.split('_')[1] === name.split('_')[1]) !== -1) !== -1) {
        const parentIndex = updatedList.findIndex(({ group, list }) => group === name.split('_')[0] && list && list.findIndex(({ group: childGroup }) => childGroup && childGroup.split('_')[1] === name.split('_')[1]) !== -1);
        const childIndex = updatedList[parentIndex].list.findIndex(({ group: childGroup }) => childGroup && childGroup.split('_')[1] === name.split('_')[1]);
        updatedList[parentIndex].list[childIndex].list = [...updatedList[parentIndex].list[childIndex].list, { label: name, value: name, type: 'variable', ...variable }];
      } else if (updatedList.findIndex(({ group, list }) => group === name.split('_')[0] && list && list.findIndex(({ label }) => label && label.split('_')[1] === name.split('_')[1]) !== -1) !== -1) {
        const parentIndex = updatedList.findIndex(({ group, list }) => group === name.split('_')[0] && list && list.findIndex(({ label }) => label && label.split('_')[1] === name.split('_')[1]) !== -1);
        const childIndex = updatedList[parentIndex].list.findIndex(({ label }) => label && label.split('_')[1] === name.split('_')[1]);
        const currVariable = updatedList[parentIndex].list[childIndex];
        updatedList[parentIndex].list.splice(childIndex, 1, { group: `${name.split('_')[0]}_${name.split('_')[1]}`, list: [currVariable, { label: name, value: name, type: 'variable', ...variable }] });
      } else if (updatedList.findIndex(({ group }) => group === name.split('_')[0]) !== -1) {
        const index = updatedList.findIndex(({ group }) => group === name.split('_')[0]);
        updatedList[index].list = [...updatedList[index].list, { label: name, value: name, type: 'variable', ...variable }];
      } else if (updatedList.findIndex(({ label }) => label && label.split('_')[0] === name.split('_')[0]) !== -1) {
        const index = updatedList.findIndex(({ label }) => label && label.split('_')[0] === name.split('_')[0]);
        const currVariable = updatedList[index];
        updatedList.splice(index, 1, { group: name.split('_')[0], list: [currVariable, { label: name, value: name, type: 'variable', ...variable }] });
        if (currVariable.value.split('_')[1] === name.split('_')[1]) {
          updatedList[index].list = [{ group: `${name.split('_')[0]}_${name.split('_')[1]}`, list: updatedList[index].list }];
        }
      } else {
        updatedList = [...updatedList, { label: name, value: name, type: 'variable', ...variable }];
      }
    });
    
    return updatedList;
  }

  logicVariableSelected(event, index) {
    (this.conditionForm.get('conditionArray') as FormArray).at(index).patchValue({ specificVariableOperatorId: "", value: "", attribute: event?.value.caseVariable?.id });
  }

  private createProfileForm() {
    this.profileForm = this.fb.group({
        email: [''],
        username: [''],
        firstName: [''],
        lastName: [''],
        phone: [''],
    });
    }

    createPasswordForm() {
        this.passwordForm = this.fb.group({
            oldPassword: ['', Validators.required],
            newPassword: ['', [Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&\\-])[A-Za-z\\d@$!%*?&\\-]{8,}$')]],
            confirmPassword: ['', Validators.required]
        }, { validators: this.passwordsMatch });
    }


    populateProfileForm() {
        const id = this.userDetail()?.user?.id;
        this.appService.getUserInformation(id).subscribe(
            (userData: any) => {
                if (userData) {
                    this.profileForm.patchValue({
                        email: userData?.data?.email,
                        username: userData?.data?.username,
                        firstName: userData?.data?.firstName,
                        lastName: userData?.data?.lastName,
                        phone: userData?.data?.phone,
                    });
                }
            },
            (error) => {
                console.error("Fehler beim Laden der Benutzerdaten:", error);
                this.sharedService.open("Fehler beim Laden der aktuellen Benutzerdaten", 'failure');
            }
        );
    }

    private createInstitutionForm() {
        this.institutionForm = this.fb.group({
            name: ['', Validators.required],
            phone: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            IBAN: ['', Validators.required],
            // UStID: ['', Validators.required],
            bankname: ['', Validators.required]
        });
    
        this.addressForm = this.fb.group({
            city: ['', Validators.required],
            street: ['', Validators.required],
            houseNumber: ['', Validators.required],
            postcode: ['', Validators.required]
        });
    }
    
    private populateInstitutionForm() {
        const userData = this.userDetail();
        const institutionId = userData?.user?.institutionId;
    
        if (!institutionId) {
            return;
        }
    
        this.appService.userInstitutionDetail(institutionId).pipe(
            map(({ data }: any) => ({
                name: data.result.name,
                phone: data.result.phone,
                email: data.result.email,
                IBAN: data.result.IBAN,
                // UStID: data.result.UStID,
                bankname: data.result.bankname,
                address: data.result.adress ? {
                    city: data.result.adress.city || '',
                    street: data.result.adress.street || '',
                    houseNumber: data.result.adress.houseNumber || '',
                    postcode: data.result.adress.postcode || ''
                } : { city: '', street: '', houseNumber: '', postcode: '' }
            })),
            catchError(error => {
                console.error("Fehler beim Laden der Institutionsdaten:", error);
                return of(null);
            })
        ).subscribe(institutionData => {
            if (institutionData) {
                this.institutionForm.patchValue({
                    name: institutionData.name,
                    phone: institutionData.phone,
                    email: institutionData.email,
                    IBAN: institutionData.IBAN,
                    // UStID: institutionData.UStID,
                    bankname: institutionData.bankname
                });
    
                this.addressForm.patchValue(institutionData.address);
            }
        });
    }

    userDetail() {
    return localStorage.getItem('userDetail') 
        ? JSON.parse(window.atob(localStorage.getItem('userDetail'))) 
        : null;
    }

    passwordsMatch(group: FormGroup): { [key: string]: any } | null {
        const newPassword = group.get('newPassword').value;
        const confirmPassword = group.get('confirmPassword').value;
        return newPassword === confirmPassword ? null : { passwordsMismatch: true };
    }

    togglePasswordChange(): void {
        this.showPasswordChange = !this.showPasswordChange;
    }

    savePasswordChange(): void {
        if (this.passwordForm.valid) {
            const { oldPassword, newPassword } = this.passwordForm.value;
            this.submitting = true;
            this.appService.changePassword({ oldPassword, newPassword }).subscribe(
                (response: any) => {
                    this.sharedService.open(response?.result?.message || 'Passwort erfolgreich geändert', 'success');
                    this.passwordForm.reset();
                    Object.keys(this.passwordForm.controls).forEach(key => {
                        this.passwordForm.controls[key].setErrors(null);
                        this.passwordForm.controls[key].markAsPristine();
                        this.passwordForm.controls[key].markAsUntouched();
                    });
                    this.submitting = false;
                },
                (error) => {
                    console.error("Fehler beim Ändern des Passworts:", error);
                    const errorMessage = error.error?.message || 'Fehler beim Ändern des Passworts. Bitte versuchen Sie es erneut.';
                    this.sharedService.open(errorMessage, 'failure');
                    this.passwordForm.setErrors({ serverError: errorMessage });
                    this.submitting = false;
                }
            );
        }
    }

    showSuccessMessage(message: string) {
        this.snackBar.open(message, 'Schließen', {
            duration: 3000, // Auto close after 3s
            panelClass: ['success-snackbar'] // Optional styling
        });
    }
    
    showErrorMessage(message: string) {
        this.snackBar.open(message, 'Schließen', {
            duration: 3000,
            panelClass: ['error-snackbar']
        });
    }

    saveUserSettings(): void {
        if (this.profileForm.valid) {
            const profileValue = this.profileForm.value;
            const id = this.userDetail().user.id;
            this.submitting = true;
    
            this.appService.updateUserInformation({
                id,
                username: profileValue.username,
                email: profileValue.email,
                firstName: profileValue.firstName,
                lastName: profileValue.lastName,
                phone: profileValue.phone,
            }).subscribe(
                (response: any) => {
                    this.sharedService.open(response?.result?.message || 'Benutzerdaten erfolgreich aktualisiert', 'success');
                    let userDetail = this.sharedService.userDetail();
                    userDetail.user.firstName = response?.data?.result?.firstName;
                    userDetail.user.lastName = response?.data?.result?.lastName;
                    localStorage.setItem('userDetail', window.btoa(JSON.stringify(userDetail)));
                    this.isEditingAll = false;
                    this.populateProfileForm();
                    this.submitting = false;
                    this.sharedService.userLoginSuccess.next(true);
                },
                (error) => {
                    console.error("Fehler beim Aktualisieren der Benutzerdaten:", error);
                    const errorMessage = error.error?.message || 'Fehler beim Speichern der Benutzerdaten. Bitte versuchen Sie es erneut.';
                    this.sharedService.open(errorMessage, 'failure');
                    this.profileForm.setErrors({ serverError: errorMessage });
                    this.submitting = false;
                }
            );
        }
    }

    updateTitle(): void {
        const currentPage = this.pages.find(page => page.id === this.currentSection);
        this.currentTitle = currentPage ? currentPage.label : 'Profil';
    }

    toggleEditAll(): void {
        this.isEditingAll = !this.isEditingAll;
    }
    
    cancelEdit(): void {
        this.isEditingAll = false;
        this.profileForm.reset(this.userDetail().user);
    }

    saveInstitutionSettings(): void {
        const userData = this.userDetail();
        const institutionId = userData?.user?.institutionId;
        const institutionData = {
            ...this.institutionForm.value,
            ...this.addressForm.value
        };
        this.appService.updateUserInstitution(institutionId, institutionData).subscribe(institutionData => {
        this.isEditingAll = false;
    })}

    inviteViaEmail() {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (emailPattern.test(this.invitationAdress)) {
            this.validMailAdress = false;
            this.appService.inviteUserToInstitution({ email: this.invitationAdress, }).subscribe((data: any) => {
                this.sharedService.open(data.data.result.message, 'success')
            },(error) => {
                const errorMessage = error.error?.message || 'Fehler beim versenden der Einladung';
                this.sharedService.open(errorMessage, 'failure');
                this.profileForm.setErrors({ serverError: errorMessage });
            } )
        }
        else {
            this.validMailAdress = true;
        }
    }

    computeConditionValidity() {
        this.validatedConditions = {};
        this.data.conditionList.forEach(cond => {
        let isValid = false;
        if (cond.logic && cond.logic.length > 0) {
            isValid = cond.logic.some(logic => this.validateLogic(logic));
        }
        this.validatedConditions[cond.name] = isValid;
        });
    }

    getDropdownValue(id: string): string | null {
        return this.dropdownValueMap.get(id) || null;
    }

    initializeDropdownValueMap() {
        if (!this.data?.dropdownsValues) return;
    
        this.dropdownValueMap.clear();
        
        Object.values(this.data.dropdownsValues).forEach((subArray: any) => {
            if (Array.isArray(subArray)) {
                subArray.forEach(item => {
                    const name = item.class || item.name;
                    if (item.id && name) {
                        this.dropdownValueMap.set(item.id, name);
                    }
                });
            }
        });
    }
    
    validateLogic(logic: any): boolean {
        const variableValue = this.data?.filledVariables[logic.specificVariableOperator.caseVariable.name];

        let logicValue = logic.value;
        if (this.dropdownValueMap.has(logic.value)) {
            logicValue = this.dropdownValueMap.get(logic.value);
        }
        
        let isValid = false;
    
        switch (logic.specificVariableOperator.operator.value) {
          case '==': isValid = variableValue === logicValue;
            break;
          case '<=': isValid = parseFloat(variableValue) <= parseFloat(logic.value);
            break;
          case '<': isValid = parseFloat(variableValue) < parseFloat(logic.value);
            break;
          case '>=': isValid = parseFloat(variableValue) >= parseFloat(logic.value);
            break;
          case '>': isValid = parseFloat(variableValue) > parseFloat(logic.value);
            break;
          case '!=': isValid = variableValue != logic.value;
            break;
          case 'is empty': isValid = !variableValue?.trim();
            break;
          case 'is not empty': isValid = !!variableValue?.trim();
            break;
          default: isValid = false;
        }
    
        return isValid;

    }

    enableEdit(index: number) {
        if (this.isEditing) {
            this.sharedService.open('Bitte beenden Sie die aktuelle Bearbeitung, bevor Sie eine andere Aktion ausführen.', 'failure');
            return;
        }
        this.isEditing = true;
        this.containers[index].isEditable = true;
    }

    saveEdit(index: number) {
        const container = this.containers[index];
        container.isValid = !!(container.variableName && container.dateType && container.operator && container.days);
        if (!container.isValid) {
            this.sharedService.open('Bitte füllen Sie alle Felder aus, bevor Sie speichern.', 'failure');
            return;
        }
    
        const oldName = container.oldVariableName;
        const newName = container.variableName;
    
        this.dateVariableService.updateDateVariable(this.data.documentTemplateId, container).subscribe({
            next: () => {
                container.isEditable = false;
                this.isEditing = false;
                this.sharedService.open('Änderungen an der Datumsvariable erfolgreich gespeichert.', 'success');

                if (oldName) {
                    this.sharedService.variableUpdatedEvent.next({ oldName, newName });
                }
            },
            error: () => {
                this.sharedService.open('Fehler beim Speichern der Änderungen an der Datumsvariable.', 'failure');
            }
        });
    }

    saveNewContainer(index: number) {
        const container = this.containers[index];
        container.isValid = !!(container.variableName && container.dateType && container.operator && container.days);
        if (!container.isValid) {
            this.sharedService.open('Bitte füllen Sie alle Felder aus, bevor Sie speichern.', 'failure');
            return;
        }
    
        const containerToSave = {
            variableName: container.variableName,
            dateType: container.dateType,
            operator: container.operator,
            days: container.days,
            documentTemplateId: this.data.documentTemplateId
        };

        this.dateVariableService.createDateVariable(this.data.documentTemplateId, containerToSave).subscribe({
            next: (res) => {
                container.isEditable = false;
                this.isEditing = false;
                this.sharedService.open('Neue Datumsvariable erfolgreich gespeichert.', 'success');
                this.loadContainers();
                this.sharedService.variableUpdatedEvent.next();
            },
            error: () => {
                this.sharedService.open('Fehler beim Speichern der neuen Datumsvariable.', 'failure');
            }
        });
    }

    validateDays(container: any): void {
        if (container.days < 0) {
            container.days = 0;
        } else if (container.days > 3653) {
            container.days = 3653;
        }
        this.cdr.detectChanges();
    }

    cancelDateEdit(index: number) {
        const container = this.containers[index];
        if (!container.id) {
            this.containers.splice(index, 1);
        } else {
            container.isEditable = false;
        }

        this.isEditing = false;
    }

    loadContainers() {
        this.appService.getDateVariables(this.data.documentTemplateId).subscribe({
            next: (response: any) => {
                this.containers = response.data.result.map(container => ({
                    ...container,
                    isEditable: false
                }));
            },
            error: (error) => {
                console.error('Fehler beim Abrufen der DateVariables:', error);
            }
        });
    }
}

