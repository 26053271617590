import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { AppService } from 'src/app/services/app.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
})
export class ClientListComponent implements OnInit {
  @Output() navigateClient: EventEmitter<string> = new EventEmitter<any>();
  @Output() close: EventEmitter<string> = new EventEmitter<any>();
  @Output() selectedClient: EventEmitter<string> = new EventEmitter<any>();

  @Input() refClientId: any;

  @ViewChild('searchInput') searchInput: ElementRef;

  public dataSource: any;
  public userDetail: any;
  public pageSize = 20;
  public pageIndex = 0;
  public displayedColumns = ['name', 'plz', 'ort', 'email', 'telefonNummer'];
  public sortBy = '';
  public sortOrder = '';
  public isInitialLoad = true;

  constructor(public router: Router, public appService: AppService, public sharedService: SharedService, private dialogRef: MatDialogRef<ClientListComponent>) { }

  ngOnInit(): void {
    this.userDetail = this.sharedService.userDetail();
    this.clientList();
    this.sharedService.getVariableDropdownEvent().subscribe(e => {
      if(e) {
        this.dialogRef.disableClose = true;
      } else {
        this.dialogRef.disableClose = false;
      }
    })
  }

  clientList(){
    const search = this.searchInput && this.searchInput.nativeElement ? this.searchInput.nativeElement.value.trim() : ''; 
    this.appService.clientList(this.userDetail?.user?.institutionId, this.pageIndex, this.pageSize, { sortBy: this.sortBy, sortOrder: this.sortOrder, search }).subscribe(
      ({data}: any) => {
        this.convertToTableData(data.result);
        if(this.isInitialLoad) {
          this.listenSearch();
          this.isInitialLoad = false;
        }
      },
      error => {
        console.log(error);
      }
    )
  }

  navigate(client?){
    if(client) {
      if(this.refClientId) {
        this.navigateClient.emit(client);
        return this.selectedClient.emit();
      } else {
        return this.router.navigate([`/dashboard/editClient/${client.id}`]);
      }
    }

    this.router.navigate([`/dashboard/createClient`]);
  }

  pageSizeChange(e){
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    this.clientList();
  }

  closeDetailModal() {
    this.close.emit();
  }

  sortData(e) {
    this.sortBy = e.active === 'ort' ? 'city' : e.active === 'plz' ? 'postcode' : e.active === 'telefonNummer' ? 'phone' : e.active === 'name' ? 'name' : e.active;
    this.sortOrder = e.direction;
    this.clientList();
  }

  listenSearch() {
    fromEvent(this.searchInput.nativeElement, 'keydown')
    .pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => {
        this.clientList();
      })
    )
    .subscribe();
  }

  convertToTableData(data) {
    let tableData = {};
    tableData['header'] = [
      {id: 'name', value: 'Name', isSortable: true, isInitial: false },
      {id: 'plz', value: 'PLZ', isSortable: true, isInitial: false },
      {id: 'ort', value: 'Ort', isSortable: true, isInitial: false },
      {id: 'email', value: 'E-Mail-Adresse', isSortable: true, isInitial: false },
      {id: 'telefonNummer', value: 'Telefonnummer', isSortable: true, isInitial: false },
    ];
    
    tableData['rows'] = data?.rows?.map((mandant) => ({
      ...mandant,
      name: mandant?.firstName ? `${mandant?.firstName} ${mandant?.lastName}` : mandant?.companyName,
      plz: mandant?.adress?.postcode,
      ort: mandant?.adress?.city,
      email: mandant?.email,
      telefonNummer: mandant?.phone
    }));

    tableData['count'] = data?.count;
    this.dataSource = tableData;
  }
}
