import { 
    Component,
    EventEmitter,
    HostListener,
    Inject,
    Input,
    OnInit,
    Output
    } from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { AppService } from 'src/app/services/app.service';
import { SharedService } from 'src/app/services/shared.service';
import { SubSink } from 'subsink';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-client-create',
    templateUrl: './client-create.component.html',
})
export class ClientCreateComponent implements OnInit {
    @Output() backTrigger: EventEmitter<string> = new EventEmitter<any>();
    @Output() selectedClient: EventEmitter<string> = new EventEmitter<any>();
    @Output() close: EventEmitter<string> = new EventEmitter<any>();

    @Input() refClientId: any;
    @Input() refViewMode: boolean;

    public clientForm: FormGroup;
    private subs = new SubSink();
    public isPerson:boolean;
    public anreden = [];
    public titels = [];
    public userDetail: any;
    public viewMode = true;
    public clientDetail: any;
    public clientId = '';
    public formChanged = false;
    private nullValue = null;
    public updating = false;

    constructor(
        public appService: AppService,
        public sharedService: SharedService,
        public fb: FormBuilder,
        private route: ActivatedRoute,
        public router: Router,
        @Inject(MAT_DIALOG_DATA) public dialogData: any,
        public dialog: MatDialog
    ) { }

    @HostListener('window:beforeunload')
    canDeactivate() {
        if (this.formChanged) {
            if (
                confirm(
                    'Möchten Sie die getätigten Änderungen verwerfen und ohne Speichern verlassen?'
                )
            ) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    }

    ngOnInit(): void {
        if (this.refClientId) {
            this.clientId = this.refClientId;
        }
        if (this.dialogData) {
            this.clientId = this.dialogData?.clientId;
        }
        this.route.paramMap.subscribe(({ params }: any) => {
            this.clientId = params.id || this.clientId || this.refClientId;
            this.fetchSalutationTitle();
            this.userDetail = this.sharedService.userDetail();
        });
    }

    isPersonOnInit() {
        if (this.clientDetail?.isPerson === true) {
            this.onSelectionChange({ value: 'true' });
        } else if(this.clientDetail?.isPerson === false) {
            this.onSelectionChange({ value: 'false' });
        }
        //Loading older Mandants that were not created with a boolean
        //set to true, because all older clients could only be Persons so isPerson needs to be true!
        else {
            this.onSelectionChange({ value: 'true' })
        }
    }


    onSelectionChange(event: any) {
        if(event.value === 'true'){
            this.clientForm.get("firstName").setValidators(Validators.compose([Validators.required]))
            this.clientForm.get("valueSalutationId").setValidators(Validators.compose([Validators.required]))
            this.clientForm.get("lastName").setValidators(Validators.compose([Validators.required]))
            this.clientForm.get("companyName").clearValidators();
            this.clientForm.get("companyDirectorValueSalutationId").clearValidators();
            this.clientForm.get("companyDirectorValueTitleId").clearValidators();
            this.clientForm.get("companyDirectorFirstName").clearValidators();
            this.clientForm.get("companyDirectorLastName").clearValidators();

            this.clientForm.get("companyName").setValue(null);
            this.clientForm.get("companyDirectorValueSalutationId").setValue(null);
            this.clientForm.get("companyDirectorValueTitleId").setValue(null);
            this.clientForm.get("companyDirectorFirstName").setValue(null);
            this.clientForm.get("companyDirectorLastName").setValue(null);

            this.isPerson = true;
        }
        else if (event.value === 'false') {
            this.clientForm.get("companyDirectorValueSalutationId").setValidators(Validators.compose([]))
            this.clientForm.get("companyDirectorValueTitleId").setValidators(Validators.compose([]))
            this.clientForm.get("companyDirectorFirstName").setValidators(Validators.compose([]))
            this.clientForm.get("companyDirectorLastName").setValidators(Validators.compose([]))
            this.clientForm.get("companyName").setValidators(Validators.compose([]))
            this.clientForm.get("lastName").clearValidators();
            this.clientForm.get("firstName").clearValidators();
            this.clientForm.get("valueSalutationId").clearValidators();
            this.clientForm.get("valueTitleId").clearValidators();

            this.clientForm.get("valueSalutationId").setValue(null);
            this.clientForm.get("companyDirectorValueTitleId").setValue(null);
            this.clientForm.get("lastName").setValue(null);
            this.clientForm.get("firstName").setValue(null);

            this.isPerson = false;
        }
        this.clientForm.updateValueAndValidity();

        this.clientForm.get("companyName").updateValueAndValidity();
        this.clientForm.get("lastName").updateValueAndValidity();
        this.clientForm.get("firstName").updateValueAndValidity();
        this.clientForm.get("valueSalutationId").updateValueAndValidity();
        this.clientForm.get("companyDirectorValueSalutationId").updateValueAndValidity();
        this.clientForm.get("companyDirectorValueTitleId").updateValueAndValidity();
        this.clientForm.get("companyDirectorFirstName").updateValueAndValidity();
        this.clientForm.get("companyDirectorLastName").updateValueAndValidity();
    }

    createForm(data?) {
        this.clientForm = this.fb.group({
            isPerson: [this.isPerson, Validators.compose([])],
            valueSalutationId: [
                data?.valueSalutationId,
                Validators.compose([]),
            ],
            valueTitleId: [data?.companyDirectorAnrede, Validators.compose([])],
            companyName: [data?.companyName, Validators.compose([])],
            firstName: [data?.firstName, Validators.compose([])],
            lastName: [data?.lastName, Validators.compose([])],
            companyDirectorValueSalutationId: [
                data?.companyDirectorValueSalutationId,
                Validators.compose([]),
            ],
            companyDirectorValueTitleId: [data?.companyDirectorValueTitleId, Validators.compose([])],
            companyDirectorFirstName: [data?.companyDirectorFirstName, Validators.compose([])],
            companyDirectorLastName: [data?.companyDirectorLastName, Validators.compose([])],
            street: [data?.adress?.street, Validators.compose([])],
            houseNumber: [
                data?.adress?.houseNumber,
                Validators.compose([]),
            ],
            postcode: [
                data?.adress?.postcode,
                Validators.compose([]),
            ],
            city: [data?.adress?.city, Validators.compose([])],
            phone: [data?.phone, Validators.compose([])],
            email: [
                data?.email,
                Validators.compose([
                    Validators.pattern(
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    ),
                ]),
            ],
            IBAN: [data?.IBAN, Validators.compose([])],
            bankname: [data?.bankname, Validators.compose([])],
            // UStID: [data?.UStID, Validators.compose([])],
            accountOwner: [data?.accountOwner, Validators.compose([])],
            contactPerson1: [data?.contactPerson1, Validators.compose([])],
            contactPerson2: [data?.contactPerson2, Validators.compose([])],
            mobile: [data?.mobile, Validators.compose([])],
        });
        if (this.clientId) {
            this.clientForm.addControl(
                'id',
                new FormControl(data?.id, Validators.compose([Validators.required]))
            );
            this.validateAllFormFields(this.clientForm);
        }
        else
            this.clientForm.addControl(
                'institutionId',
                new FormControl(
                    this.userDetail?.user?.institutionId,
                    Validators.compose([Validators.required])
                )
            );

        setTimeout(() => this.detectFormValueChange());
    }

    detectFormValueChange() {
        this.clientForm.valueChanges.subscribe((event) => {
            this.formChanged = true;
        });
    }

    fetchSalutationTitle() {
        this.subs.sink = this.appService.salutationTitleList().subscribe(
            ({ data }: any) => {
                this.anreden = data?.result?.salutation;
                this.titels = data?.result?.title;
                if (this.clientId) this.fetchClientDetail();
                else this.createForm();
            },
            (error) => {
                console.log(error);
            }
        );
    }

    fetchClientDetail() {
        this.subs.sink = this.appService.clientDetail(this.clientId).subscribe(
            ({ data }: any) => {
                this.clientDetail = data?.result;
                this.createForm(this.clientDetail);
                this.isPersonOnInit();
                this.clientForm.disable();
            },
            (error) => {
                console.log(error);
            }
        );
    }

    createClient() {
        if (!this.updating) {
            if (!this.clientForm.valid) {
                this.validateAllFormFields(this.clientForm);
                return;
            } else {
                this.clientForm.value.isPerson = this.isPerson;
                this.updating = true;
                this.subs.sink = this.appService
                    .createClient(this.clientForm.value)
                    .pipe(finalize(() => { this.formChanged = false; this.updating = false; }))
                    .subscribe(
                        ({ data }: any) => {
                            this.router.navigate(['/dashboard/clientList']);
                            this.sharedService.open(
                                `Mandant wurde angelegt.`,
                                'success'
                            );
                        },
                        (error) => {
                            console.error(error);
                            this.sharedService.open(error.error.message, 'failure');
                        }
                    );
            }
        }
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach((field) => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    editClient() {
        if (!this.updating) {
            if (this.viewMode) {
                this.viewMode = !this.viewMode;
                this.clientForm.enable();
            } else {
                this.clientForm.value.isPerson = this.isPerson;
                this.updating = true;
                this.appService
                    .editClient(this.clientForm.value)
                    .pipe(finalize(() => { this.formChanged = false; this.updating = false; }))
                    .subscribe(
                        ({ data }: any) => {
                            this.viewMode = !this.viewMode;
                            this.clientForm.disable();
                            this.sharedService.open(
                                `Änderungen wurden gespeichert.`,
                                'success'
                            );
                            if (this.dialogData?.clientId) {
                                this.closeModal();
                            }
                        },
                        (error) => {
                            console.error(error);
                            this.sharedService.open(error.error.message, 'failure');
                        }
                    );
            }
        }
    }

    deleteClient() {
        if (confirm('Möchten Sie mit dem löschen fortfahren?')) {
            this.sharedService.progress.next(true);
            this.subs.sink = this.appService
                .deleteClient(this.clientDetail.id)
                .subscribe(() => {
                    this.router.navigate(['/dashboard/clientList']);
                });
            this.sharedService.progress.next(false);
        }
    }

    navigate() {
        if (this.refClientId)
            this.backTrigger.emit();
        else
            this.router.navigate(['/dashboard/clientList']);
    }

    confirmClient() {
        this.selectedClient.emit();
    }

    closeModal() {
        this.dialog.closeAll();
    }

    closeDetailModal() {
        this.close.emit();
    }

    titleHead(salutationId) {
        const salutation = this.anreden.find(({ id }) => id === salutationId);
        return salutation ? salutation.name.toLowerCase() === 'frau' ? 'Mandantin' : salutation.name.toLowerCase() === 'herr' ? 'Mandant' : '' : '';
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
        this.sharedService.progress.next(false);
    }
}
