import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-workshop-dashboard',
  templateUrl: './workshop-dashboard.component.html',
  styleUrls: ['./workshop-dashboard.component.css']
})
export class WorkshopDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
