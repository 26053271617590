import {
  Component,
  OnInit,
  HostListener,
  Inject,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { SharedService } from 'src/app/services/shared.service';
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { SubSink } from 'subsink';
import { AppService } from 'src/app/services/app.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-institution-create',
  templateUrl: './institution-create.component.html',
})
export class InstitutionCreateComponent implements OnInit {
  @Output() backTrigger: EventEmitter<string> = new EventEmitter<any>();
  @Output() selectedEmployee: EventEmitter<string> = new EventEmitter<any>();
  @Output() close: EventEmitter<string> = new EventEmitter<any>();
  @Output() unlinkInstitute: EventEmitter<any> = new EventEmitter<any>();

  @Input() refInstitute: any;
  @Input() currInstitutionId: any;
  @Input() refViewMode: boolean;

  public subs = new SubSink();
  public instituteForm: FormGroup;
  public instituteType = '';
  public instituteId = '';
  public userDetail: any;
  public instituteTypeDetail: any;
  public instituteDetail: any;
  public viewMode = true;
  public userForm: FormGroup;
  public selUser: any;
  public userModal: MatDialogRef<unknown, any>;
  public anreden = [];
  public titels = [];
  public userDataSource: any;
  public displaySBColumns = [
    'initials',
    'name',
    'email',
    'telefonNummer',
    'options',
  ];
  public formChanged = false;
  private nullValue = null;
  public updating = false;
  public canEdit = false;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private sharedService: SharedService,
    public dialog: MatDialog,
    public appService: AppService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {}

  @HostListener('window:beforeunload')
  canDeactivate() {
    if (this.formChanged) {
      if (
        confirm(
          'Möchten Sie die getätigten Änderungen verwerfen und ohne Speichern verlassen?'
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  ngOnInit() {
    if (this.refInstitute?.id) {
      this.instituteId = this.refInstitute?.id;
    }
    if (this.dialogData) {
      this.instituteId = this.dialogData?.instituteId;
    }
    this.route.paramMap.subscribe(({ params }: any) => {
      this.instituteType = params.type;
      if (this.instituteType) this.fetchInstituteTypes();

      this.instituteId = params.id || this.instituteId || this.refInstitute?.id;
      if (this.instituteId) {
        this.fetchInstitutionDetail(true);
        this.checkEdit();
      }

      this.userDetail = this.sharedService.userDetail();
    });
  }

  fetchInstitutionDetail(initialLoad?) {
    this.subs.sink = this.appService
      .institutionDetail(this.instituteId)
      .subscribe(
        ({ data }: any) => {
          this.instituteDetail = data?.result;
          if (initialLoad) {
            this.createForm(data.result);
            this.fetchInstitutionUsers();
            this.fetchSalutationTitle();
            this.validateAllFormFields(this.instituteForm);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  //Checking if the institution is a self managed or a contact
  checkEdit(){
    this.appService
    .institutionDetail(this.instituteId)
    .subscribe(
        ({ data }: any) => {
            if (data?.result?.linkedInstitution?.institutionId) {
                this.canEdit = true;
            }
        }
    )
  }

  fetchSalutationTitle() {
    this.subs.sink = this.appService.salutationTitleList().subscribe(
      ({ data }: any) => {
        this.anreden = data.result.salutation;
        this.titels = data.result.title;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  fetchInstitutionUsers() {
    this.appService
      .institutionUser(this.instituteDetail?.linkedInstitution?.id)
      .subscribe(
        ({ data }: any) => {
          this.userDataSource = data.result;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  createForm(data?) {
    this.instituteForm = this.formBuilder.group({
      name: [
        data?.linkedInstitution?.name,
        Validators.compose([Validators.required]),
      ],
      street: [
        data?.linkedInstitution?.adress?.street || '',
        Validators.compose((data &&
          data?.linkedInstitution?.valueTypeOfInstitution?.name !==
            'versicherungen') ||
        (this.instituteType && this.instituteType !== 'versicherungen') ? [Validators.required] : []),
      ],
      houseNumber: [
        data?.linkedInstitution?.adress?.houseNumber || '',
        Validators.compose((data &&
          data?.linkedInstitution?.valueTypeOfInstitution?.name !==
            'versicherungen') ||
        (this.instituteType && this.instituteType !== 'versicherungen') ? [Validators.required] : []),
      ],
      postcode: [
        data?.linkedInstitution?.adress?.postcode,
        Validators.compose([Validators.required]),
      ],
      city: [
        data?.linkedInstitution?.adress?.city,
        Validators.compose([Validators.required]),
      ],
      phone: [
        data?.linkedInstitution?.phone,
        Validators.compose([Validators.required]),
      ],
      email: [
        data?.linkedInstitution?.email,
        Validators.compose([
          Validators.required,
          Validators.pattern(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ]),
      ],
    });
    if (
      (data &&
        data?.linkedInstitution?.valueTypeOfInstitution?.name !==
          'versicherungen') ||
      (this.instituteType && this.instituteType !== 'versicherungen')
    ) {
      this.instituteForm.addControl(
        'IBAN',
        new FormControl(data?.linkedInstitution?.IBAN, Validators.compose([]))
      );
      this.instituteForm.addControl(
        'bankname',
        new FormControl(
          data?.linkedInstitution?.bankname,
          Validators.compose([])
        )
      );
    //   this.instituteForm.addControl(
    //     'UStID',
    //     new FormControl(data?.linkedInstitution?.UStID, Validators.compose([]))
    //   );
    }
    if (data) {
      this.instituteForm.addControl(
        'id',
        new FormControl(data?.id, Validators.compose([Validators.required]))
      );
      this.instituteForm.addControl(
        'linkedInstitutionId',
        new FormControl(
          data?.linkedInstitution?.id,
          Validators.compose([Validators.required])
        )
      );
      this.instituteForm.disable();
    } else {
      this.instituteForm.addControl(
        'institutionId',
        new FormControl(
          this.userDetail?.user?.institutionId,
          Validators.compose([Validators.required])
        )
      );
      this.instituteForm.addControl(
        'valueTypeOfInstitutionId',
        new FormControl(
          this.instituteTypeDetail?.id,
          Validators.compose([Validators.required])
        )
      );
    }
    this.detectFormValueChange();
  }

  detectFormValueChange() {
    this.instituteForm.valueChanges.subscribe(() => (this.formChanged = true));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.sharedService.progress.next(false);
  }

  fetchInstituteTypes() {
    this.appService.instituteTypeList().subscribe(({ data }: any) => {
      this.instituteTypeDetail = data?.result?.typeOfInstitution.find(
        ({ name }) => name === this.instituteType
      );
      this.createForm();
    });
  }

  isInsurerInstitute() {
    return this.instituteDetail?.id
      ? this.instituteDetail?.linkedInstitution?.valueTypeOfInstitution
          ?.name === 'versicherungen'
      : this.instituteType === 'versicherungen';
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  createInstitute() {
    if(!this.updating) {
      if (!this.instituteForm.valid) {
        this.validateAllFormFields(this.instituteForm);
        return;
      } else {
        this.updating = true;
        this.subs.sink = this.appService
          .createInstitution(this.instituteForm.value)
          .pipe(finalize(() => {this.formChanged = false; this.updating = false;}))
          .subscribe(
            ({ data }: any) => {
              this.sharedService.open(
                `${this.instituteMessageName()} wurde angelegt`,
                'success'
              );
              this.navigate();
            },
            (error) => {
              console.error(error);
              this.sharedService.open(error.error.message, 'failure');
            }
          );
        this.sharedService.progress.next(false);
      }
    }
  }

  instituteMessageName() {
    return this.isInsurerInstitute() ? 'Versicherung' : 'Partner';
  }

  editInstitute() {
    if (this.viewMode) {
      this.viewMode = !this.viewMode;
      this.instituteForm.enable();
    } else {
      if(!this.updating) {
        if (!this.instituteForm.valid) {
          this.validateAllFormFields(this.instituteForm);
          return;
        } else {
          this.updating = true;
          this.appService
            .updateInstitution(this.instituteForm.value)
            .pipe(finalize(() => {this.formChanged = false; this.updating = false;}))
            .subscribe(
              ({ data }: any) => {
                this.viewMode = !this.viewMode;
                this.instituteForm.disable();
                this.fetchInstitutionDetail();
                this.sharedService.open(
                  `Änderungen wurden gespeichert`,
                  'success'
                );
                if (this.dialogData?.instituteId) {
                  this.closeModal();
                }
              },
              (error) => {
                console.error(error);
                this.sharedService.open(error.error.message, 'failure');
              }
            );
        }
      }
    }
  }

  deleteInstitute() {
    if (confirm('Möchten Sie mit dem löschen fortfahren? ')) {
      this.sharedService.progress.next(true);
      this.subs.sink = this.appService
        .deleteInstitution(this.instituteDetail.id)
        .subscribe(() => {
          this.navigate();
        });
      this.sharedService.progress.next(false);
    }
  }

  createInstitutionUser(data?) {
    this.userForm = this.formBuilder.group({
      email: [
        data?.email,
        Validators.compose([
          Validators.required,
          Validators.pattern(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ]),
      ],
      firstName: [data?.firstName || '', Validators.compose((this.instituteDetail &&
        this.instituteDetail?.linkedInstitution?.valueTypeOfInstitution?.name !==
          'versicherungen') ||
      (this.instituteType && this.instituteType !== 'versicherungen') ? [Validators.required] : [])],
      lastName: [data?.lastName, Validators.compose([Validators.required])],
      phone: [data?.phone, Validators.compose([Validators.required])],
      valueSalutationId: [
        data?.valueSalutationId,
        Validators.compose([Validators.required]),
      ],
      valueTitleId: [data?.valueTitleId, Validators.compose([])],
    });
    if (data)
      this.userForm.addControl(
        'id',
        new FormControl(data?.id, Validators.compose([Validators.required]))
      );
    else
      this.userForm.addControl(
        'institutionId',
        new FormControl(
          this.instituteDetail?.linkedInstitution?.id,
          Validators.compose([Validators.required])
        )
      );
  }

  openUserModal(userTemplate, user?) {
    this.createInstitutionUser(user);
    this.selUser = user;
    this.userModal = this.dialog.open(userTemplate, {
      width: 'auto',
      disableClose: true,
    });
  }

  closeUserModal(): void {
    this.userModal.close();
  }

  saveUser() {
    if (!this.userForm.valid) return this.validateAllFormFields(this.userForm);

    if (!this.userForm.dirty)
      return this.sharedService.open('No changes made', 'warning');

    if (this.selUser) return this.updateUser();

    this.createUser();
  }

  createUser() {
    this.subs.sink = this.appService
      .createInstitutionUser(this.userForm.value)
      .subscribe(
        (data) => {
          this.fetchInstitutionUsers();
          this.closeUserModal();
          this.sharedService.open(`Mitarbeiter wurde angelegt.`, 'success');
        },
        (error) => {
          console.log(error);
        }
      );
  }

  updateUser() {
    this.subs.sink = this.appService
      .editInstitutionUser(this.userForm.value)
      .subscribe(
        (data) => {
          this.fetchInstitutionUsers();
          this.closeUserModal();
          this.sharedService.open(
            `Änderungen wurden gespeichert.`,
            'success'
          );
          if (this.dialogData?.instituteId) {
            this.closeModal();
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  deleteUser(user) {
    if (confirm('Möchten Sie mit dem löschen fortfahren?')) {
      this.removeUser(user);
    }
  }

  removeUser(user) {
    this.subs.sink = this.appService.deleteInstitutionUser(user.id).subscribe(
      (data) => {
        this.fetchInstitutionUsers();
        this.sharedService.open(`Mitarbeiter wurde gelöscht.`, 'success');
      },
      (error) => {
        console.log(error);
      }
    );
  }

  navigate() {
    const path = this.instituteType
      ? this.instituteType
      : this.instituteDetail.linkedInstitution.valueTypeOfInstitution.name;
    this.refInstitute?.id
      ? this.backTrigger.emit()
      : this.router.navigate(['/dashboard/list', path]);
  }

  closeModal() {
    this.dialog.closeAll();
  }

  isWerkstattenInstitute() {
    return (
      this.instituteTypeDetail?.name === 'werkstatten' ||
      this.instituteDetail?.linkedInstitution?.valueTypeOfInstitution?.name ===
        'werkstatten'
    );
  }

  isSachverstandigeInstitute() {
    return (
      this.instituteTypeDetail?.name === 'sachverstandige' ||
      this.instituteDetail?.linkedInstitution?.valueTypeOfInstitution?.name ===
        'sachverstandige'
    );
  }

  displayElement(werkstattenPermission, sachverstandigePermission) {
    if (this.isWerkstattenInstitute())
      return this.userDetail?.permissions?.includes(werkstattenPermission);

    if (this.isSachverstandigeInstitute())
      return this.userDetail?.permissions?.includes(sachverstandigePermission);

    return false;
  }

  listTitle() {
    return this.isWerkstattenInstitute()
      ? 'Werkstätten'
      : this.isSachverstandigeInstitute()
      ? 'Sachverständige'
      : 'Versicherung';
  }

  selectEmployee(employee) {
    this.selectedEmployee.emit(employee);
  }

  closeDetailModal() {
    this.close.emit();
  }

  selectInstitute() {
    this.selectedEmployee.emit();
  }

  unmapInstitute() {
    this.unlinkInstitute.emit();
  }
}
