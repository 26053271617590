import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UploaderOptions, UploadInput, UploadOutput } from 'ngx-uploader';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-accident',
  templateUrl: './accident.component.html'
})
export class AccidentComponent implements OnInit {

  @Input() clientId: any;
  @Input() caseValueDetail: any;
  @Input() caseDetail: any;
  @Input() documentList: EventEmitter<boolean> = new EventEmitter();;

  @Output() deleteFiles: EventEmitter<string> = new EventEmitter<string>();
  @Output() downloadAttachment: EventEmitter<object> = new EventEmitter<object>();

  public options: UploaderOptions;
  public accidentForm: FormGroup;
  public isManualEntry = false;
  public viewMode = true;
  public userDetail: any;
  public tabFileChanged: any = [];
  public docList: any = [];

  constructor(public fb: FormBuilder, private sharedService: SharedService, public cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.documentList.subscribe((data) => {
      if (this.tabFileChanged?.length) {
        this.docList.forEach(({ childFileAndFolder }, index) => {
          if(childFileAndFolder?.fileButtonMappingId){
            if(!this.tabFileChanged.includes(childFileAndFolder?.fileButtonMapping?.name)) {
              const newEle = data.find(
                ({ childFileAndFolder }) =>
                  childFileAndFolder?.fileButtonMappingId === childFileAndFolder?.fileButtonMappingId
              );
              this.docList.splice(index, 1, newEle ? newEle : {});
            }
          }
        });
      } else {
        this.docList = JSON.parse(JSON.stringify(data));
        this.cd.detectChanges();
      }
    });
    this.createForm();
    this.userDetail = this.sharedService.userDetail();
  }

  createForm(data?) {
    this.accidentForm = this.fb.group({
      accidentDate: [this.caseDetail?.accidentDate, Validators.compose([Validators.required])],
      accidentTime: [this.caseDetail?.accidentTime, Validators.compose([])],
      accidentDescription: [this.caseDetail?.accidentDescription, Validators.compose([])],
      accidentPoliceReport: [this.caseDetail?.accidentPoliceReport, Validators.compose([])],
      tripTypeId: [this.caseDetail?.tripTypeId, Validators.compose([])],
      guiltReasonId: [this.caseDetail?.guiltReasonId, Validators.compose([])],
      accidentCity: [this.caseDetail?.accidentAddress?.city, Validators.compose([])],
      accidentStreet: [this.caseDetail?.accidentAddress?.street, Validators.compose([])],
      accidentPostcode: [this.caseDetail?.accidentAddress?.postcode, Validators.compose([])],
      accidentHouseNumber: [this.caseDetail?.accidentAddress?.houseNumber, Validators.compose([])] 
    });

    if(this.caseDetail) {
      this.accidentForm.disable();
      this.isManualEntry = !!this.caseDetail.accidentDescription;
    }

  }

  fileChanged(e) {
    const accidentFile = this.caseValueDetail?.fileButtonMapping.find(
      ({ name }) => name === 'polizeibericht'
    );
    if (!accidentFile)
      return this.sharedService.open('Etwas ist schief gelaufen!', 'failure');

    const { id: accidentControl } = accidentFile;

    if (!this.accidentForm.get(accidentControl)) {
      this.accidentForm.addControl(
        accidentControl,
        new FormControl('', Validators.compose([]))
      );
    }
    this.tabFileChanged.push('polizeibericht');
    this.accidentForm.patchValue({
      [accidentControl]: e.target.files[0],
    });
  }

  formValue() {
    let formValue = this.accidentForm.value;
    if(!this.isManualEntry) {
      formValue.accidentDescription = null;
    }
    return this.accidentForm.value;
  }

  edit() {
    this.viewMode = !this.viewMode;
    if(this.viewMode) {
      this.accidentForm.disable();
    } else {
      this.accidentForm.enable();
    }
  }

  removeFile() {
    const index = this.docList.findIndex(
      ({ childFileAndFolder }) =>
        childFileAndFolder?.fileButtonMapping?.name === 'polizeibericht'
    );
    this.deleteFiles.emit(this.docList[index].childFileAndFolder?.id);
    this.docList.splice(index, 1);
    this.tabFileChanged.push('polizeibericht');
  }

  download() {
    const file = this.isFilePresent('polizeibericht');
    this.downloadAttachment.emit({
      path: file?.childFileAndFolder?.path,
      name: file?.childFileAndFolder?.name,
    });
  }

  isFilePresent(fileName) {
    return this.docList?.find(
      ({ childFileAndFolder }) =>
        childFileAndFolder?.fileButtonMapping?.name === fileName
    );
  }

}
