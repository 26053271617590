import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-vehicle-damage',
  templateUrl: './vehicle-damage.component.html',
})
export class VehicleDamageComponent implements OnInit {
  @Input() caseValueDetail: any;
  @Input() clientId: any;
  @Input() caseDetail: any;
  @Input() documentList: any;

  @Output() deleteFiles: EventEmitter<string> = new EventEmitter<string>();
  @Output() downloadAttachment: EventEmitter<object> =
    new EventEmitter<object>();

  public vehicleDamageForm: FormGroup;
  public isReplacementCar = false;
  public viewMode = true;
  public tabFileChanged: any = [];
  public docList: any = [];

  constructor(public fb: FormBuilder, public sharedService: SharedService, public cd: ChangeDetectorRef) {}

  ngOnInit(): void {this.documentList.subscribe((data) => {
    if (this.tabFileChanged?.length) {
      this.docList.forEach(({ childFileAndFolder }, index) => {
        if(childFileAndFolder?.fileButtonMappingId){
          if(!this.tabFileChanged.includes(childFileAndFolder?.fileButtonMapping?.name)) {
            const newEle = data.find(
              ({ childFileAndFolder }) =>
                childFileAndFolder?.fileButtonMappingId === childFileAndFolder?.fileButtonMappingId
            );
            this.docList.splice(index, 1, newEle ? newEle : {});
          }
        }
      });
    } else {
      this.docList = JSON.parse(JSON.stringify(data));
      this.cd.detectChanges();
    }
  });
    this.createForm();
  }

  createForm() {
    this.vehicleDamageForm = this.fb.group({
      typeOfDamageId: [this.caseDetail?.typeOfDamageId, Validators.compose([])],
      residualValue: [this.caseDetail?.residualValue, Validators.compose([])],
      replacementValue: [
        this.caseDetail?.replacementValue,
        Validators.compose([]),
      ],
      residualValueSellYN: [
        this.caseDetail?.residualValueSellYN,
        Validators.compose([]),
      ],
      residualValueOfferDeadline: [
        this.caseDetail?.residualValueOfferDeadline,
        Validators.compose([]),
      ],
      typeOfFailureId: [
        this.caseDetail?.typeOfFailureId,
        Validators.compose([]),
      ],
      typeOfBillingId: [
        this.caseDetail?.typeOfBillingId,
        Validators.compose([]),
      ],
      depreciationValue: [
        this.caseDetail?.depreciationValue,
        Validators.compose([]),
      ],
      rentalCarCompany: [
        this.caseDetail?.rentalCarCompany,
        Validators.compose([]),
      ],
      rentalCarCost: [this.caseDetail?.rentalCarCost, Validators.compose([])],
      replacementVehicleCost: [
        this.caseDetail?.replacementVehicleCost,
        Validators.compose([]),
      ],
      lossDuration: [this.caseDetail?.lossDuration, Validators.compose([])],
      dateOfPurchaseContract: [
        this.caseDetail?.dateOfPurchaseContract,
        Validators.compose([]),
      ],
      repairCost: [this.caseDetail?.repairCost, Validators.compose([])],
      repairStartDate: [
        this.caseDetail?.repairStartDate,
        Validators.compose([]),
      ],
      repairEndDate: [this.caseDetail?.repairEndDate, Validators.compose([])],
    });
    if (this.caseDetail) {
      this.vehicleDamageForm.disable();
      this.isReplacementCar = !!(
        this.caseDetail?.dateOfPurchaseContract ||
        this.caseDetail?.replacementVehicleCost ||
        this.documentList?.purchaseFile
      );
    }
    if(!this.caseDetail?.typeOfBillingId) {
      this.billingTypeChanged({ checked: false });
    }
    if(!this.caseDetail?.typeOfFailureId) {
      this.failureTypeChanged({ checked: false });
    }
  }

  totalSeveritySelected() {
    return this.caseValueDetail?.typeOfDamage
        ?.find(
          (damage) =>
            damage.id === this.vehicleDamageForm.get('typeOfDamageId')?.value
        )
        ?.name.toLowerCase() === 'totalschaden';
  }

  rentalFailSelected() {
    return (
      this.caseValueDetail?.typeOfFailure
        ?.find(
          (failure) =>
            failure.id === this.vehicleDamageForm.get('typeOfFailureId').value
        )
        ?.name.toLowerCase() === 'mietwagen'
    );
  }

  fictiousBillingSelected() {
    return (
      this.caseValueDetail?.typeOfBilling
        ?.find(
          (bill) =>
            bill.id === this.vehicleDamageForm.get('typeOfBillingId').value
        )
        ?.name.toLowerCase() === 'fiktiv'
    );
  }

  formValue() {
    let formValue = this.vehicleDamageForm.value;
    if (this.totalSeveritySelected()) {
      formValue.depreciationValue = null;
      formValue.typeOfBillingId = null;
      formValue.repairCost = null;
      formValue.repairStartDate = null;
      formValue.repairEndDate = null;
      this.removeFile('werkstattrechnung');

      if (!this.vehicleDamageForm.get('residualValueSellYN').value) {
        formValue.residualValueOfferDeadline = null;
      }
      if (this.vehicleDamageForm.get('typeOfFailureId').value) {
        if (this.rentalFailSelected()) {
          formValue.dateOfPurchaseContract = null;
          formValue.replacementVehicleCost = null;
          formValue.lossDuration = null;
          this.removeFile('purchaseFile');
        } else {
          formValue.rentalCarCompany = null;
          formValue.rentalCarCost = null;
          this.removeFile('mietwagenrechnung');
          if (this.isReplacementCar) {
            formValue.lossDuration = null;
          } else {
            formValue.dateOfPurchaseContract = null;
            formValue.replacementVehicleCost = null;
            this.removeFile('purchaseFile');
          }
        }
      } else {
        formValue.typeOfFailureId = null;
        formValue.dateOfPurchaseContract = null;
        formValue.replacementVehicleCost = null;
        this.removeFile('purchaseFile');
        formValue.lossDuration = null;
        formValue.rentalCarCompany = null;
        formValue.rentalCarCost = null;
        this.removeFile('mietwagenrechnung');
      }
    } else {
      formValue.residualValue = null;
      formValue.replacementValue = null;
      formValue.residualValueSellYN = false;
      formValue.residualValueOfferDeadline = null;
      formValue.dateOfPurchaseContract = null;
      this.removeFile('purchaseFile');
      formValue.replacementVehicleCost = null;
      formValue.lossDuration = null;

      if (this.vehicleDamageForm.get('typeOfBillingId').value) {        
        if (this.fictiousBillingSelected()) {
          this.removeFile('werkstattrechnung');
          formValue.typeOfFailureId = null;
          formValue.repairStartDate = null;
          formValue.repairEndDate = null;
          formValue.rentalCarCompany = null;
          formValue.rentalCarCost = null;
          this.removeFile('mietwagenrechnung');
        } else {
          this.removeFile('werkstattrechnung');
          if (this.vehicleDamageForm.get('typeOfFailureId').value) {
            if (this.rentalFailSelected()) {
              formValue.repairStartDate = null;
              formValue.repairEndDate = null;
            } else {
              formValue.rentalCarCompany = null;
              formValue.rentalCarCost = null;
              this.removeFile('mietwagenrechnung');
            }
          } else {
            formValue.typeOfFailureId = null;
            formValue.repairStartDate = null;
            formValue.repairEndDate = null;
            formValue.rentalCarCompany = null;
            formValue.rentalCarCost = null;
            this.removeFile('mietwagenrechnung');
          }
        }
      } else {
        formValue.typeOfBillingId = null;
        formValue.repairCost = null;
        this.removeFile('werkstattrechnung');
        formValue.typeOfFailureId = null;
        formValue.repairStartDate = null;
        formValue.repairEndDate = null;
        formValue.rentalCarCompany = null;
        formValue.rentalCarCost = null;
        this.removeFile('mietwagenrechnung');
      }
    }
    return formValue;
  }

  fileChanged(e, fieldName) {
    const fieldNameFile = this.caseValueDetail?.fileButtonMapping.find(
      ({ name }) => name === fieldName
    );
    if (!fieldNameFile)
      return this.sharedService.open('Etwas ist schief gelaufen!', 'failure');

    const { id: fieldControl } = fieldNameFile;

    if (!this.vehicleDamageForm.get(fieldControl)) {
      this.vehicleDamageForm.addControl(
        fieldControl,
        new FormControl('', Validators.compose([]))
      );
    }
    this.tabFileChanged.push(fieldName);
    this.vehicleDamageForm.patchValue({
      [fieldControl]: e.target.files[0],
    });
  }

  edit() {
    this.viewMode = !this.viewMode;
    if (this.viewMode) {
      this.vehicleDamageForm.disable();
    } else {
      this.vehicleDamageForm.enable();
    }
  }

  removeFile(field) {
    const index = this.docList.findIndex(
      ({ childFileAndFolder }) =>
        childFileAndFolder?.fileButtonMapping?.name === field
    );
    if(index !== -1) {
      this.deleteFiles.emit(this.docList[index].childFileAndFolder?.id);
      this.docList.splice(index, 1);
    }
    this.tabFileChanged.push(field);
  }

  download(field) {
    const file = this.isFilePresent(field);
    this.downloadAttachment.emit({
      path: file?.childFileAndFolder?.path,
      name: file?.childFileAndFolder?.name,
    });
  }

  isFilePresent(fileName) {
    return this.docList?.find(
      ({ childFileAndFolder }) =>
        childFileAndFolder?.fileButtonMapping?.name === fileName
    );
  }

  damageTypeChanged(e) {
    const selName = e.checked ? 'totalschaden' : 'reparaturschaden';
    const damageType = this.caseValueDetail?.typeOfDamage?.find(
      ({ name }) => name.toLowerCase() === selName
    );
    this.vehicleDamageForm.patchValue({
      typeOfDamageId: damageType?.id,
    });
  }

  billingTypeChanged(e) {
    const selName = e.checked ? 'fiktiv' : 'konkret';
    const billType = this.caseValueDetail?.typeOfBilling?.find(
      ({ name }) => name.toLowerCase() === selName
    );
    this.vehicleDamageForm.patchValue({
      typeOfBillingId: billType?.id,
    }); 
  }

  failureTypeChanged(e) {
    const selName = e.checked ? 'mietwagen' : 'nutzungsausfall';
    const failType = this.caseValueDetail?.typeOfFailure?.find(
      ({ name }) => name.toLowerCase() === selName
    );
    this.vehicleDamageForm.patchValue({
      typeOfFailureId: failType?.id,
    });
  }
}
