import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { SharedService } from '../services/shared.service';
import { AppService } from '../services/app.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface CanComponentDeactivate {
  canDeactivate: () => boolean;
}

@Injectable()
export class AuthGuard implements CanActivate{
  constructor(private router: Router, private sharedService: SharedService, private appService: AppService) { }

  canActivate(route: ActivatedRouteSnapshot): boolean | Observable<boolean> {
    if(route.data.module === 'USER_AUTH'){
      if (!this.sharedService.loggedIn()) {
        return true;
      }
      this.router.navigate(['/dashboard']);
      return false;
    }

    if(route.data.module === 'DASHBOARD'){
      if (this.sharedService.loggedIn()) {
        return this.appService.getPermission().pipe(map((res: any) => {
          this.sharedService.setPermissions(res.data.result.permissions);
          this.sharedService.userLoginSuccess.next(true);
          
          return true;
        })
      )
      }
      this.router.navigate(['/']);
      return false;
    }
    
  }

  //CLEAN: Clrean out old implementation code
  // canDeactivate(component: CanComponentDeactivate, currentRoute: ActivatedRouteSnapshot): boolean {
  //   if (!component.canDeactivate()) {
  //     const currentUrlTree = this.router.createUrlTree([], currentRoute);
  //     const currentUrl = currentUrlTree.toString();
  //     this.location.go(currentUrl);
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }
}
