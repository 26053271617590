import { Component, EventEmitter, HostListener, Input, OnInit, ViewChild, Output } from '@angular/core';
import { AffectedPartiesComponent } from './affected-parties/affected-parties.component';
import { AccidentComponent } from './accident/accident.component';
import { InstitutionsComponent } from './institutions/institutions.component';
import { VehicleDamageComponent } from './vehicle-damage/vehicle-damage.component';
import { OtherDamageComponent } from './other-damage/other-damage.component';
import { AppService } from 'src/app/services/app.service';
import { SharedService } from 'src/app/services/shared.service';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { saveAs } from "file-saver";
import { PersonalDamageComponent } from './personal-damage/personal-damage.component';
import { TimelineComponent } from './timeline/timeline.component';
@Component({
  selector: 'app-information-tab',
  templateUrl: './information-tab.component.html'
})
export class InformationTabComponent implements OnInit {

  private tabChanged: EventEmitter<boolean> = new EventEmitter();
  public documentListEvent: EventEmitter<boolean> = new EventEmitter();
  
  @ViewChild(AffectedPartiesComponent) affectedParties: AffectedPartiesComponent;
  @ViewChild(AccidentComponent) accident: AccidentComponent;
  @ViewChild(InstitutionsComponent) institution: InstitutionsComponent;
  @ViewChild(VehicleDamageComponent) vehicleDamage: VehicleDamageComponent;
  @ViewChild(OtherDamageComponent) otherDamage: OtherDamageComponent;
  @ViewChild(PersonalDamageComponent) personalDamage: PersonalDamageComponent;
  @ViewChild(TimelineComponent) timeline: TimelineComponent;
  
  @Output() refreshTimeline: EventEmitter<string> = new EventEmitter<any>();
  @Output() updateCaseDocument: EventEmitter<any> = new EventEmitter<any>();
  
  @Input() clientId: any;
  @Input() caseValueDetail: any;
  @Input() caseDetail: any;
  @Input() caseId: any;
  @Input() documentList: any;
  @Input() statusIconArr: any;
  @Input() userDetail: any;
  @Input() caseDocumentEvent: any;

  public viewMode = true;
  public updating = false;
  public removeFiles: any = [];

  constructor(public appService: AppService, public sharedService: SharedService, public router: Router) { }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkTabHeader(event.target.innerWidth);
  }

  ngOnInit(): void {
    this.userDetail = this.sharedService.userDetail();
    this.checkTabHeader(window.innerWidth);
    this.caseDocumentEvent.subscribe(data => this.documentListEvent.emit(data));
  }

  ngAfterViewInit() {
    this.documentListEvent.emit(this.documentList);
  }

  updateDocList(e) {
    this.updateCaseDocument.emit(e);
    this.documentListEvent.emit(e);
  }

  checkTabHeader(width) {
    const matHeader:any = document.getElementsByClassName("mat-tab-header");
    const matBody:any = document.getElementsByClassName("mat-tab-body-wrapper");
    const checkWidth = this.clientId ? 1650 : 1880;
    if(width <= checkWidth) {
      if(matHeader.length) {
        matHeader[0].classList.add("mat-tab-header-pagination-controls-enabled");
        matHeader[0].style.width = `${matBody[0].clientWidth - 150}px`;
      }
    } else {
      matHeader[0].classList.remove("mat-tab-header-pagination-controls-enabled");
      matHeader[0].style.width = `100%`;
    }
  }

  edit() {
    if(!this.updating) {
      if(this.viewMode) {
        this.viewMode = !this.viewMode;
        this.affectedParties.edit();
        this.accident.edit();
        // this.institution.edit();
        this.vehicleDamage.edit();
        this.otherDamage.edit();
        this.personalDamage.edit();
        this.timeline.edit();
      } else {
        return this.informationTabValue();
      }
    }
  }

  informationTabValue() {
    let completedValue = { ...this.affectedParties.formValue(), ...this.accident.formValue(), ...this.vehicleDamage.formValue(), ...this.otherDamage.formValue(), ...this.personalDamage.formValue() };
    var formData = new FormData();
    if(this.clientId) {
      formData.append('lawyerId', this.userDetail.user?.id);
      formData.append('createdById', this.userDetail.user?.id);
      formData.append('clientId', this.clientId);
    } else {
      completedValue = { ...completedValue, ...this.timeline.formValue() };
      if(this.removeFiles.length) {
          formData.append('removeFiles', this.removeFiles);
      }
    }
    for (const [key, value] of Object.entries(completedValue)) {
      formData.append(key, typeof(value) === 'string' ? ((value as string)?.trim().length ? value as string : null) : value as string);
    }
    return formData;
  }

  deleteFiles(fileId) {
    this.removeFiles.push(fileId);
  }

  downloadAttachment(fileData) {
    this.appService.downloadCase(fileData.path).subscribe(
    blob => {
        saveAs(blob, fileData.name); 
    },
    err => {
      console.log('error in file download ', err);
    })
  }
  
  onTabChanged(e) {
    if(e.tab.textLabel.toLowerCase() === 'dokumente') 
      this.tabChanged.emit(true);
  }

}
